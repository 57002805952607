export function validateForm(form) {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  )

  const emailRegex = new RegExp(".+@.+.[A-Za-z]+$")
  const nameRegex = /^[\w\d\s-']+$/
  const numberRegex = /^\d+$/
  const postCodeRegex = /^[\w\s]+$/
  const addressRegex = /^[\w\s-',]+$/

  console.log("error check")
  const {
    name,
    accountName,
    firstName,
    secondName,
    email,
    accountEmail,
    password1,
    password2,
    accountAddress,
    accountPostCode,
    telephone,
  } = form

  const newErrors = {}

  // name errors
  if (name !== undefined) {
    if (name.length < 3) {
      newErrors.name = "Name is too short"
    } else if (!nameRegex.test(name)) {
      newErrors.name = "Please enter a valid name"
    } else {
      newErrors.name = ""
    }
  }

  // account name errors
  if (accountName !== undefined) {
    if (accountName.length < 2) {
      newErrors.accountName = "Name is too short"
    } else if (!nameRegex.test(accountName)) {
      newErrors.accountName = "Please enter a valid name"
    } else {
      newErrors.accountName = ""
    }
  }

  // name errors
  if (firstName !== undefined) {
    if (firstName.length < 3) {
      newErrors.firstName = "First name is too short"
    } else if (!nameRegex.test(firstName)) {
      newErrors.firstName = "Please enter a valid name"
    } else {
      newErrors.firstName = ""
    }
  }

  // name errors
  if (secondName !== undefined) {
    if (secondName.length < 3) {
      newErrors.secondName = "Second name is too short"
    } else if (!nameRegex.test(secondName)) {
      newErrors.secondName = "Please enter a valid name"
    } else {
      newErrors.secondName = ""
    }
  }

  // email errors
  if (email !== undefined) {
    if (email.length < 3) {
      newErrors.email = "Email is too short"
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email"
    } else {
      newErrors.email = ""
    }
  }

  if (accountEmail !== undefined) {
    if (accountEmail.length < 3) {
      newErrors.accountEmail = "Email is too short"
    } else if (!emailRegex.test(accountEmail)) {
      newErrors.accountEmail = "Please enter a valid email"
    } else {
      newErrors.accountEmail = ""
    }
  }

  if (password1 !== undefined) {
    if (!strongRegex.test(password1)) {
      newErrors.password1 = "Password is weak"
    } else {
      newErrors.password1 = ""
    }
  }

  if (password2 !== undefined) {
    if (password2 !== password1) {
      newErrors.password2 = "Passwords do not match"
    } else {
      newErrors.password2 = ""
    }
  }

  // address errors
  if (accountAddress !== undefined) {
    if (accountAddress.length < 3) {
      newErrors.accountAddress = "Address is too short"
    } else if (!addressRegex.test(accountAddress)) {
      newErrors.accountAddress = "Please enter a valid address"
    } else {
      newErrors.accountAddress = ""
    }
  }

  // address errors
  if (accountPostCode !== undefined) {
    if (accountPostCode.length < 6) {
      newErrors.accountPostCode = "Postcode is too short"
    } else if (!postCodeRegex.test(accountPostCode)) {
      newErrors.accountPostCode = "Please enter a valid postcode"
    } else {
      newErrors.accountPostCode = ""
    }
  }

  // Telephone errors
  if (telephone !== undefined) {
    if (telephone.length < 6) {
      newErrors.telephone = "telephone is too short"
    } else if (!numberRegex.test(telephone)) {
      newErrors.telephone = "Please enter a valid telephone"
    } else {
      newErrors.telephone = ""
    }
  }
  return newErrors
}
