import React from "react"
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, Title)

export default function DataSetPie({ theme, datasets, navigate }) {
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Data Sets",
        color: theme === "dark" ? "white" : "black",
        font: {
          size: "26px",
          weight: "300",
        },
      },
      legend: {
        labels: {
          color: theme === "dark" ? "white" : "black",
        },
      },
    },
    responsive: true,
  }
  const data = {
    labels: ["A", "B", "C", "D"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          datasets.filter((dataSet) => dataSet.dataCategory === "A").length,
          datasets.filter((dataSet) => dataSet.dataCategory === "B").length,
          datasets.filter((dataSet) => dataSet.dataCategory === "C").length,
          datasets.filter((dataSet) => dataSet.dataCategory === "D").length,
        ],
        backgroundColor: [
          "rgba(153, 102, 255, 0.5)",
          "rgba(235, 114, 49, 0.5)",
          "rgba(255, 166, 0, 0.6)",
          "rgba(0, 0, 255, 0.6)",
        ],
        borderColor: [
          "rgba(153, 102, 255, 0.5)",
          "rgba(235, 114, 49, 0.5)",
          "rgba(255, 166, 0, 0.6)",
          "rgba(0, 0, 255, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  }
  return (
    <div className=" primaryShadow text-white p-3">
      <Pie options={options} data={data} />

      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/data_sets")}
        >
          Go There
        </button>
      </div>
    </div>
  )
}
