import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact8() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact8 text-white">
            Close to <span className="fact8Span">50%</span> of Americans
            regularly use Wi-Fi hotspots to carry out financial transactions,
            while <span className="fact8Span">18%</span> use public Wi-Fi to
            work remotely
          </h2>
          <p className="fact-reference text-white"> - aura.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          Using a virtual private network (VPN) can protect your device and data
          when using public Wi-Fi by redirecting your internet connection
          through a private internet server. This will hide your IP address and
          your obscure your activity, anyone attempting to spy on you won't be
          able to see past the VPN's encryption protocols.
        </h3>
      </div>
    </div>
  )
}
