import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLaptop,
  faComputer,
  faServer,
  faPrint,
  faNetworkWired,
  faMobileScreenButton,
  faTabletScreenButton,
  faQuestion,
  faDesktop,
  faHardDrive,
} from "@fortawesome/free-solid-svg-icons"

export default function DeviceListIcon({ device }) {
  const [deviceType, setDeviceType] = useState()
  const [deviceOS, setDeviceOS] = useState()
  const [auditStatus, setAuditStatus] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getIconDetails = (type, operatingSystem, isMonitored, status) => {
      try {
        if (type === "Desktop") {
          setDeviceType("Desktop")
        } else if (type === "Laptop") {
          setDeviceType("Laptop")
        }
        // else if (type === "Mobile") {
        //   setDeviceType("Mobile")
        // } else if (type === "Printer") {
        //   setDeviceType("Printer")
        // } else if (type === "Router") {
        //   setDeviceType("Router")
        // } else if (type === "Server") {
        //   setDeviceType("Server")
        // } else if (type === "Tablet") {
        //   setDeviceType("Tablet")
        // } else if (type === "NAS") {
        //   setDeviceType("NAS")
        // } else if (type === "Thin Client") {
        //   setDeviceType("Thin Client")
        // }
        else {
          setDeviceType("Other")
        }

        if (operatingSystem.toLowerCase().includes("windows")) {
          setDeviceOS("Windows")
        } else if (operatingSystem.toLowerCase().includes("mac")) {
          setDeviceOS("Apple")
        } else if (operatingSystem.toLowerCase().includes("linux")) {
          setDeviceOS("Linux")
        } else {
          setDeviceOS("Other")
        }

        if (isMonitored) {
          if (status) {
            setAuditStatus("green")
          } else {
            setAuditStatus("red")
          }
        } else {
          setAuditStatus("gray")
        }
      } catch (err) {
        console.log("err", err)
      } finally {
        setLoading(false)
      }
    }

    getIconDetails(
      device.type,
      device.operatingSystem,
      device.isMonitored,
      device.status
    )
  }, [device])

  return (
    <>
      {!loading && (
        <div className="">
          {deviceType && deviceType === "Other" ? (
            <FontAwesomeIcon icon={faQuestion} size="2x" className="ms-4" />
          ) : (
            <img
              height={45}
              width="auto"
              src={require(`../../../Components/Imgs/${deviceType}.png`)}
            />
          )}
          {deviceOS && deviceOS === "Other" ? (
            <>
              {deviceType !== "Other" && (
                <FontAwesomeIcon
                  icon={faQuestion}
                  size="1x"
                  style={
                    deviceType === "Desktop"
                      ? { marginBottom: 3, marginLeft: -47 }
                      : deviceType === "Laptop"
                      ? { marginBottom: 7, marginLeft: -35 }
                      : {}
                  }
                />
              )}
            </>
          ) : (
            <>
              {deviceType !== "Other" && (
                <img
                  height={18}
                  width="auto"
                  src={require(`../../../Components/Imgs/${deviceOS}.png`)}
                  style={
                    deviceType === "Desktop"
                      ? { marginTop: -10, marginLeft: -50 }
                      : deviceType === "Laptop"
                      ? { marginTop: -16, marginLeft: -39 }
                      : {}
                  }
                />
              )}
            </>
          )}
          {auditStatus && (
            <div
              className="bringFront"
              style={{
                backgroundColor: auditStatus,
                height: 20,
                width: 20,
                borderRadius: 20,
                marginTop: -28,
                marginLeft: -10,
              }}
            />
          )}
        </div>
      )}
    </>
  )
}
