import axios from "axios"
import React, { useEffect, useState } from "react"
import Spinner from "../../../Components/Loading/Spinner"

export default function EnableMfaForm({ emailAdr }) {
  const [setupDetails, setSetupDetails] = useState()
  const [loading, setLoading] = useState(true)

  console.log(setupDetails)

  const getSetupDetails = async () => {
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/users/setupgoogleauth/${emailAdr}`
      )
      setSetupDetails(res.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (emailAdr) {
      getSetupDetails()
    }
  }, [emailAdr])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center mt-1">
        <p className="text-center bold ">
          Download the Google Authenticator app from Google Play or the App
          Store and scan the QR code below:
        </p>
      </div>
      {loading && (
        <div className="">
          <Spinner>
            Loading MFA details <span>Please Wait</span>
          </Spinner>
        </div>
      )}
      {!loading && Object.keys(setupDetails).length > 0 && (
        <div className="d-flex flex-column align-items-center">
          <img
            className="imgcenter mt-1"
            src={setupDetails.barcode}
            width="150"
            height="150"
          />
          <p className="  mt-1">
            Alternatively Setup Manually Using Setup Code Below:
          </p>
          <div className="col-12 d-flex justify-content-center">
            <div style={{ width: "90%" }} className="primaryShadow p-2 mb-3">
              <p
                className="overflow-wrap  m-0"
                style={{ overflowWrap: "break-word" }}
              >
                {setupDetails.setupCode}
              </p>
            </div>
          </div>
        </div>
      )}
      <hr />
      <p className="textcenter bold ">Enter Code to Complete Setup:</p>
    </div>
  )
}
