import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import FormInput from "../../Components/Forms/FormInput"
import { useAuthContext } from "../../Hooks/useAuthContext"
import SubmitButton from "../../Components/SubmitButton"
import { toast } from "react-toastify"
import UsernameTable from "./Components/UsernameTable"

export default function CreateEditEmployee() {
  const { user } = useAuthContext()
  const [employee, setEmployee] = useState({
    accountId: user.accountId,
    instances: [],
    usernames: [],
  })
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const getDataSet = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/employees/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log(res, "RES")
        setEmployee(res.data)
      } catch (error) {}
    }
    if (params.id !== "0") {
      getDataSet()
    }
    setLoading(false)
  }, [params.id])

  const setField = (field, value) => {
    setEmployee((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)

    var usernames = []

    if (employee.usernames && employee.usernames.length > 0) {
      usernames = employee.usernames.map((x) => x.username)
    }

    try {
      if (params.id !== "0") {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/employees/${employee.id}`,
            { ...employee, usernames: usernames },
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Employee updated.")
          navigate(-1)
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/employees/`,
            { ...employee, usernames: usernames },
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Employee created.")
          navigate(-1)
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      setSubmitting(false)
    }
  }

  if (!loading) {
    return (
      <div className="p-4">
        {params.id === "0" ? (
          <h2 className="mb-5">Create Employee</h2>
        ) : (
          <h2 className="mb-5">Edit Employee</h2>
        )}
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">Name</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={employee.name ?? ""}
                placeholder="Name"
                required
                onChange={(e) => setField("name", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">Email</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={employee.email ?? ""}
                type="email"
                required
                placeholder="Email"
                onChange={(e) => setField("email", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">Telephone</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={employee.telephone ?? ""}
                type="tel"
                placeholder="Telephone"
                onChange={(e) => setField("telephone", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">Address</Form.Label>
            <div className="col col-md-4">
              <Form.Control
                as="textarea"
                rows={3}
                value={employee.address ?? ""}
                placeholder="Address"
                onChange={(e) => setField("address", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">Postcode</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={employee.postCode ?? ""}
                placeholder="Postcode"
                onChange={(e) => setField("postCode", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-4">
              Works From Home (1+ days per week)
            </Form.Label>
            <div className="col-md-4">
              <Form.Check
                onChange={(e) => {
                  setField("worksFromHome", e.target.checked)
                }}
                checked={employee.worksFromHome ?? false}
              />
            </div>
          </Form.Group>

          <div className="d-flex align-items-center">
            <p className="d-flex mt-3" style={{ fontSize: 26 }}>
              USERNAMES
            </p>
          </div>
          <Form.Group className="d-flex flex-column flex-md-row">
            <UsernameTable setField={setField} usernames={employee.usernames} />
          </Form.Group>

          <div className="mt-5">
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <SubmitButton submitting={submitting} />
          </div>
        </Form>
      </div>
    )
  }
}
