import axios from "axios"
import React from "react"
import { useEffect, useState } from "react"
import { ToastContainer } from "react-toastify"
import ChangePassword from "../Components/Forms/ChangePassword"
import EditUser from "../Components/Forms/EditUser"
import { useAuthContext } from "../Hooks/useAuthContext"
import Spinner from "../Components/Loading/Spinner"

export default function ManageUser() {
  const { user } = useAuthContext()
  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users/${user.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setUserDetails(res.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    getUserDetails()
  }, [user])

  return (
    <div className="d-flex gap-4">
      <div className="col-4">
        <div className="p-3 primaryShadow">
          <h3 className="text-center bold mb-3">Edit User Details</h3>
          {loading && <Spinner />}
          {!loading && (
            <EditUser
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          )}
        </div>
      </div>
      <div className="col-4">
        <div className=" p-3 primaryShadow">
          <h3 className="text-center bold mb-3">Change Password</h3>
          {loading && <Spinner />}
          {!loading && (
            <ChangePassword
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
