import axios from "axios"
//React
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
//Components
import Spinner from "../../Components/Loading/Spinner"

export default function AccountCompliance() {
  const { accountGUID } = useParams()

  const [logoURL, setLogoURL] = useState()
  const [account, setAccount] = useState()
  const [devices, setDevices] = useState()
  const [compliantDevicePercentage, setCompliantDevicePercentage] = useState()
  const [minorIssueDevicePercentage, setMinorIssueDevicePercentage] = useState()
  const [seriousIssueDevicePercentage, setSeriousIssueDevicePercentage] =
    useState()
  const [monitoredDevicePercentage, setMonitoredDevicePercentage] = useState()
  const [employees, setEmployees] = useState()
  const [compliantEmployeePercentage, setCompliantEmployeePercentage] =
    useState()
  const [isCompliantImg, setIsCompliantImg] = useState()
  const [isCompliant, setIsCompliant] = useState()

  const checkDate = new Date()
  checkDate.setDate(checkDate.getDate() - 3)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/accounts/guid/${accountGUID}`
        )
        setAccount(res.data)
        setDevices(res.data.devices)
        setEmployees(res.data.employees)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    getAccountDetails()
  }, [accountGUID])

  useEffect(() => {
    if (devices) {
      const compliantDevices = devices.filter(
        (x) => x.minorIssues === 0 && x.seriousIssues === 0
      )
      var greenBar = 0

      if (compliantDevices.length > 0) {
        greenBar = (compliantDevices.length / devices.length) * 100
      } else {
        greenBar = 0
      }

      setCompliantDevicePercentage(greenBar)

      const minorIssueDevices = devices.filter(
        (x) => x.minorIssues > 0 && x.seriousIssues === 0
      )
      var amberBar = 0

      if (minorIssueDevices.length > 0) {
        amberBar = (minorIssueDevices.length / devices.length) * 100
      } else {
        amberBar = 0
      }

      setMinorIssueDevicePercentage(amberBar)

      const seriousIssueDevices = devices.filter((x) => x.seriousIssues > 0)
      var redBar = 0

      if (seriousIssueDevices.length > 0) {
        redBar = (seriousIssueDevices.length / devices.length) * 100
      } else {
        redBar = 0
      }

      setSeriousIssueDevicePercentage(redBar)

      const monitoredDevices = devices.filter(
        (x) =>
          x.isMonitored && new Date(x.dateLastAudited) > new Date(checkDate)
      )
      var monitored = 0

      if (monitoredDevices.length > 0) {
        monitored = (monitoredDevices.length / devices.length) * 100
      } else {
        monitored = 0
      }

      setMonitoredDevicePercentage(monitored)
    }
  }, [devices])

  useEffect(() => {
    if (employees) {
      const compliantEmployees = employees.filter((x) => !x.status)
      var greenBar = 0

      if (compliantEmployees.length > 0) {
        greenBar = (compliantEmployees.length / employees.length) * 100
      } else {
        greenBar = 0
      }

      setCompliantEmployeePercentage(greenBar)
    }
  }, [employees])

  const base64ToArrayBuffer = (base64) => {
    //Convert received base64 string into a byte array
    const binaryString = window.atob(base64)
    const bytes = new Uint8Array(binaryString.length)
    return bytes.map((byte, i) => binaryString.charCodeAt(i))
  }

  useEffect(() => {
    if (account && account.logoBase64) {
      var byteArray = base64ToArrayBuffer(account.logoBase64)
      //get img blob for logo
      const imgBlob = new Blob([byteArray], { type: "image/png" })
      //setup file reader to retrieve byte array from img blob
      const fileReader = new FileReader()
      fileReader.onload = function () {
        const arrayBuffer = this.result
        const byteArray = new Uint8Array(arrayBuffer)
        //pass img byte array generated from blob to 'generateImgURL'
        generateImgURL(byteArray)
      }
      fileReader.readAsArrayBuffer(imgBlob)
    }
  }, [account])

  const generateImgURL = (byteArray) => {
    //generate img blob using img byte array
    const blob = new Blob([byteArray], {
      type: "image/png",
    })
    //generate url to view the img
    setLogoURL(window.URL.createObjectURL(blob))
  }

  useEffect(() => {
    if (
      compliantDevicePercentage !== undefined &&
      minorIssueDevicePercentage !== undefined &&
      compliantEmployeePercentage !== undefined
    ) {
      try {
        setLoading(true)
        if (
          compliantDevicePercentage + minorIssueDevicePercentage > 90 &&
          compliantEmployeePercentage > 90
        ) {
          setIsCompliantImg(
            require("../../Components/Imgs/Green-check-mark-icon.png")
          )
          setIsCompliant("is")
        } else if (
          compliantDevicePercentage + minorIssueDevicePercentage > 70 &&
          compliantEmployeePercentage > 70
        ) {
          setIsCompliantImg(
            require("../../Components/Imgs/Amber-warning-icon.png")
          )
          setIsCompliant("is nearly")
        } else {
          setIsCompliantImg(require("../../Components/Imgs/Red-x-icon.png"))
          setIsCompliant("isn't")
        }
      } catch (err) {
        console.log("err", err)
      } finally {
        setLoading(false)
      }
    }
  }, [
    compliantDevicePercentage,
    minorIssueDevicePercentage,
    compliantEmployeePercentage,
  ])

  if (loading) {
    return (
      <div
        style={{ height: "75vh" }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Spinner />
      </div>
    )
  } else if (!account) {
    return (
      <div
        style={{ height: "75vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div style={{ fontSize: 26 }} className="bold">
          Company Not Found.
        </div>
      </div>
    )
  } else {
    return (
      <div
        style={{ height: "75vh" }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="d-flex mb-5 mt-3 col-11">
          <div className="d-flex flex-column col-8 ms-4 mt-3">
            <div className="mb-3">
              <img height={150} width="auto" src={logoURL} />
            </div>
            <div className="d-flex flex-column flex-md-row">
              <div className="col col-md-3" style={{ color: "gray" }}>
                Name:
              </div>
              <div className="col col-md-4 ms-2" style={{ color: "gray" }}>
                {account && account.name}
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row">
              <div className="col col-md-3" style={{ color: "gray" }}>
                Address:
              </div>
              <div className="col col-md-4 ms-2" style={{ color: "gray" }}>
                {account && account.address}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center col-4">
            <div className="d-flex justify-content-center mb-2">
              <img height={150} width="auto" src={isCompliantImg} />
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ color: "gray" }}
            >
              <div className="text-center bold col-8">
                This company {isCompliant} CyberEssentials Compliant
              </div>
            </div>
            <div className="d-flex justify-content-center col-12">
              <div
                className="text-end col col-md-6 ms-4 me-2"
                style={{ color: "gray" }}
              >
                Last Refreshed:
              </div>
              <div
                className="text-start col col-md-6"
                style={{ color: "gray" }}
              >
                28/03/2024
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex mt-3 col-11">
          <div className="col-4 ms-4">
            <div style={{ fontSize: 26, color: "gray" }} className="mb-5">
              DEVICES
            </div>
            <div className="d-flex flex-column flex-md-row mb-4">
              <div className="col col-md-4" style={{ color: "gray" }}>
                No of Devices:
              </div>
              <div className="col col-md-4 ms-2" style={{ color: "gray" }}>
                {devices && devices.length < 21
                  ? "1 - 20"
                  : devices.length < 101
                  ? "20 - 100"
                  : devices.length < 1001
                  ? "100 - 1000"
                  : "1000+"}
              </div>
            </div>
            <div className="col-8 mb-5">
              <div className="d-flex">
                <div
                  style={{
                    width: `${
                      compliantDevicePercentage < 6
                        ? "5"
                        : compliantDevicePercentage
                    }%`,
                    color: "gray",
                  }}
                  className="d-flex justify-content-end"
                >
                  {Number.isInteger(compliantDevicePercentage)
                    ? compliantDevicePercentage + "%"
                    : compliantDevicePercentage &&
                      compliantDevicePercentage.toFixed(2) + "%"}
                </div>
              </div>

              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: "green",
                    height: 25,
                    width: `${compliantDevicePercentage}%`,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "darkOrange",
                    height: 25,
                    width: `${minorIssueDevicePercentage}%`,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "red",
                    height: 25,
                    width: `${
                      100 -
                      compliantDevicePercentage -
                      minorIssueDevicePercentage
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div style={{ color: "gray" }} className="me-2">
                {Number.isInteger(compliantDevicePercentage)
                  ? compliantDevicePercentage + "%"
                  : compliantDevicePercentage &&
                    compliantDevicePercentage.toFixed(2) + "%"}{" "}
                of devices are compliant.
              </div>
              <div style={{ color: "gray" }} className="me-2">
                {Number.isInteger(seriousIssueDevicePercentage)
                  ? seriousIssueDevicePercentage + "%"
                  : seriousIssueDevicePercentage &&
                    seriousIssueDevicePercentage.toFixed(2) + "%"}{" "}
                of devices have serious issues.
              </div>
              <div style={{ color: "gray" }} className="me-2">
                {Number.isInteger(monitoredDevicePercentage)
                  ? monitoredDevicePercentage + "%"
                  : monitoredDevicePercentage &&
                    monitoredDevicePercentage.toFixed(2) + "%"}{" "}
                of devices are monitored.
              </div>
            </div>
          </div>

          <div className="col-4 ms-4">
            <div style={{ fontSize: 26, color: "gray" }} className="mb-5">
              EMPLOYEES
            </div>
            <div className="d-flex flex-column flex-md-row mb-4">
              <div className="col col-md-4" style={{ color: "gray" }}>
                No of Employees:
              </div>
              <div className="col col-md-4 ms-2" style={{ color: "gray" }}>
                {employees && employees.length < 21
                  ? "1 - 20"
                  : employees.length < 101
                  ? "20 - 100"
                  : employees.length < 1001
                  ? "100 - 1000"
                  : "1000+"}
              </div>
            </div>
            <div className="col-8 mb-5">
              <div
                style={{
                  width: `${
                    compliantEmployeePercentage < 6
                      ? "5"
                      : compliantEmployeePercentage
                  }%`,
                  color: "gray",
                }}
                className="d-flex justify-content-end"
              >
                {compliantEmployeePercentage}%
              </div>
              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: "green",
                    height: 25,
                    width: `${compliantEmployeePercentage}%`,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "red",
                    height: 25,
                    width: `${100 - compliantEmployeePercentage}%`,
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div style={{ color: "gray" }} className="me-2">
                {compliantEmployeePercentage}% of employees have recently been
                trained.
              </div>
              <div style={{ color: "gray" }} className="me-2">
                {100 - compliantEmployeePercentage}% of employees have not been
                trained in the last 6 months.
              </div>
            </div>
          </div>

          {/* Audits currently hardcoded pending future updates */}
          <div className="col-5 ms-4">
            <div style={{ fontSize: 26, color: "gray" }} className="mb-5">
              AUDITS
            </div>
            <div className="d-flex flex-column flex-md-row mb-4">
              <div className="col col-md-4" style={{ color: "gray" }}>
                Last Audit Complete:
              </div>
              <div className="col col-md-4 ms-2" style={{ color: "gray" }}>
                22/03/2024
              </div>
            </div>
            <div className="col-8 mb-5">
              <div
                style={{
                  width: "75%",
                  color: "gray",
                }}
                className="d-flex justify-content-end"
              >
                75%
              </div>
              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: "green",
                    height: 25,
                    width: `75%`,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: "darkOrange",
                    height: 25,
                    width: `25%`,
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div style={{ color: "gray" }} className="me-2 col-10">
                The last audit was completed 8 days ago.
              </div>
              <div style={{ color: "gray" }} className="me-2 col-10">
                The pass rate was 75%, with a number of small issues
                highlighted.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
