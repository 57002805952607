import React, { useEffect, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import SubmitButton from "../../../Components/SubmitButton"
import axios from "axios"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

export default function ResendConfirmationForm() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (email === "") {
      setEmailError("Please provide and email address.")
    } else {
      setSubmitting(true)

      try {
        await axios.post(
          `https://${process.env.REACT_APP_IP}/api/users/resendconfirmation/${email}`,
          email
        )

        toast.success("Confirmation sent. Please check your inbox")
        navigate("../login")
      } catch (err) {
        console.log(err)
        if (err.response.data === "User is alread confirmed") {
          toast.error(err.response.data)
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <FloatingLabel label="Email Address" className="mb-3">
          <Form.Control
            value={email}
            required
            type="email"
            placeholder="name@example.com"
            isInvalid={emailError}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {emailError}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="d-flex justify-content-end align-items-center mt-3">
        <button
          className="btn btn-danger me-2"
          type="button"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <SubmitButton submitting={submitting} />
      </Form.Group>
    </Form>
  )
}
