//Axios
import axios from "axios"

//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"

//React
import { useEffect, useState } from "react"

//Router
import { useNavigate } from "react-router-dom"
//Helper
import { validateForm } from "../../../Helpers/FormValidation"
import { toast } from "react-toastify"

import SubmitButton from "../../../Components/SubmitButton"

export default function RegisterForm() {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [form, setForm] = useState({})
  const [formErrors, setFormErrors] = useState({})

  const postRegister = async (data) => {
    setSubmitting(true)
    try {
      await axios.post(
        `https://${process.env.REACT_APP_IP}/api/register/`,
        data
      )
      setForm({})
      navigate("../login")
      toast.success("User created. Please confirm email then login.")
    } catch (err) {
      if (
        err.response.data === "A user already exists with this e-mail address."
      ) {
        setFormErrors((prev) => {
          return { ...prev, email: "Email already exists" }
        })
      } else if (
        err.response.data ===
        "An account already exists with this e-mail address"
      ) {
        setFormErrors((prev) => {
          return { ...prev, accountEmail: "Email already exists" }
        })
      } else {
        toast.error("Oops something went wrong. Please try again.")
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(form)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [form])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const {
      email,
      password,
      password2,
      accountName,
      accountEmail,
      accountAddress,
      accountPostCode,
    } = formErrors

    if (
      !email &&
      !password &&
      !password2 &&
      !accountName &&
      !accountEmail &&
      !accountAddress &&
      !accountPostCode
    ) {
      const user = {
        firstName: form.firstName,
        secondName: form.secondName,
        email: form.email,
        password: form.password1,
        accountAddress: form.accountAddress,
        accountEmail: form.accountEmail,
        accountName: form.accountName,
        accountPostCode: form.accountPostCode,
      }

      postRegister(user)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-1">
          <Form.Text className="text-white ">Company Details</Form.Text>
        </Form.Group>

        {/* ACCOUNT NAME */}
        <Form.Group controlId="validationCustom01">
          <FloatingLabel label="Company Name" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Name"
              isInvalid={formErrors.accountName}
              isValid={formErrors.accountName === ""}
              onChange={(e) => setField("accountName", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.accountName}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        {/* ACCOUNT EMAIL */}
        <Form.Group>
          <FloatingLabel label="Company Email Address" className="mb-3">
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              isInvalid={formErrors.accountEmail}
              isValid={formErrors.accountEmail === ""}
              onChange={(e) => setField("accountEmail", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.accountEmail}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        {/* ACCOUNT ADDRESS */}
        <Form.Group>
          <FloatingLabel label="Company Address" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="name@example.com"
              isInvalid={formErrors.accountAddress}
              isValid={formErrors.accountAddress === ""}
              onChange={(e) => setField("accountAddress", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.accountAddress}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Company Postcode" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="name@example.com"
              isInvalid={formErrors.accountPostCode}
              isValid={formErrors.accountPostCode === ""}
              onChange={(e) => setField("accountPostCode", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.accountPostCode}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Text className="text-white ">Personal Details</Form.Text>
        </Form.Group>

        <Form.Group controlId="validationCustom01">
          <FloatingLabel label="First Name" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter First Name"
              isInvalid={formErrors.firstName}
              isValid={formErrors.firstName === ""}
              onChange={(e) => setField("firstName", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.firstName}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="validationCustom01">
          <FloatingLabel label="Second Name" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Second Name"
              isInvalid={formErrors.secondName}
              isValid={formErrors.secondName === ""}
              onChange={(e) => setField("secondName", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.secondName}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Email Address" className="mb-3">
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              isInvalid={formErrors.email}
              isValid={formErrors.email === ""}
              onChange={(e) => setField("email", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Password" className="mb-3">
            <Form.Control
              required
              type="password"
              placeholder="Password"
              isInvalid={formErrors.password1}
              isValid={formErrors.password1 === ""}
              onChange={(e) => setField("password1", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.password1}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Re-Type Password" className="mb-3">
            <Form.Control
              required
              type="password"
              placeholder="Re-Type Password"
              isInvalid={formErrors.password2}
              isValid={formErrors.password2 === ""}
              onChange={(e) => setField("password2", e.target.value)}
            />
            <Form.Control.Feedback className="text-white" type="invalid">
              {formErrors.password2}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="d-flex justify-content-end align-items-center mt-3">
          <button
            className="btn btn-danger me-2"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <SubmitButton submitting={submitting} />
        </Form.Group>
      </Form>
    </div>
  )
}
