import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"

export default function EmployeeTable({
  setField,
  employees,
  selectEmployees,
}) {
  const handleDelete = (id) => {
    const filteredList = employees.filter((item) => item.id !== id)

    setField("employees", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newLog = {
      id: randomId,
      date: null,
    }

    setField("employees", [...employees, newLog])
  }

  const handleEdit = (id, field, value) => {
    const existing = employees.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...employees, newEdit]
    } else {
      let updatedList = employees.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("employees", updatedList)
    }
  }

  console.log(selectEmployees)
  return (
    <div className="d-flex flex-column align-items-center justify-content-center col">
      <table className="table table-borderless ">
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>NAME</td>
            <td>DATE TRAINED</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {employees &&
            employees.length > 0 &&
            employees.map((employee) => (
              <tr key={employee.id}>
                <td>
                  <Form.Select
                    required
                    value={employee.employeeId ?? "None"}
                    onChange={(e) =>
                      handleEdit(employee.id, "employeeId", e.target.value)
                    }
                  >
                    <option hidden value="">
                      Select Employee
                    </option>
                    {selectEmployees && selectEmployees.length > 0 ? (
                      selectEmployees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No Employees Found</option>
                    )}
                  </Form.Select>
                </td>
                <td>
                  <Form.Control
                    required
                    defaultValue={
                      employee.date ? employee.date.split("T")[0] : ""
                    }
                    type={`date`}
                    onChange={(e) =>
                      handleEdit(employee.id, "date", e.target.value)
                    }
                  />
                </td>
                <td className="d-flex justify-content-center ">
                  <div
                    className="btn btn-danger"
                    onClick={() => handleDelete(employee.id)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex col-12 justify-content-end">
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
