import React from "react"
import Fact1 from "./FactPages/Fact1"
import Fact2 from "./FactPages/Fact2"
import Fact3 from "./FactPages/Fact3"
import Fact4 from "./FactPages/Fact4"
import Fact5 from "./FactPages/Fact5"
import Fact6 from "./FactPages/Fact6"
import Fact7 from "./FactPages/Fact7"
import Fact8 from "./FactPages/Fact8"
import Fact9 from "./FactPages/Fact9"
import Fact10 from "./FactPages/Fact10"

export default function RightCon() {
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const rndInt = randomIntFromInterval(1, 10)

  return (
    <div className="d-none d-lg-flex col landingPage">
      {rndInt === 1 && <Fact1 />}
      {rndInt === 2 && <Fact2 />}
      {rndInt === 3 && <Fact3 />}
      {rndInt === 4 && <Fact4 />}
      {rndInt === 5 && <Fact5 />}
      {rndInt === 6 && <Fact6 />}
      {rndInt === 7 && <Fact7 />}
      {rndInt === 8 && <Fact8 />}
      {rndInt === 9 && <Fact9 />}
      {rndInt === 10 && <Fact10 />}
    </div>
  )
}
