import React from "react"
import { useAuthContext } from "../../Hooks/useAuthContext"

import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap"
import { NavLink } from "react-router-dom"

import "./TopNav.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulb, faQuestion } from "@fortawesome/free-solid-svg-icons"

export default function TopNav({ theme, setTheme }) {
  const { user, dispatch } = useAuthContext()

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light"
    setTheme(newTheme)
  }

  const help = () => {
    dispatch({
      type: "WELCOMEBACK",
      payload: { ...user, hasSeenTutorial: false },
    })
  }

  return (
    <>
      <Navbar
        className="topNav d-flex flex-column sticky"
        style={{ height: "100%" }}
      >
        {user && (
          <div
            style={{ height: 100 }}
            className="d-flex justify-content-between align-items-center col-12"
          >
            <Nav className="d-flex ms-3">
              <NavLink to="/" className="navbar-brand text-white">
                <img
                  height={75}
                  width="auto"
                  src={require("../Imgs/Logo.svg").default}
                />
              </NavLink>
            </Nav>
            <div className="d-flex">
              {/* <div
                className="d-flex align-items-center px-2 hoverMe me-5"
                onClick={help}
              >
                <FontAwesomeIcon icon={faQuestion} />
              </div> */}
              <Nav className="d-flex align-items-center me-3">
                {user && (
                  <>
                    <Nav className="d-flex align-items-center">
                      <NavLink
                        className="d-flex align-items-center nav-link"
                        to="/user"
                      >
                        <div
                          style={{ borderRadius: 35 }}
                          className="d-flex justify-content-center align-items-center user-image me-3"
                        >
                          {user.initials}
                        </div>
                        <div className="d-flex flex-column">
                          <div className="user-name" to="/user">
                            {user.name}
                          </div>
                          <div className="user-role" to="/user">
                            {user.isAdmin ? "Administrator" : "User"}
                          </div>
                        </div>
                      </NavLink>
                      {/* <div
                        className="d-flex align-items-center px-2 hoverMe"
                        onClick={switchTheme}
                      >
                        <FontAwesomeIcon icon={faLightbulb} />
                      </div> */}
                    </Nav>
                  </>
                )}
              </Nav>
            </div>
          </div>
        )}
        <div className="d-flex gradient-bar col-12" />
      </Navbar>
    </>
  )
}
