import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"
import TableItem from "./TableItem"

export default function SoftwareTable({ software, setField, deletedSoftware }) {
  const [deleteList, setDeleteList] = useState([])

  const handleDelete = (id) => {
    setDeleteList((prev) => {
      return [...prev, id]
    })

    const filteredList = software.filter((item) => item.id !== id)

    if (!isNaN(id)) {
      setField("deletedSoftware", [...deletedSoftware, id])
    }

    setField("software", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newSoftware = {
      id: randomId,
    }

    setField("software", [...software, newSoftware])
  }

  const handleEdit = (id, field, value) => {
    const existing = software.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...software, newEdit]
    } else {
      let updatedList = software.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("software", updatedList)
    }
  }

  return (
    <div className="d-flex flex-column">
      <table className="table table-borderless" style={{ minWidth: "1000px" }}>
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>PRODUCT</td>
            <td>INSTALLED VERSION</td>
            <td>LATEST VERSION</td>
            <td>LAST CHECKED</td>
            <td>OUT OF SUPPORT</td>
            <td>UNINSTALL DATE</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {software.map((item) => (
            <tr key={item.id}>
              <td>
                <TableItem
                  item={item}
                  field="productName"
                  handleEdit={handleEdit}
                />
              </td>
              <td>
                <TableItem
                  item={item}
                  field="installedVersion"
                  handleEdit={handleEdit}
                />
              </td>
              <td>
                <TableItem
                  item={item}
                  field="latestVersion"
                  handleEdit={handleEdit}
                />
              </td>
              <td>
                <Form.Control
                  defaultValue={
                    item.dateLastVerified
                      ? item.dateLastVerified.split("T")[0]
                      : ""
                  }
                  type={`date`}
                  onChange={(e) =>
                    handleEdit(item.id, "dateLastVerified", e.target.value)
                  }
                />
              </td>
              <td className="d-flex justify-content-center">
                <Form.Check
                  onChange={(e) =>
                    handleEdit(item.id, "outOfSupport", e.target.checked)
                  }
                  defaultChecked={item.outOfSupport}
                />
              </td>
              <td>
                <Form.Control
                  defaultValue={
                    item.dateOfUninstall
                      ? item.dateOfUninstall.split("T")[0]
                      : ""
                  }
                  type={`date`}
                  onChange={(e) =>
                    handleEdit(item.id, "dateOfUninstall", e.target.value)
                  }
                />
              </td>

              <td className="d-flex justify-content-center ">
                <div
                  className="btn btn-danger"
                  onClick={() => handleDelete(item.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="my-2 d-flex justify-content-end"
        style={{ minWidth: "800px" }}
      >
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
