import {
  faCaretDown,
  faCaretUp,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import useLocalStorage from "use-local-storage"
import TablePageSelect from "../../../Components/Table/TablePageSelect"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import Spinner from "../../../Components/Loading/Spinner"

export default function CloudServicesHome() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [cloudServiceList, setCloudServiceList] = useState([])
  const [loading, setLoading] = useState(true)

  //Paginations
  const [pagination, setPagination] = useLocalStorage("servicesSearch", {
    status: location.state ? location.state : "all",
    pageNumber: 1,
    sort: "name-d",
    search: "",
    pageSize: 10,
  })

  const [totalCount, setTotalCount] = useState(0)

  const listCloudServices = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/cloudservices/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&status=${pagination.status}&pageNumber=${pagination.pageNumber}&sort=${pagination.sort}&search=${pagination.search}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setCloudServiceList(res.data.services)
      setTotalCount(res.data.count)
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listCloudServices()
  }, [user, pagination])

  return (
    <div className="p-4">
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div>
          <h1 className="pb-4">Cloud Services</h1>
        </div>
        <div>
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate("./cloud_service/0")}
          >
            Add Service
          </button>
        </div>
      </div>

      <div className="d-flex mb-2">
        <div className="col-3 me-2">
          <Form.Control
            value={pagination.search}
            placeholder="Search cloud services..."
            onChange={(e) =>
              setPagination((prev) => {
                return { ...prev, search: e.target.value }
              })
            }
          />
        </div>
        <div className="col-2 me-2">
          <Form.Select
            value={pagination.status}
            onChange={(e) => {
              setPagination((prev) => {
                return { ...prev, status: e.target.value }
              })
              setPagination((prev) => {
                return { ...prev, pageNumber: 1 }
              })
            }}
            required
          >
            <option value="all">Show All</option>
            <option value="MFA-enabled">MFA Enabled</option>
            <option value="MFA-not-enabled">MFA Not Enabled</option>
          </Form.Select>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless ">
          <thead
            className="bold"
            style={{ borderBottom: "1px solid var(--secondary)" }}
          >
            <tr>
              <td
                className="hoverMe"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort: pagination.sort === "name-a" ? "name-d" : "name-a",
                    }
                  })
                }
              >
                NAME
                {pagination.sort === "name-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "name-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td>MFA</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3} className="text-center py-3">
                  <Spinner />
                </td>
              </tr>
            ) : cloudServiceList.length > 0 ? (
              cloudServiceList.map((service) => (
                <tr key={service.id} className="tableRow">
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_cloud_service/${service.id}`)
                    }
                  >
                    {service.name}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_cloud_service/${service.id}`)
                    }
                  >
                    {service.mfaEnabled ? "True" : "False"}
                  </td>
                  <td className="d-flex justify-content-center">
                    <button
                      style={{ fontSize: 14, width: 100, borderRadius: 6 }}
                      className="btn btn-primary"
                      onClick={() => navigate(`./cloud_service/${service.id}`)}
                    >
                      EDIT
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center py-3">
                  No results...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <TablePageSelect
        pageNumber={pagination.pageNumber}
        setPagination={setPagination}
        totalCount={totalCount}
        pagination={pagination}
      />
    </div>
  )
}
