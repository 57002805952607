import { faCloud } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact4() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact4 text-white">
            <span className="fact4Span">79%</span> of companies have experienced
            at least one cloud data breach in the past 18 months
          </h2>
          <p className="fact-reference text-white"> - purplesec.us</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faCloud} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          It is imperitive that multi-factor authentication is enabled for all
          cloud services. Although multi-factor authentication is not the
          cure-all it once was it can still block a whopping 99.9% of modern
          automated cyberattacks.
        </h3>
      </div>
    </div>
  )
}
