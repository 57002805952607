import React from "react"

export default function ViewDeviceRow({ label, content, style }) {
  return (
    <div style={{ ...style }} className="d-flex flex-column flex-md-row">
      <div className="col col-md-5 ms-2">{label}</div>
      <div className="col col-md-6 ms-2">{content}</div>
    </div>
  )
}
