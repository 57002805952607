import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditCloudService from "./Pages/CloudService/CreateEditCloudService"
import ViewCloudService from "./Pages/CloudService/ViewCloudService"
import CloudServicesHome from "./Pages/CloudServicesHome"

export default function CloudServices() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<CloudServicesHome />} />
        <Route path="/cloud_service/:id" element={<CreateEditCloudService />} />
        <Route path="/view_cloud_service/:id" element={<ViewCloudService />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}
