import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import { toast } from "react-toastify"
import DeviceTable from "./Components/DeviceTable"
import useLocalStorage from "use-local-storage"
import TablePageSelect from "../../Components/Table/TablePageSelect"

export default function CreateEditIncidentReport() {
  const { user } = useAuthContext()
  const bundle = "Incident Report Type"
  const [report, setReport] = useState({})
  const [devicesInvolved, setDevicesInvolved] = useState([])
  const [devices, setDevices] = useState("")
  const [type, setType] = useState("default")
  const [offices, setOffices] = useState([])
  const [types, setTypes] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const params = useParams()

  const [pagination, setPagination] = useLocalStorage("devicesSearch", {
    pageNumber: 1,
    search: "",
    pageSize: 10,
    office: "",
    room: "",
  })

  useEffect(() => {
    const getDevices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&office=${pagination.office}&room=${pagination.room}&pageNumber=${pagination.pageNumber}&search=${pagination.search}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setDevices(
            res.data.devices.filter((x) => x.name !== "Unknown Device")
          )
          setTotalCount(res.data.count)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    getDevices()
  }, [user, pagination])

  useEffect(() => {
    const getTypes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/selectListOptions/${bundle}/listoptions`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setTypes(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    const getOffices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/offices/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setOffices(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    getTypes()
    getOffices()
  }, [params.id])

  useEffect(() => {
    const getIncidentReport = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/incidentreports/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setReport(res.data)
      } catch (error) {}
    }
    if (params.id !== "0") {
      getIncidentReport()
    }
    setLoading(false)
  }, [params.id])

  const setField = (field, value) => {
    setReport((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const data = {
      IncidentTitle: report.incidentTitle,
      IncidentType: report.incidentType,
      DateOccurred: report.dateOccurred,
      Description: report.description,
      Mitigation: report.mitigation,
      RepairTime: report.repairTime,
      RepairCost: report.repairCost,
      LessonsLearned: report.lessonsLearned,
      DeviceIds: devicesInvolved.map((x) => x.deviceId),
    }

    try {
      if (params.id !== "0") {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/incidentreports/${report.id}`,
            data,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Incident report updated.")
          navigate(-1)
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/incidentreports/`,
            data,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Incident report created.")
          navigate(-1)
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    }
  }

  useEffect(() => {
    const getIncidentDevices = async () => {
      if (report.id) {
        try {
          const res = await axios.get(
            `https://${process.env.REACT_APP_IP}/api/incidentdevices/${report.id}`,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          if (res.status === 200) {
            setDevicesInvolved(res.data)
          }
        } catch (err) {
          console.log(err.response)
        }
      }
    }

    getIncidentDevices()
  }, [report.id])

  // const addAllDevices = (e) => {
  //   e.preventDefault()
  //   if (devices && devices.length > 0) {
  //     var addDevices = []

  //     devices.map((device) => {
  //       const randomId = (Math.random() + 1).toString(36).substring(7)
  //       addDevices = [
  //         ...addDevices,
  //         { id: randomId, deviceId: device.id.toString() },
  //       ]
  //     })

  //     setDevicesInvolved([...addDevices])
  //   }
  // }

  if (!loading) {
    return (
      <div className="p-4">
        {params.id === "0" ? (
          <h2 className="mb-5">Create Incident Report</h2>
        ) : (
          <h2 className="mb-5">Edit Incident Report</h2>
        )}
        <Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Title</Form.Label>
            <div className="col-md-3">
              <Form.Control
                value={report.incidentTitle ?? ""}
                placeholder="Title"
                required
                onChange={(e) => setField("incidentTitle", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Type</Form.Label>
            <div className="col-md-3">
              <Form.Select
                required
                value={report.incidentType ? report.incidentType : type}
                onChange={(e) => {
                  setType(e.target.value)
                  setField("incidentType", e.target.value)
                }}
              >
                <option hidden value={"default"}>
                  Select Type
                </option>
                {types &&
                  types.map((type) => {
                    return (
                      <option key={type.id} value={type.value}>
                        {type.text}
                      </option>
                    )
                  })}
              </Form.Select>
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Description</Form.Label>
            <div className="col-md-6">
              <Form.Control
                as="textarea"
                rows={3}
                required
                value={report.description ?? ""}
                placeholder="Description"
                onChange={(e) => setField("description", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Mitigation</Form.Label>
            <div className="col col-md-6">
              <Form.Control
                as="textarea"
                rows={3}
                required
                value={report.mitigation ?? ""}
                placeholder="Mitigation"
                onChange={(e) => setField("mitigation", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Date Occurred</Form.Label>
            <div className="col col-md-2">
              <Form.Control
                defaultValue={
                  report.dateOccurred ? report.dateOccurred.split("T")[0] : ""
                }
                required
                type={`date`}
                onChange={(e) => setField("dateOccurred", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Repair Time (hrs)</Form.Label>
            <div className="col-md-3">
              <Form.Control
                required
                type="number"
                value={report.repairTime ?? ""}
                placeholder="Repair Time (hrs)"
                onChange={(e) => setField("repairTime", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Repair Cost (£)</Form.Label>
            <div className="col-md-3">
              <Form.Control
                required
                type="number"
                value={report.repairCost ?? ""}
                placeholder="Repair Cost (£)"
                onChange={(e) => setField("repairCost", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">Lessons Learned</Form.Label>
            <div className="col col-md-6">
              <Form.Control
                as="textarea"
                rows={3}
                required
                value={report.lessonsLearned ?? ""}
                placeholder="Lessons Learned"
                onChange={(e) => setField("lessonsLearned", e.target.value)}
              />
            </div>
          </Form.Group>

          <div className="d-flex align-items-center">
            <p className="d-flex mt-3" style={{ fontSize: 26 }}>
              DEVICES INVOLVED
            </p>
          </div>
          <div className="d-flex">
            <div className="col-3 me-2">
              <Form.Control
                placeholder="Search devices..."
                value={pagination.search}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, search: e.target.value }
                  })
                }
              />
            </div>{" "}
            <div className="col-2 me-2">
              <Form.Select
                value={pagination.office}
                onChange={(e) => {
                  setPagination((prev) => {
                    return { ...prev, office: e.target.value }
                  })
                  setPagination((prev) => {
                    return { ...prev, pageNumber: 1 }
                  })
                }}
                required
              >
                <option value="">Any Office</option>
                {offices &&
                  offices.map((office) => {
                    return (
                      <option key={office} value={office}>
                        {office}
                      </option>
                    )
                  })}
              </Form.Select>
            </div>
            <div className="col-1 me-2">
              <Form.Control
                placeholder="Room..."
                value={pagination.room}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, room: e.target.value }
                  })
                }
              />
            </div>
          </div>
          <Form.Group className="d-flex flex-column flex-md-row">
            <DeviceTable
              devicesInvolved={devicesInvolved}
              setDevicesInvolved={setDevicesInvolved}
              devices={devices}
            />
          </Form.Group>
          <TablePageSelect
            classes="mt-3"
            pageNumber={pagination.pageNumber}
            setPagination={setPagination}
            totalCount={totalCount}
            pagination={pagination}
          />

          <div>
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </Form>
      </div>
    )
  }
}
