import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function TablePageSelect({
  classes,
  pageNumber,
  setPagination,
  totalCount,
  pagination,
}) {
  return (
    <div className={`d-flex ${classes}  gap-3 justify-content-end`}>
      <div className="d-flex align-items-center">
        <div className="me-2">Show</div>

        <select
          className="form-select"
          defaultValue={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => {
              return { ...prev, pageSize: e.target.value, pageNumber: 1 }
            })
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div>
        <button
          disabled={pageNumber === 1 ? true : false}
          className="btn btn-primary"
          onClick={() =>
            setPagination((prev) => {
              return { ...prev, pageNumber: pageNumber - 1 }
            })
          }
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </div>

      <div className="d-flex justify-content-center align-content-center pt-2">
        <div>
          {pageNumber} /{" "}
          {Math.ceil(totalCount / pagination.pageSize) < 1
            ? 1
            : Math.ceil(totalCount / pagination.pageSize)}
        </div>
      </div>
      <div>
        <button
          className="btn btn-primary"
          onClick={() =>
            setPagination((prev) => {
              return { ...prev, pageNumber: pageNumber + 1 }
            })
          }
          disabled={
            totalCount <= pageNumber * pagination.pageSize ? true : false
          }
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>
    </div>
  )
}
