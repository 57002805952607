import React from "react"

export default function TableItem({ item, field, handleEdit, type }) {
  return (
    <input
      required
      className="form-control"
      type={`${type}`}
      defaultValue={item[field] ?? ""}
      onChange={(e) => handleEdit(item.id, field, e.target.value)}
    />
  )
}
