import axios from "axios"
//React
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
//Hooks
import { useAuthContext } from "../../Hooks/useAuthContext"
//Components
import SoftwareTable from "./Components/CreateEdit/SoftwareTable"
import HDDTable from "./Components/CreateEdit/HDDTable"
import { useNavigate, useParams } from "react-router-dom"
import SubmitButton from "../../Components/SubmitButton"
import { toast } from "react-toastify"
import DeviceViewIcon from "./Components/View/DeviceViewIcon"

export default function CreateEditDevice() {
  const { user } = useAuthContext()
  const [device, setDevice] = useState({
    accountId: user.accountId,
    hdds: [],
    deletedHDDS: [],
    software: [],
    deletedSoftware: [],
    openPorts: [],
    deletedOpenPorts: [],
    automaticUpdatesEnabled: false,
    backupsEnabled: false,
    defaultPasswordReset: false,
    hasInternetAccess: false,
    pcAutoPlayDisabled: false,
    mobileAppStoreOnly: false,
    mobilePasswordPinProtected: false,
    manufacturer: "",
  })
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  const [deviceTypes, setDeviceTypes] = useState([])
  const deviceTypeBundle = "Device Type"

  useEffect(() => {
    const getDevice = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )

        setDevice({
          ...res.data,
          deletedSoftware: [],
          deletedHDDS: [],
          deletedOpenPorts: [],
        })
      } catch (error) {}
    }
    if (params.id !== "0") {
      getDevice()
    }
    setLoading(false)
  }, [params.id])

  useEffect(() => {
    const getDeviceTypes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/selectListOptions/${deviceTypeBundle}/listoptions`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setDeviceTypes(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    getDeviceTypes()
  }, [deviceTypeBundle])

  const setField = (field, value) => {
    setDevice((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (params.id !== "0") {
        const postData = {
          ...device,
          software: device.software.map((instance) => {
            if (isNaN(instance.id)) {
              instance.id = null
            }
            return instance
          }),
          hdds: device.hdds.map((instance) => {
            if (isNaN(instance.id)) {
              instance.id = null
            }
            return instance
          }),
          openPorts: device.openPorts.map((instance) => {
            if (isNaN(instance.id)) {
              instance.id = null
            }
            return instance
          }),
        }
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/devices/${device.id}`,
            postData,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Device updated.")
          navigate("/devices")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/devices/`,
            device,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Device created.")
          navigate("/devices")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      setSubmitting(false)
    }
  }

  console.log(device)

  if (!loading) {
    return (
      <div className="p-4">
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <div className="d-flex col-12 mb-4">
            <div className="d-flex flex-column col-8">
              <div className="d-flex flex-column col gap-1 mb-3">
                <div className="d-flex">
                  {params.id === "0" ? (
                    <h2 className="mb-3">Create Device</h2>
                  ) : (
                    <h2 className="mb-3">Edit Device</h2>
                  )}

                  <div className="ms-5">
                    <button
                      type="button"
                      className="btn btn-danger me-2"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <SubmitButton submitting={submitting} />
                  </div>
                </div>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Name</Form.Label>
                  <div className="col col-md-6">
                    <Form.Control
                      value={device.name ?? ""}
                      placeholder="Name"
                      onChange={(e) => setField("name", e.target.value)}
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Ip Address</Form.Label>
                  <div className="col col-md-6">
                    <Form.Control
                      value={device.ipAddress ?? ""}
                      placeholder="Ip Address"
                      onChange={(e) => setField("ipAddress", e.target.value)}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex flex-column flex-md-row mb-2">
                  <Form.Label className="col col-md-4">Device Type</Form.Label>
                  <div className="col col-md-6 ">
                    <Form.Select
                      value={device.type ?? ""}
                      onChange={(e) => setField("type", e.target.value)}
                      required
                    >
                      <option hidden value="">
                        Select Type
                      </option>
                      {deviceTypes &&
                        deviceTypes.map((type) => {
                          return (
                            <option key={type.id} value={type.value}>
                              {type.text}
                            </option>
                          )
                        })}
                    </Form.Select>
                  </div>
                </Form.Group>
              </div>

              {device.type === "Server" && (
                <div>
                  <Form.Group className="d-flex flex-column flex-md-row mb-3">
                    <Form.Label className="col col-md-4">
                      Virtual Server?
                    </Form.Label>
                    <div className="col col-1">
                      <Form.Check
                        onChange={(e) =>
                          setField("virtualServer", e.target.checked)
                        }
                        checked={device.virtualServer}
                      />
                    </div>
                  </Form.Group>
                </div>
              )}

              <div className="d-flex flex-column col gap-1 mb-4">
                <Form.Group className="d-flex">
                  <div className="col d-flex flex-column flex-md-row ">
                    <Form.Label className="col col-md-4">
                      Manufacturer
                    </Form.Label>
                    <div className="col col-md-6">
                      <Form.Control
                        value={device.manufacturer ?? ""}
                        placeholder="Manufacturer"
                        onChange={(e) =>
                          setField("manufacturer", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="d-flex">
                  <div className="col d-flex flex-column flex-md-row ">
                    <Form.Label className="col col-md-4">Model</Form.Label>
                    <div className="col col-md-6">
                      <Form.Control
                        value={device.model ?? ""}
                        placeholder="Model"
                        onChange={(e) => setField("model", e.target.value)}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="d-flex">
                  <div className="col d-flex flex-column flex-md-row ">
                    <Form.Label className="col col-md-4">Serial</Form.Label>
                    <div className="col col-md-6">
                      <Form.Control
                        value={device.serial ?? ""}
                        placeholder="Serial"
                        onChange={(e) => setField("serial", e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>

              <div className="d-flex flex-column col gap-1 mb-4">
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Processor</Form.Label>
                  <div className="col d-flex gap-1">
                    <div className="col col-md-2">
                      <Form.Control
                        value={device.cpuManufacturer ?? ""}
                        placeholder="Manufacturer"
                        onChange={(e) =>
                          setField("cpuManufacturer", e.target.value)
                        }
                        required
                      />
                    </div>
                    <div className="col col-md-5">
                      <Form.Control
                        value={device.cpuModel ?? ""}
                        placeholder="Model"
                        onChange={(e) => setField("cpuModel", e.target.value)}
                        required
                      />
                    </div>
                    <div className="col col-md-2">
                      <Form.Control
                        value={device.cpuSpeed ?? ""}
                        placeholder="Speed"
                        type="number"
                        onChange={(e) => setField("cpuSpeed", e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Memory</Form.Label>
                  <div className="col d-flex gap-1">
                    <div className="col col-md-2">
                      <Form.Control
                        value={device.ramSize ?? ""}
                        type="number"
                        placeholder="Size"
                        onChange={(e) => setField("ramSize", e.target.value)}
                        required
                      />
                    </div>
                    <div className="col col-md-7">
                      <Form.Control
                        value={device.ramType ?? ""}
                        placeholder="Type"
                        onChange={(e) => setField("ramType", e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Graphics Card
                  </Form.Label>
                  <div className="col col-md-6">
                    <Form.Control
                      value={device.graphicsCard ?? ""}
                      placeholder="GPU Details"
                      onChange={(e) => setField("graphicsCard", e.target.value)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Mac Address</Form.Label>
                  <div className="col col-md-6">
                    <Form.Control
                      value={device.macAddress ?? ""}
                      placeholder="Mac Address"
                      onChange={(e) => setField("macAddress", e.target.value)}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="d-flex flex-column col gap-1 mb-4">
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Has Internet Access
                  </Form.Label>
                  <div className="col col-1">
                    <Form.Check
                      onChange={(e) =>
                        setField("hasInternetAccess", e.target.checked)
                      }
                      checked={device.hasInternetAccess}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Backups Enabled
                  </Form.Label>
                  <div className="col col-1">
                    <Form.Check
                      onChange={(e) =>
                        setField("backupsEnabled", e.target.checked)
                      }
                      checked={device.backupsEnabled}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Default Password Reset
                  </Form.Label>
                  <div className="col col-1">
                    <Form.Check
                      onChange={(e) =>
                        setField("defaultPasswordReset", e.target.checked)
                      }
                      checked={device.defaultPasswordReset}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Auto Play Disabled
                  </Form.Label>
                  <div className="col col-1">
                    <Form.Check
                      onChange={(e) =>
                        setField("pcAutoPlayDisabled", e.target.checked)
                      }
                      checked={device.pcAutoPlayDisabled}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <div className="d-flex col flex-column flex-md-row align-items-mm-center">
                    <Form.Label className="col col-md-4">
                      Automatic Updates Enabled
                    </Form.Label>
                    <div className="col col-md-1">
                      <Form.Check
                        onChange={(e) =>
                          setField("automaticUpdatesEnabled", e.target.checked)
                        }
                        checked={device.automaticUpdatesEnabled}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Automatic Updates Last Checked
                  </Form.Label>
                  <div className="col col-md-2">
                    <Form.Control
                      type="date"
                      onChange={(e) =>
                        setField("automaticUpdatesLastChecked", e.target.value)
                      }
                      defaultValue={
                        device.automaticUpdatesLastChecked
                          ? device.automaticUpdatesLastChecked.split("T")[0]
                          : ""
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="d-flex flex-column col gap-1 mb-4">
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Date Last Audited
                  </Form.Label>
                  <div className="col col-2">
                    <Form.Control
                      defaultValue={
                        device.dateLastAudited
                          ? device.dateLastAudited.split("T")[0]
                          : ""
                      }
                      type={`date`}
                      onChange={(e) =>
                        setField("dateLastAudited", e.target.value)
                      }
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="d-flex flex-column col gap-1 mb-4">
                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">
                    Operating System
                  </Form.Label>
                  <div className="col d-flex gap-1">
                    <div className="col col-md-6">
                      <Form.Control
                        required
                        type="text"
                        onChange={(e) =>
                          setField("operatingSystem", e.target.value)
                        }
                        value={device.operatingSystem ?? ""}
                      />
                    </div>
                    <div className="col col-md-3">
                      <Form.Control
                        required
                        type="text"
                        onChange={(e) =>
                          setField("operatingSystemVersion", e.target.value)
                        }
                        value={device.operatingSystemVersion ?? ""}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="d-flex">
                  <Form.Label className="col col-md-4">Anti-Virus</Form.Label>
                  <div className="col d-flex gap-1">
                    <div className="col col-md-6">
                      <Form.Control
                        type="text"
                        onChange={(e) => setField("antivirus", e.target.value)}
                        value={device.antivirus ?? ""}
                      />
                    </div>
                    <div className="col col-md-3">
                      <Form.Control
                        type="text"
                        onChange={(e) =>
                          setField("antivirusVersion", e.target.value)
                        }
                        value={device.antivirusVersion ?? ""}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="d-flex flex-column col-4">
              <div className="mb-5">
                <DeviceViewIcon device={device} />
              </div>
              <div className="ms-3">
                <div className="mb-3">
                  <Form.Group className="d-flex">
                    <Form.Label className="col col-md-4">
                      Date Purchased
                    </Form.Label>
                    <div className="col col-7">
                      <Form.Control
                        defaultValue={
                          device.purchaseDate
                            ? device.purchaseDate.split("T")[0]
                            : ""
                        }
                        type={`date`}
                        onChange={(e) =>
                          setField("purchaseDate", e.target.value)
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="mb-3">
                  <Form.Group className="d-flex">
                    <Form.Label className="col col-md-4">
                      Purchase Price (£)
                    </Form.Label>
                    <div className="col col-7">
                      <Form.Control
                        value={device.purchasePrice ?? ""}
                        type="number"
                        onChange={(e) =>
                          setField("purchasePrice", e.target.value)
                        }
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="mb-3">
                  <Form.Group className="d-flex">
                    <Form.Label className="col col-md-4">Location</Form.Label>

                    <div className="col col-7 d-flex gap-1">
                      <div className="col col-md-6">
                        <Form.Control
                          value={device.office ?? ""}
                          placeholder="Office"
                          onChange={(e) => setField("office", e.target.value)}
                        />
                      </div>
                      <div className="col col-md-6">
                        <Form.Control
                          value={device.room ?? ""}
                          placeholder="Room"
                          onChange={(e) => setField("room", e.target.value)}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          {(device.type === "Laptop" ||
            device.type === "Desktop" ||
            device.type === "Server") && (
            <div>
              <Form.Group className="d-flex flex-column mb-4">
                <div className="d-flex ">
                  <p style={{ fontSize: 26 }}>HDDs</p>
                </div>
                <HDDTable
                  hdds={device.hdds}
                  setField={setField}
                  deletedHDDS={device.deletedHDDS}
                />
              </Form.Group>

              <Form.Group className="d-flex flex-column mb-4">
                <div className="d-flex">
                  <p style={{ fontSize: 26 }}>SOFTWARE</p>
                </div>
                <SoftwareTable
                  software={device.software}
                  setField={setField}
                  deletedSoftware={device.deletedSoftware}
                />
              </Form.Group>
            </div>
          )}

          {/* Ports etc */}
          {/* <Form.Group className="d-flex flex-column flex-md-row">
              <PortsListeningTable ports={device.ports} setField={setField} />
            </Form.Group> */}
          <div>
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <SubmitButton submitting={submitting} />
          </div>
        </Form>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
