import React from "react"
import EmployeePie from "./Charts/EmployeePie"

export default function EmployeeStatus({ account, theme, navigate }) {
  return (
    <div className="p-3">
      <div className="mb-3">
        <EmployeePie
          theme={theme}
          trained={account.trainedEmployees}
          unTrained={account.unTrainedEmployees}
        />
      </div>
      <div className="mb-3">
        <h5>Total Employees</h5>
        <div className="total">
          {account.trainedEmployees + account.unTrainedEmployees}
        </div>
      </div>
      <div className="mb-3">
        <h5>Employees Trained</h5>
        <div className="d-flex justify-content-between">
          <div className="total">{account.trainedEmployees}</div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/employees", { state: "trained" })}
            >
              Show Me
            </button>
          </div>
        </div>
      </div>
      <div>
        <h5>Employees Reqiuire Training</h5>
        <div className="d-flex justify-content-between">
          <div className="total">{account.unTrainedEmployees}</div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/employees", { state: "un-trained" })}
            >
              Show Me
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
