import React from "react"

export default function IncidentReportRow({ label, content }) {
  return (
    <div className="d-flex col-5 mb-4">
      <div className="col col-md-4 ms-2">{label}</div>
      <div className="col col-md-8 ms-2">{content}</div>
    </div>
  )
}
