import React from "react"
import DevicePie from "./Charts/DevicePie"

export default function DeviceStatus({ account, theme, navigate }) {
  return (
    <div className="p-3">
      <div className="mb-3">
        <DevicePie
          theme={theme}
          audited={account.auditedDevices}
          unAudited={account.unAuditedDevices}
        />
      </div>
      <div className="mb-3">
        <h5>Total Devices</h5>
        <div className="total">
          {account.auditedDevices + account.unAuditedDevices}
        </div>
      </div>
      <div className="mb-3">
        <h5>Devices Audited</h5>
        <div className="d-flex justify-content-between">
          <div className="total">{account.auditedDevices}</div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/devices", { state: "audited" })}
            >
              Show Me
            </button>
          </div>
        </div>
      </div>
      <div>
        <h5>Devices Require Auditing</h5>
        <div className="d-flex justify-content-between">
          <div className="total">{account.unAuditedDevices}</div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/devices", { state: "un-audited" })}
            >
              Show Me
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
