import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { validateForm } from "../../../Helpers/FormValidation"
import { FloatingLabel, Form } from "react-bootstrap"
import SubmitButton from "../../../Components/SubmitButton"

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const params = useParams()

  console.log(params)

  const [userDetails, setUserDetails] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const changePassword = async (data) => {
    setSubmitting(true)

    try {
      await axios.post(
        `https://${process.env.REACT_APP_IP}/api/users/${params.id}/resetpassword`,
        data
      )

      toast.success("Password changed. Please login")
      navigate("../login")
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(userDetails)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [userDetails])

  console.log(formErrors, "Errors")

  const setField = (field, value) => {
    setUserDetails({
      ...userDetails,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { password, password2 } = formErrors

    if (!password && !password2) {
      const user = {
        code: params.code,
        password: userDetails.password1,
      }

      changePassword(user)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <FloatingLabel label="Password" className="mb-3">
          <Form.Control
            required
            value={userDetails.password1 ?? ""}
            type="password"
            placeholder="Password"
            isInvalid={formErrors.password1}
            isValid={formErrors.password1 === ""}
            onChange={(e) => setField("password1", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.password1}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group>
        <FloatingLabel label="Re-Type Password" className="mb-3">
          <Form.Control
            required
            value={userDetails.password2 ?? ""}
            type="password"
            placeholder="Re-Type Password"
            isInvalid={formErrors.password2}
            isValid={formErrors.password2 === ""}
            onChange={(e) => setField("password2", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.password2}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="d-flex justify-content-end align-items-center mt-3">
        <SubmitButton submitting={submitting} />
      </Form.Group>
    </Form>
  )
}
