import React from "react"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Spinner.css"

export default function Spinner({ children, size = "2x" }) {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center spinner">
      {children}
      <FontAwesomeIcon className="mt-3" icon={faSpinner} size={size} spin />
    </div>
  )
}
