import axios from "axios"
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import MainHeading from "../../Components/Headings/MainHeading"
import { locale } from "../../Config/Constants"
import IncidentReportRow from "./Components/IncidentReportRow"
import { toast } from "react-toastify"

export default function ViewIncidentReport() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [report, setReport] = useState({})
  const [loading, setLoading] = useState(true)
  const [devicesInvolved, setDevicesInvolved] = useState([])

  useEffect(() => {
    const getIncidentReport = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/incidentreports/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setReport(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getIncidentReport()
  }, [params.id])

  useEffect(() => {
    const getDevices = async () => {
      if (report.id) {
        try {
          const res = await axios.get(
            `https://${process.env.REACT_APP_IP}/api/incidentdevices/${report.id}`,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          if (res.status === 200) {
            if (res.data.length > 0) {
              res.data.map((device) => {
                setDevices(device)
              })
            }
          }
        } catch (err) {
          console.log(err.response)
        }
      }
    }

    getDevices()
  }, [report.id])

  const setDevices = (device) => {
    setDevicesInvolved((prev) => {
      return [...prev, device]
    })
  }

  const handleDelete = async (incidentReportId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/incidentreports/${incidentReportId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Incident Report deleted.")
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    }
  }
  {
    /* <div
                      className="btn btn-danger"
                      onClick={() => handleDelete(incidentReport.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} size={"1x"} />
                    </div> */
  }

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex flex-column col-12">
          <div className="d-flex mb-5">
            <h1>{report.incidentTitle}</h1>
            <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate(`../incident_report/${report.id}`)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(report.id)}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="d-flex flex-column mb-5">
            <IncidentReportRow label={"Title"} content={report.incidentTitle} />
            <IncidentReportRow label={"Type"} content={report.incidentType} />
            <IncidentReportRow
              label={"Description"}
              content={report.description}
            />
            <IncidentReportRow
              label={"Mitigation"}
              content={report.mitigation}
            />
            <IncidentReportRow
              label={"Date Occurred"}
              content={
                report.dateOccurred
                  ? new Date(report.dateOccurred).toLocaleDateString(locale)
                  : "Not Completed"
              }
            />
            <IncidentReportRow
              label={"Date Logged"}
              content={
                report.dateLogged
                  ? new Date(report.dateLogged).toLocaleDateString(locale)
                  : "Not Completed"
              }
            />

            <IncidentReportRow
              label={"repairTime"}
              content={report.repairTime}
            />
            <IncidentReportRow
              label={"repairCost"}
              content={report.repairCost}
            />
            <IncidentReportRow
              label={"lessonsLearned"}
              content={report.lessonsLearned}
            />
          </div>

          <div>
            <p style={{ fontSize: 26 }}>DEVICES INVOLVED</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless ">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                  </tr>
                </thead>
                <tbody>
                  {devicesInvolved.map((device) => (
                    <tr key={device.id} className="tableRow">
                      <td
                        className="hoverMe text-decoration-underline"
                        onClick={() =>
                          navigate(`/devices/view_device/${device.deviceId}`)
                        }
                      >
                        {device.deviceName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
