import React from "react"
import ReactDOM from "react-dom/client"
//Package styles
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.min.css"
//Global styles
import "./index.css"
import App from "./App"
//Bootstrap Styles

import { AuthContextProvider } from "./Context/AuthContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
)
