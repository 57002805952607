import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact7() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact7 text-white">
            <span className="fact7Span">39%</span> of UK businesses reported
            suffering a cyber attack in 2022
          </h2>
          <p className="fact-reference text-white"> - aag-it.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          Backing up your data regularly is an overlooked step in personal
          online security.The top IT and security managers follow a simple rule
          called the 3-2-1 backup rule. Essentially, you will keep three copies
          of your data on two different types of media (local and external hard
          drive) and one copy in an off-site location (cloud storage).
        </h3>
      </div>
    </div>
  )
}
