import {
  faAdd,
  faChampagneGlasses,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"

export default function DeviceLogs({
  setField,
  instances,
  devices,
  deletedInstances,
}) {
  const [deleteList, setDeleteList] = useState([])

  const handleDelete = (id) => {
    setDeleteList((prev) => {
      return [...prev, id]
    })

    const filteredList = instances.filter((item) => item.id !== id)

    if (!isNaN(id)) {
      setField("deletedInstances", [...deletedInstances, id])
    }

    setField("instances", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newLog = {
      id: randomId,
      removalMethod: "None",
    }

    setField("instances", [...instances, newLog])
  }

  const handleEdit = (id, field, value) => {
    const existing = instances.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...instances, newEdit]
    } else {
      let updatedList = instances.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("instances", updatedList)
    }
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center col">
      <table className="table table-borderless ">
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>NAME</td>
            <td>DATE CREATED</td>
            <td>DATE REMOVED</td>
            <td>REMOVAL METHOD</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {instances.map((instance) => (
            <tr key={instance.id}>
              <td>
                <Form.Select
                  required
                  value={instance.deviceId ?? ""}
                  onChange={(e) =>
                    handleEdit(instance.id, "deviceId", e.target.value)
                  }
                >
                  <option hidden value="">
                    Select Device
                  </option>
                  {devices.length > 0 &&
                    devices.map((device) => (
                      <option key={device.id} value={`${device.id}`}>
                        {device.name}
                      </option>
                    ))}
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  required
                  type="date"
                  defaultValue={
                    instance.dateCreated
                      ? instance.dateCreated.split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    handleEdit(instance.id, "dateCreated", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  type="date"
                  defaultValue={
                    instance.dateRemoved
                      ? instance.dateRemoved.split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    handleEdit(instance.id, "dateRemoved", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Select
                  value={instance.removalMethod ?? "None"}
                  onChange={(e) =>
                    handleEdit(instance.id, "removalMethod", e.target.value)
                  }
                >
                  <option value="None">N/A</option>
                  <option value="Deleted">Deleted</option>
                  <option value="SecureErasure">Secure Erasure</option>
                  <option value="Destroyed">Destroyed</option>
                </Form.Select>
              </td>
              <td className="d-flex justify-content-center">
                <div
                  className="btn btn-danger "
                  onClick={() => handleDelete(instance.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex col-12 justify-content-end">
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
