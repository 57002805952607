import axios from "axios"
import React from "react"
import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../../../Hooks/useAuthContext"
import UserTable from "./Components/CreateEdit/UserTable"
import SubmitButton from "../../../../Components/SubmitButton"
import { toast } from "react-toastify"

export default function CreateEditCloudService() {
  const { user } = useAuthContext()
  const [cloudService, setCloudService] = useState({
    accountId: user.accountId,
    mfaEnabled: false,
    employeesWithAccess: [],
    deletedEmployeeAccess: [],
  })
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const getCloudService = async () => {
      console.log("test")
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/cloudservices/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setCloudService({ ...res.data, deletedEmployeeAccess: [] })
      } catch (error) {
        console.log("error", error)
      }
    }
    if (params.id !== "0") {
      getCloudService()
    }
    setLoading(false)
  }, [params.id])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/employees/listbyaccount/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log("res.data", res.data)
        setEmployees(res.data.employees)
      } catch (error) {}
    }

    getUsers()

    setLoading(false)
  }, [user])

  const setField = (field, value) => {
    setCloudService((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (params.id !== "0") {
        const postData = {
          accountId: user.accountId,
          name: cloudService.name,
          type: cloudService.type,
          mfaEnabled: cloudService.mfaEnabled,
          deletedEmployeeAccess: cloudService.deletedEmployeeAccess,
          employeesWithAccess: cloudService.employeesWithAccess.map((e) => {
            if (isNaN(e.id)) {
              e.id = null
            }
            return e
          }),
        }
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/cloudservices/${cloudService.id}`,
            postData,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Cloud service updated.")
          navigate("/cloud_services")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/cloudservices/`,
            cloudService,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Cloud service created.")
          navigate("/cloud_services")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
    } finally {
      setSubmitting(false)
    }
  }

  const addAllEmployees = (e) => {
    e.preventDefault()
    if (employees && employees.length > 0) {
      var addEmployees = []

      employees.map((user) => {
        const randomId = (Math.random() + 1).toString(36).substring(7)
        addEmployees = [
          ...addEmployees,
          { id: randomId, isAdmin: false, employeeId: user.id.toString() },
        ]
      })

      setField("employeesWithAccess", [...addEmployees])
    }
  }

  console.log("employees", employees)

  if (!loading) {
    return (
      <div className="p-4">
        {params.id === "0" ? (
          <h2 className="mb-5">Create Cloud Service</h2>
        ) : (
          <h2 className="mb-5">Edit Cloud Service</h2>
        )}
        <Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-2">Name</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={cloudService.name ?? ""}
                required
                placeholder="Name"
                onChange={(e) => setField("name", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-2">Type</Form.Label>
            <div className="col-md-4">
              <Form.Select
                value={cloudService.type ?? ""}
                onChange={(e) => setField("type", e.target.value)}
                required
              >
                <option hidden value="">
                  Type
                </option>
                <option value="SAAS">SAAS</option>
                <option value="PAAS">PAAS</option>
                <option value="IAAS">IAAS</option>
              </Form.Select>
            </div>
          </Form.Group>
          <Form.Group className="mb-2 d-flex flex-column flex-md-row">
            <Form.Label className="col col-md-2">MFA Enabled</Form.Label>
            <div className="col ">
              <Form.Check
                onChange={(e) => setField("mfaEnabled", e.target.checked)}
                checked={cloudService.mfaEnabled}
              />
            </div>
          </Form.Group>

          {/* <Form.Group className="d-flex flex-column flex-md-row mb-2">
                <Form.Label className="col col-md-2">Start Date</Form.Label>
                <div>
                  <Form.Control
                    defaultValue={
                      cloudService.dateRecieved
                        ? cloudService.dateRecieved.split("T")[0]
                        : ""
                    }
                    type={`date`}
                    onChange={(e) => setField("dateRecieved", e.target.value)}
                  />
                </div>
              </Form.Group> */}

          <div className="d-flex align-items-center">
            <p className="d-flex mt-3" style={{ fontSize: 26 }}>
              EMPLOYEES WITH ACCESS
            </p>
            <button
              className="btn btn-primary ms-5"
              onClick={(e) => {
                addAllEmployees(e)
              }}
            >
              Add All Employees
            </button>
          </div>
          <Form.Group className="d-flex flex-column flex-md-row">
            <UserTable
              setField={setField}
              instances={cloudService.instances}
              deletedInstances={cloudService.deletedInstances}
              employeesWithAccess={cloudService.employeesWithAccess}
              selectUsers={employees}
              deletedEmployeeAccess={cloudService.deletedEmployeeAccess}
            />
          </Form.Group>

          <div>
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <SubmitButton submitting={submitting} />
          </div>
        </Form>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
