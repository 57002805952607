import { useState } from "react"

import { ToastContainer } from "react-toastify"
//Components
import { Navigate, Route, Routes } from "react-router-dom"
import LandingHome from "./Pages/LandingHome"
import Login from "./Pages/Login"
import Register from "./Pages/Register"
import Activate from "./Pages/Activate"
import ForgotPassword from "./Pages/ForgotPassword"
import ResetPassword from "./Pages/ResetPassword"

import "./LandingPage.css"
import ResendConfirmation from "./Pages/ResendConfirmation"

export default function LandingPage() {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<LandingHome />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="activate/:code" element={<Activate />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="resend_confirmation" element={<ResendConfirmation />} />
        <Route path="reset_password/:id/:code" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}
