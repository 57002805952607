import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditEmployee from "../Employee/CreateEditEmployee"
import ViewEmployee from "../Employee/ViewEmployee"
import EmployeesHome from "./Pages/EmployeesHome"

export default function Employees() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<EmployeesHome />} />
        <Route path="/employee/:id" element={<CreateEditEmployee />} />
        <Route path="/view_employee/:id" element={<ViewEmployee />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}
