import React from "react"

export default function DataSetRow({ label, content }) {
  return (
    <div className="d-flex col-5 mb-4">
      <div className="col col-md-3 ms-2">{label}</div>
      <div className="col col-md-9 ms-2">{content}</div>
    </div>
  )
}
