import React, { useEffect, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import SubmitButton from "../../../Components/SubmitButton"
import axios from "axios"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

export default function ForgotPasswordForm() {
  const navigate = useNavigate()
  const emailRegex = new RegExp(".+@.+.[A-Za-z]+$")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [requestErr, setRequestErr] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (email === "") {
      setEmailError("Please provide an email address.")
    } else {
      setSubmitting(true)

      try {
        await axios.post(
          `https://${process.env.REACT_APP_IP}/api/users/reset/${email}`,
          email
        )

        toast.success("Reset link sent. Please check your inbox")
        navigate("../login")
      } catch (err) {
        console.log(err)
        if (err.response.data === "User not found.") {
          setRequestErr("No existing user found with the email entered")
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    // email errors
    if (email !== undefined) {
      if (email.length < 3) {
        setEmailError("Email is too short")
      } else if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email")
      } else {
        setEmailError("")
      }
    }
  }, [email])

  return (
    <Form onSubmit={handleSubmit}>
      <div className="loginErr mb-3 text-center">
        {requestErr && (
          <span className="loginErr mb-3 text-center text-white">
            {requestErr}
          </span>
        )}
      </div>

      <Form.Group>
        <FloatingLabel label="Email Address" className="mb-3">
          <Form.Control
            value={email}
            required
            type="email"
            placeholder="name@example.com"
            isInvalid={emailError}
            isValid={emailError === ""}
            onChange={(e) => {
              setEmail(e.target.value)
              setRequestErr("")
            }}
          />
          <Form.Control.Feedback className="text-white" type="invalid">
            {emailError}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="d-flex justify-content-end align-items-center mt-3">
        <button
          className="btn btn-danger me-2"
          type="button"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <SubmitButton submitting={submitting} />
      </Form.Group>
    </Form>
  )
}
