import axios from "axios"
import React, { useRef } from "react"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import DeviceLogs from "./Components/DeviceLogs"
import SubmitButton from "../../Components/SubmitButton"
import { toast } from "react-toastify"
import {
  faCaretDown,
  faCaretUp,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function CreateEditDataSet() {
  const { user } = useAuthContext()
  const [dataSet, setDataSet] = useState({
    accountId: user.accountId,
    instances: [],
    deletedInstances: [],
  })
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const [selectType, setSelectType] = useState(false)
  const [selectingType, setSelectingType] = useState(false)

  useEffect(() => {
    const getDataSet = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/clientdatasets/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setDataSet({ ...res.data, deletedInstances: [] })
      } catch (error) {}
    }
    if (params.id !== "0") {
      getDataSet()
    }
    setLoading(false)
  }, [params.id])

  useEffect(() => {
    const getDevices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/listbyaccount/${user.accountId}?pageSize=1000`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log(res)
        setDevices(res.data.devices)
      } catch (error) {}
    }

    getDevices()

    setLoading(false)
  }, [user])

  const setField = (field, value) => {
    setDataSet((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (params.id !== "0") {
        const postData = {
          accountId: user.accountId,
          name: dataSet.name,
          clientName: dataSet.clientName,
          dataCategory: dataSet.dataCategory,
          dateRecieved: dataSet.dateRecieved,
          description: dataSet.description,
          id: dataSet.id,
          deletedInstances: dataSet.deletedInstances,
          instances: dataSet.instances.map((instance) => {
            if (isNaN(instance.id)) {
              instance.id = null
            }
            return instance
          }),
        }
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/clientdatasets/${dataSet.id}`,
            postData,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Data set updated.")
          navigate("/data_sets")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/clientdatasets/`,
            dataSet,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Data set created.")
          navigate("/data_sets")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="p-4">
      {params.id === "0" ? (
        <h2 className="mb-5">Create Data Set</h2>
      ) : (
        <h2 className="mb-5">Edit Data Set</h2>
      )}
      <Form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <Form.Group className="d-flex flex-column flex-md-row mb-4">
          <Form.Label className="col col-md-2">Name</Form.Label>
          <div className="col-md-3">
            <Form.Control
              value={dataSet.name ?? ""}
              placeholder="Name"
              required
              onChange={(e) => setField("name", e.target.value)}
            />
          </div>
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-md-row mb-4">
          <Form.Label className="col col-md-2">Client</Form.Label>
          <div className="col-md-3">
            <Form.Control
              value={dataSet.clientName ?? ""}
              placeholder="Client name"
              required
              onChange={(e) => setField("clientName", e.target.value)}
            />
          </div>
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-md-row mb-4">
          <Form.Label className="col col-md-2">Data Category</Form.Label>
          <div className="col col-5" style={{ position: "relative" }}>
            <div
              tabIndex="0"
              onBlur={() => {
                if (!selectingType) {
                  setSelectType(false)
                }
              }}
              onClick={() => {
                setSelectType(!selectType)
              }}
              className="d-flex justify-content-between align-items-center inputBorder"
              // value={dataSet.dataCategory ?? ""}
            >
              {dataSet.dataCategory ? (
                <div>Category {dataSet.dataCategory}</div>
              ) : (
                <div>Select Type</div>
              )}

              {selectType ? (
                <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
              ) : (
                <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
              )}
            </div>
            {selectType && (
              <div
                className="dropdown p-1 overflow-auto shadow"
                style={{ maxHeight: "300px" }}
                onMouseEnter={() => setSelectingType(true)}
                onMouseLeave={() => setSelectingType(false)}
              >
                <div
                  onClick={() => {
                    setField("dataCategory", "A")
                    setSelectType(!selectType)
                  }}
                  className="d-flex flex-column option hoverMe p-1 mb-2"
                >
                  <h4 style={{ fontSize: "bold" }}>
                    Category A - Government / Classified
                  </h4>
                  <div>
                    Any data which may be from the government or may be
                    classified, or data to which access should be restricted
                  </div>
                </div>

                <div
                  onClick={() => {
                    setField("dataCategory", "B")
                    setSelectType(!selectType)
                  }}
                  className="d-flex flex-column option hoverMe p-1 mb-2"
                >
                  <h4 style={{ fontSize: "bold" }}>
                    Category B - Sensitive Personal / Corporate
                  </h4>
                  <div>
                    Data containing sensitive personal information such as
                    passwords, health details, criminal records, credit details
                    etc. Corporate data containing trade secrets, corporate
                    accounts, wage infiormation or credit card details
                  </div>
                </div>

                <div
                  onClick={() => {
                    setField("dataCategory", "C")
                    setSelectType(!selectType)
                  }}
                  className="d-flex flex-column option hoverMe p-1 mb-2"
                >
                  <h4 style={{ fontSize: "bold" }}>
                    Category C - Personal / Corporate
                  </h4>
                  <div>
                    Data containing peoples personal details, such as name,
                    email and phone numbers. Corporate data that may be of use
                    to rivals and is not in the public domain
                  </div>
                </div>

                <div
                  onClick={() => {
                    setField("dataCategory", "D")
                    setSelectType(!selectType)
                  }}
                  className="d-flex flex-column option hoverMe p-1"
                >
                  <h4 style={{ fontSize: "bold" }}>
                    Category D - Public Information
                  </h4>
                  <div>Non-confidential data</div>
                </div>
              </div>
            )}
          </div>
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-md-row mb-4">
          <Form.Label className="col col-md-2">Data Description</Form.Label>
          <div className="col col-md-10">
            <Form.Control
              as="textarea"
              rows={5}
              value={dataSet.description ?? ""}
              placeholder="Data Description"
              onChange={(e) => setField("description", e.target.value)}
            />
          </div>
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-md-row mb-4">
          <Form.Label className="col col-md-2">Date Recieved</Form.Label>
          <div>
            <Form.Control
              defaultValue={
                dataSet.dateRecieved ? dataSet.dateRecieved.split("T")[0] : ""
              }
              type={`date`}
              onChange={(e) => setField("dateRecieved", e.target.value)}
            />
          </div>
        </Form.Group>

        <div>
          <p style={{ fontSize: 26 }}>DEVICE LOGS</p>
          <Form.Group className="d-flex flex-column flex-md-row">
            <DeviceLogs
              setField={setField}
              instances={dataSet.instances}
              deletedInstances={dataSet.deletedInstances}
              devices={devices}
            />
          </Form.Group>
        </div>

        <div>
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <SubmitButton submitting={submitting} />
        </div>
      </Form>
    </div>
  )
}
