import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditIncidentReport from "../IncidentReport/CreateEditIncidentReport"
import ViewIncidentReport from "../IncidentReport/ViewIncidentReport"
import IncidentReportsHome from "./Pages/IncidentReportsHome"

export default function IncidentReports() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IncidentReportsHome />} />
        <Route
          path="/incident_report/:id"
          element={<CreateEditIncidentReport />}
        />
        <Route
          path="/view_incident_report/:id"
          element={<ViewIncidentReport />}
        />
      </Routes>
      <ToastContainer />
    </div>
  )
}
