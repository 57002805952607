import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { locale } from "../../../../Config/Constants"

export default function SoftwareList({ softwareList }) {
  const [showUninstalled, setShowUninstalled] = useState(false)
  const checkDate = new Date()
  checkDate.setDate(checkDate.getDate() - 14)

  const versionCompare = (installed, latest) => {
    var installedParts = installed.split(".")
    var latestParts = latest.split(".")

    function isValidPart(x) {
      return /^\d+$/.test(x)
    }

    if (!installedParts.every(isValidPart) || !latestParts.every(isValidPart)) {
      return NaN
    }

    installedParts = installedParts.map(Number)
    latestParts = latestParts.map(Number)

    for (var i = 0; i < installedParts.length; ++i) {
      if (latestParts.length == i) {
        return 1
      }

      if (installedParts[i] == latestParts[i]) {
        continue
      } else if (installedParts[i] > latestParts[i]) {
        return 1
      } else {
        return -1
      }
    }

    if (installedParts.length != latestParts.length) {
      return -1
    }

    return 0
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <p style={{ fontSize: 26 }} className="mt-2 me-4">
          SOFTWARE
        </p>
        <Form className="d-flex">
          <Form.Label>Uninstalled</Form.Label>
          <Form.Check
            className="ms-2"
            type="checkbox"
            id="isUninstalledCheckbox"
            checked={showUninstalled}
            onChange={(e) => {
              setShowUninstalled(e.target.checked)
            }}
          />
        </Form>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless">
          <thead
            style={{ borderBottom: "1px solid var(--secondary)" }}
            className="bold"
          >
            <tr>
              <td>PRODUCT</td>
              {!showUninstalled && (
                <>
                  <td>INSTALLED VERSION</td>
                  <td>LATEST VERSION</td>
                  <td>LAST CHECKED</td>
                </>
              )}
              {showUninstalled && <td>Date Of Uninstall</td>}
            </tr>
          </thead>
          <tbody>
            {!showUninstalled && softwareList.length > 0
              ? softwareList.map((software) => {
                  if (
                    versionCompare(
                      software.installedVersion,
                      software.latestVersion
                    ) === 1 ||
                    versionCompare(
                      software.installedVersion,
                      software.latestVersion
                    ) === -1
                  ) {
                    software.isOutOfDate = true
                  }
                  if (software.isUninstalled === false) {
                    return (
                      <tr
                        style={
                          software.outOfSupport ||
                          (software.dateOfSoftwareProblem &&
                            software.dateOfSoftwareProblem <
                              new Date(checkDate) &&
                            software.isOutOfDate) ||
                          software.isOutOfDate
                            ? {
                                backgroundColor: "red",
                                color: "white",
                                border: "1px solid var(--secondary)",
                              }
                            : software.dateOfSoftwareProblem &&
                              software.dateOfSoftwareProblem >
                                new Date(checkDate) &&
                              software.isOutOfDate
                            ? {
                                backgroundColor: "darkOrange",
                                color: "white",
                                border: "1px solid var(--secondary)",
                              }
                            : {}
                        }
                        key={software.id}
                      >
                        <td>{software.productName}</td>
                        <td>{software.installedVersion}</td>
                        <td>
                          {!software.outOfSupport
                            ? software.isOutOfDate
                              ? "Out of Date"
                              : software.latestVersion
                            : "Unsupported"}
                        </td>
                        <td>
                          {software.dateLastVerified
                            ? new Date(
                                software.dateLastVerified
                              ).toLocaleDateString(locale)
                            : ""}
                        </td>
                      </tr>
                    )
                  }
                })
              : !showUninstalled && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Software Found...
                    </td>
                  </tr>
                )}
            {showUninstalled &&
            softwareList.filter((x) => x.isUninstalled).length > 0
              ? softwareList.map((software) => {
                  if (software.isUninstalled === true) {
                    return (
                      <tr key={software.id}>
                        <td>{software.productName}</td>
                        <td>
                          {software.dateOfUninstall
                            ? new Date(
                                software.dateOfUninstall
                              ).toLocaleDateString(locale)
                            : "N/A"}
                        </td>
                      </tr>
                    )
                  }
                })
              : showUninstalled && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Software Found...
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </>
  )
}
