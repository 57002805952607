import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"

export default function DeviceTable({
  devicesInvolved,
  setDevicesInvolved,
  devices,
}) {
  const handleDelete = (id) => {
    const filteredList = devicesInvolved.filter((item) => item.deviceId !== id)

    setDevicesInvolved(filteredList)
  }

  const handleAdd = (id, field, value) => {
    const newAdd = {
      id: id,
      [field]: value,
    }
    setDevicesInvolved([...devicesInvolved, newAdd])
  }

  console.log("devicesInvolved", devicesInvolved)

  return (
    <div className="d-flex flex-column align-items-center justify-content-center col">
      <table className="table table-borderless ">
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <th>NAME</th>
            <th>OFFICE</th>
            <th>ROOM</th>
            <th>INVOLVED?</th>
          </tr>
        </thead>
        <tbody>
          {devices &&
            devices.length > 0 &&
            devices.map((device) => (
              <tr key={device.id}>
                <td>{device.name}</td>
                <td>{device.office}</td>
                <td>{device.room}</td>
                <td>
                  <Form.Check
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleAdd(device.id, "deviceId", device.id)
                      } else {
                        handleDelete(device.id)
                      }
                    }}
                    defaultChecked={
                      devicesInvolved.filter((x) => x.deviceId === device.id)
                        .length > 0
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
