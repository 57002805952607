import axios from "axios"
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import ViewRow from "../../Components/Row/ViewRow"
import MainHeading from "../../Components/Headings/MainHeading"
import { locale } from "../../Config/Constants"
import { toast } from "react-toastify"
import DataSetRow from "./Components/View/DataSetRow"

export default function ViewDataSet() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [dataSet, setDataSet] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getDevices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/clientdatasets/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setDataSet(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getDevices()
  }, [params.id])

  const handleDelete = async (dataSetId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/clientdatasets/${dataSetId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Data Set deleted.")
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    }
  }

  console.log(dataSet)

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex flex-column col-12">
          <div className="d-flex mb-5">
            <h1>{dataSet.name}</h1>
            <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate(`../data_set/${dataSet.id}`)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(dataSet.id)}
                disabled={dataSet.instances.length > 0}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="d-flex flex-column mb-5">
            <DataSetRow label={"Client"} content={dataSet.clientName} />
            <DataSetRow label={"Category"} content={dataSet.dataCategory} />
            <DataSetRow label={"Description"} content={dataSet.description} />
            <DataSetRow
              label={"Date Recieved"}
              content={new Date(dataSet.dateRecieved).toLocaleDateString(
                locale
              )}
            />
          </div>

          <div>
            <p style={{ fontSize: 26 }}>DEVICE LOGS</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless ">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>DEVICE</td>
                    <td>DATE CREATED</td>
                    <td>DATE REMOVED</td>
                    <td>REMOVAL METHOD</td>
                  </tr>
                </thead>
                <tbody>
                  {dataSet.instances.map((instance) => (
                    <tr key={instance.id} className="tableRow">
                      <td
                        className="hoverMe text-decoration-underline"
                        onClick={() =>
                          navigate(`/devices/view_device/${instance.deviceId}`)
                        }
                      >
                        {instance.deviceName}
                      </td>
                      <td>
                        {new Date(instance.dateCreated).toLocaleDateString(
                          locale
                        )}
                      </td>
                      <td>
                        {instance.dateRemoved
                          ? new Date(instance.dateRemoved).toLocaleDateString(
                              locale
                            )
                          : "In Use"}
                      </td>
                      <td>
                        {instance.removalMethod
                          ? instance.removalMethod
                          : "In Use"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" mt-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
