import React from "react";
import "./MainContainer.css";

export default function MainContainer({ children, classes, style }) {
  return (
    <div style={{ ...style }} className={`mainCon ${classes} px-md-3 pt-2 `}>
      {children}
    </div>
  );
}
