import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function SubmitButton({ submitting }) {
  return (
    <button disabled={submitting} type="submit" className="btn btn-primary">
      {!submitting ? (
        "Submit"
      ) : (
        <div>
          Please wait <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
        </div>
      )}
    </button>
  )
}
