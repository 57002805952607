import axios from "axios"
import React, { useEffect, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import SubmitButton from "../../../Components/SubmitButton"
import { validateForm } from "../../../Helpers/FormValidation"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function EditUsers() {
  const { user } = useAuthContext()
  const [addUser, setAddUser] = useState()
  const [users, setUsers] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [form, setForm] = useState({})
  const [formErrors, setFormErrors] = useState({})

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const postUser = async (data) => {
    setSubmitting(true)
    if (addUser === "Add") {
      try {
        await axios.post(
          `https://${process.env.REACT_APP_IP}/api/users/`,
          {
            ...data,
            accountId: user.accountId,
          },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setForm({})
        toast.success("User created.")
        setAddUser()
      } catch (err) {
        if (
          err.response.data ===
          "A user already exists with this e-mail address."
        ) {
          setFormErrors((prev) => {
            return { ...prev, email: "Email already exists" }
          })
        } else {
          toast.error("Oops something went wrong. Please try again.")
        }
      } finally {
        setSubmitting(false)
      }
    } else if (addUser === "Edit") {
      try {
        await axios.post(
          `https://${process.env.REACT_APP_IP}/api/users/${form.userId}`,
          {
            id: form.userId,
            ...data,
          },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setForm({})
        toast.success("User updated.")
        setAddUser()
      } catch (err) {
        if (
          err.response.data ===
          "A user already exists with this e-mail address."
        ) {
          setFormErrors((prev) => {
            return { ...prev, email: "Email already exists" }
          })
        } else {
          toast.error("Oops something went wrong. Please try again.")
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      const newErrors = validateForm(form)
      setFormErrors(newErrors)
    }

    if (addUser) {
      findFormErrors()
    }
  }, [form])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users/listbyaccount/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log("res.data", res.data)
        setUsers(res.data)
      } catch (err) {
        toast.error("Oops something went wrong. Please try again.")
      }
    }

    if (!addUser) {
      getUsers()
    }
  }, [addUser])

  const deleteUser = async (userId) => {
    setSubmitting(true)
    try {
      await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/users/${userId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setUsers((prev) => {
        return prev.filter((user) => user.userId !== userId)
      })
      toast.success("User deleted.")
    } catch (err) {
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      setSubmitting(false)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { email, password, password2 } = formErrors

    if (!email && !password && !password2) {
      const user = {
        firstName: form.firstName,
        secondName: form.secondName,
        email: form.email,
        password: form.password1,
        isAdmin: form.isAdmin,
      }

      postUser(user)
    }
  }

  return (
    <div className="p-4">
      {addUser === "Add" && (
        <div className="d-flex justify-content-between mb-2">
          <h2>Add User</h2>
          <button onClick={() => setAddUser()} className="btn btn-danger">
            Cancel
          </button>
        </div>
      )}
      {addUser === "Edit" && (
        <div className="d-flex justify-content-between mb-2">
          <h2>Edit User</h2>
          <button onClick={() => setAddUser()} className="btn btn-danger">
            Cancel
          </button>
        </div>
      )}
      {!addUser && (
        <div className="d-flex justify-content-between mb-2">
          <h3>Users</h3>
          <button
            onClick={() => {
              setAddUser("Add")
              setForm({})
            }}
            className="btn btn-primary"
          >
            Add User
          </button>
        </div>
      )}
      {addUser && (
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom01">
              <FloatingLabel label="First Name" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter First Name"
                  isInvalid={formErrors.firstName}
                  isValid={formErrors.firstName === ""}
                  onChange={(e) => setField("firstName", e.target.value)}
                  value={form.firstName ?? ""}
                />
                <Form.Control.Feedback className="" type="invalid">
                  {formErrors.firstName}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="validationCustom01">
              <FloatingLabel label="Second Name" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Second Name"
                  isInvalid={formErrors.secondName}
                  isValid={formErrors.secondName === ""}
                  onChange={(e) => setField("secondName", e.target.value)}
                  value={form.secondName ?? ""}
                />
                <Form.Control.Feedback className="" type="invalid">
                  {formErrors.secondName}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Email Address" className="mb-3">
                <Form.Control
                  required
                  type="email"
                  placeholder="name@example.com"
                  isInvalid={formErrors.email}
                  isValid={formErrors.email === ""}
                  onChange={(e) => setField("email", e.target.value)}
                  value={form.email ?? ""}
                />
                <Form.Control.Feedback className="" type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Default Password" className="mb-3">
                <Form.Control
                  required={addUser === "Add"}
                  type="password"
                  placeholder="Default Password"
                  isInvalid={formErrors.password1}
                  isValid={formErrors.password1 === ""}
                  onChange={(e) => setField("password1", e.target.value)}
                  value={form.password1 ?? ""}
                />
                <Form.Control.Feedback className="" type="invalid">
                  {formErrors.password1}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="Re-Type Default Password" className="mb-3">
                <Form.Control
                  required={addUser === "Add"}
                  type="password"
                  placeholder="Re-Type Default Password"
                  isInvalid={formErrors.password2}
                  isValid={formErrors.password2 === ""}
                  onChange={(e) => setField("password2", e.target.value)}
                  value={form.password2 ?? ""}
                />
                <Form.Control.Feedback className="" type="invalid">
                  {formErrors.password2}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between align-items-center mt-3 ms-1">
              <Form.Check
                type="checkbox"
                label="Is Admin?"
                checked={form.isAdmin ?? false}
                onChange={(e) => setField("isAdmin", e.target.checked)}
              />
              <SubmitButton submitting={submitting} />
            </Form.Group>
          </Form>
        </div>
      )}
      {!addUser &&
        users &&
        users.length > 0 &&
        users.map((user) => (
          <div key={user.userId} className="list-item col-12 mt-2">
            <div className="d-flex justify-content-between align-items-center mx-3">
              <div
                className="d-flex col-11 hoverMe p-3"
                onClick={() => {
                  setForm({ ...user })
                  setAddUser("Edit")
                }}
              >
                <div>{user.name}</div>
              </div>
              <div className="hoverMe p-3">
                {submitting ? (
                  <div style={{ borderRadius: 6 }} className="delete me-2 p-2">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      color="white"
                      size="1x"
                      spin
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="white"
                    style={{ borderRadius: 6 }}
                    className="delete me-2 p-2"
                    onClick={() => {
                      deleteUser(user.userId)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
