import React from "react"
import LeftCon from "../Components/LeftCon"
import LandingHeading from "../Components/LandingHeading"
import ResendConfirmationForm from "../Components/ResendConfirmationForm"
import RightCon from "../Components/RightCon"

export default function ResendConfirmation() {
  return (
    <div style={{ height: "100vh" }} className=" d-flex homePage">
      {/* <LeftCon> */}
      <div className=" d-flex flex-column justify-content-center col-xs-12 col-lg-7">
        <div
          style={{ height: "60vh" }}
          className="d-flex flex-column justify-content-center align-items-center loginForm login shadow p-5 col-7 "
        >
          <div className=" col-10">
            <div className="d-flex justify-content-center align-items-center mb-5 ">
              <img
                height={100}
                width="auto"
                src={require("../../../Components/Imgs/Logo.svg").default}
              />
            </div>
            <h3 className="d-flex mb-5 justify-content-center text-primary">
              Re-send Confirmation
            </h3>

            <p>
              Please enter your email address below and we will re-send the
              confirmation email.
            </p>

            <ResendConfirmationForm />
          </div>
        </div>
      </div>
      {/* </LeftCon> */}

      {/* <RightCon /> */}
    </div>
  )
}
