import axios from "axios"
import { useAuthContext } from "../../../../Hooks/useAuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/free-solid-svg-icons"

export default function CloseButton() {
  const { user, dispatch } = useAuthContext()

  const handleClose = async () => {
    try {
      await axios.get(
        `https://${process.env.REACT_APP_IP}/api/users/${user.id}/completetutorial`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )

      console.log("clicked")
      dispatch({
        type: "WELCOMEBACK",
        payload: { ...user, hasSeenTutorial: true },
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <button className="btn btn-danger my-2" onClick={handleClose}>
      <FontAwesomeIcon icon={faX} />
    </button>
  )
}
