import { useState } from "react"
import LoginForm from "../Components/LoginForm"
import LeftCon from "../Components/LeftCon"
import MfaForm from "../Components/MfaForm"
import EnableMfaForm from "../Components/EnableMfaForm"
import RightCon from "../Components/RightCon"
import LandingHeading from "../Components/LandingHeading"

export default function Login() {
  const [user, setUser] = useState({})

  return (
    <div style={{ height: "100vh" }} className=" d-flex homePage">
      {/* <LeftCon> */}
      <div className=" d-flex flex-column justify-content-center col-xs-12 col-lg-7">
        <div
          style={{ height: "70vh" }}
          className="d-flex flex-column justify-content-center align-items-center loginForm login shadow p-5 col-7 "
        >
          <div className=" col-10">
            <div className="d-flex justify-content-center align-items-center mb-3 ">
              <img
                height={100}
                width="auto"
                src={require("../../../Components/Imgs/Logo.svg").default}
              />
            </div>

            <h3 className="d-flex mb-4 justify-content-center text-primary">
              Log In
            </h3>
            {!Object.keys(user).length > 0 && <LoginForm setUser={setUser} />}

            {Object.keys(user).length > 0 && !user.isMFAEnabled && (
              <EnableMfaForm emailAdr={user.email} />
            )}
            {Object.keys(user).length > 0 && user.isMFAEnabled && (
              <p>
                Please open your authenticator app and enter the code provided.
              </p>
            )}
            {Object.keys(user).length > 0 && user.isMFAEnabled && <div></div>}
            {Object.keys(user).length > 0 && <MfaForm emailAdr={user.email} />}
          </div>
        </div>
      </div>
      {/* </LeftCon> */}

      {/* <RightCon /> */}
    </div>
  )
}
