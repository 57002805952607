import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact10() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact10 text-white">
            Among the businesses that faced cybersecurity incidents in the past
            12 months <span className="fact10Span">11%</span> of the most
            serious types of incidents involved careless employees
          </h2>
          <p className="fact-reference text-white"> - kaspersky.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          It is key to ensure employees are well trained, in sync, and
          consistently exercising effective security practices. One mistake from
          an improperly trained employee can cause an entire security system to
          crumble.
        </h3>
      </div>
    </div>
  )
}
