import React from "react"

export default function EmployeeRow({ label, content, style }) {
  return (
    <div style={{ ...style }} className="d-flex col-5 mb-4">
      <div className="col col-md-3 ms-2">{label}</div>
      <div className="col col-md-9 ms-2">{content}</div>
    </div>
  )
}
