import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"

export default function UsernameTable({ setField, usernames }) {
  const handleDelete = (id) => {
    const filteredList = usernames.filter((item) => item.id !== id)

    setField("usernames", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newLog = {
      id: randomId,
    }

    setField("usernames", [...usernames, newLog])
  }

  const handleEdit = (id, field, value) => {
    const existing = usernames.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...usernames, newEdit]
    } else {
      let updatedList = usernames.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("usernames", updatedList)
    }
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center col">
      <table className="table table-borderless ">
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>NAME</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {usernames &&
            usernames.length > 0 &&
            usernames.map((username) => {
              return (
                <tr key={username.id}>
                  <td>
                    <Form.Control
                      required
                      type="text"
                      onChange={(e) =>
                        handleEdit(username.id, "username", e.target.value)
                      }
                      value={username.username ?? ""}
                    />
                  </td>
                  <td className="d-flex justify-content-center ">
                    <div
                      className="btn btn-danger"
                      onClick={() => handleDelete(username.id)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <div className="d-flex col-12 justify-content-end">
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
