import React from "react"
import ResetPasswordForm from "../Components/ResetPasswordForm"
import LandingHeading from "../Components/LandingHeading"
import LeftCon from "../Components/LeftCon"
import RightCon from "../Components/RightCon"

export default function ResetPassword() {
  return (
    <div className="min-vh-100 d-flex">
      <LeftCon>
        <LandingHeading text={"Forgot Password?"} />

        <hr />
        <p>Please enter your new password below.</p>
        <ResetPasswordForm />
      </LeftCon>
      <RightCon />
    </div>
  )
}
