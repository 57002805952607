import React from "react"
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, Title)

export default function EmployeePie({ theme, trained, unTrained }) {
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Employees",
        color: theme === "dark" ? "white" : "black",
        font: {
          size: "26px",
          weight: "300",
        },
      },
      legend: {
        labels: {
          color: theme === "dark" ? "white" : "black",
        },
      },
    },
    responsive: true,
  }
  const data = {
    labels: ["Trained", "Un-Trained"],
    datasets: [
      {
        label: "# of Votes",
        data: [trained, unTrained],
        backgroundColor: ["rgba(15, 148, 59, 0.6)", "rgba(163, 54, 61, 0.6)"],
        borderColor: ["rgba(15, 148, 59, 0.6)", "rgba(163, 54, 61, 0.6)"],
        borderWidth: 1,
      },
    ],
  }
  return (
    <div className="  ">
      <Pie options={options} data={data} />
    </div>
  )
}
