import React from "react"

export default function HddList({ hdds }) {
  return (
    <>
      <p style={{ fontSize: 26 }}>HDDs</p>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless">
          <thead
            style={{ borderBottom: "1px solid var(--secondary)" }}
            className="bold"
          >
            <tr>
              <td>TYPE</td>
              <td>SERIAL</td>
              <td>CAPACITY</td>
              <td>STATUS</td>
            </tr>
          </thead>
          <tbody>
            {hdds && hdds.length > 0 ? (
              hdds.map((hdd) => (
                <tr key={hdd.id}>
                  <td>{hdd.type}</td>
                  <td>{hdd.serial}</td>
                  <td>{hdd.size}gb</td>
                  <td>{hdd.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No HDDs Found...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
