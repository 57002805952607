import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircle,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useLocalStorage from "use-local-storage"
import TablePageSelect from "../../../Components/Table/TablePageSelect"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { locale } from "../../../Config/Constants"
import Spinner from "../../../Components/Loading/Spinner"

export default function TrainingCoursesHome() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()

  const [trainingCourseList, setTrainingCourseList] = useState([])
  const [loading, setLoading] = useState(true)

  //Paginations
  const [pagination, setPagination] = useLocalStorage("trainingCoursesSearch", {
    pageNumber: 1,
    sort: "title-d",
    search: "",
    pageSize: 10,
  })

  useEffect(() => {
    if (location.state) {
      setPagination((prev) => {
        return { ...prev, status: location.state }
      })
    }
  }, [location.state])

  const [totalCount, setTotalCount] = useState(0)

  const listTrainingCourses = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/trainingcourses/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&sort=${pagination.sort}&search=${pagination.search}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setTrainingCourseList(res.data.courses)
      setTotalCount(res.data.count)
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listTrainingCourses()
  }, [user, pagination])

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="pb-4">CyberEssentials Training Courses</h1>
        </div>
        <div>
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate("./training_course/0")}
          >
            Add Training Course
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <div className="col-4 me-2">
          <Form.Control
            placeholder="Search training courses..."
            value={pagination.search}
            onChange={(e) =>
              setPagination((prev) => {
                return { ...prev, search: e.target.value }
              })
            }
          />
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless ">
          <thead
            className="bold"
            style={{ borderBottom: "1px solid var(--secondary)" }}
          >
            <tr>
              <td
                className="hoverMe col-3"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort:
                        pagination.sort === "title-a" ? "title-d" : "title-a",
                    }
                  })
                }
              >
                TITLE
                {pagination.sort === "title-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "title-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td
                className="hoverMe col-3"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort:
                        pagination.sort === "location-a"
                          ? "location-d"
                          : "location-a",
                    }
                  })
                }
              >
                LOCATION
                {pagination.sort === "location-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "location-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td className="col-3">NO. OF EMPLOYEES</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-3">
                  <Spinner />
                </td>
              </tr>
            ) : trainingCourseList.length > 0 ? (
              trainingCourseList.map((trainingCourse) => (
                <tr key={trainingCourse.id} className="tableRow">
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_training_course/${trainingCourse.id}`)
                    }
                  >
                    {trainingCourse.title}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_training_course/${trainingCourse.id}`)
                    }
                  >
                    {trainingCourse.location}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_training_course/${trainingCourse.id}`)
                    }
                  >
                    {trainingCourse.employeeCount}
                  </td>
                  <td className="d-flex justify-content-center">
                    <button
                      style={{ fontSize: 14, width: 100, borderRadius: 6 }}
                      className="btn btn-primary"
                      onClick={() =>
                        navigate(`./training_course/${trainingCourse.id}`)
                      }
                    >
                      EDIT
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-3">
                  No results...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TablePageSelect
        pageNumber={pagination.pageNumber}
        setPagination={setPagination}
        totalCount={totalCount}
        pagination={pagination}
      />
    </div>
  )
}
