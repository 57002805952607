import axios from "axios"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../../Hooks/useAuthContext"
import { Form } from "react-bootstrap"
import { toast } from "react-toastify"

export default function CloneDevice({ id }) {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const [noOfClones, setNoOfClones] = useState("")

  const cloneDevice = async (e) => {
    e.preventDefault()

    const data = {
      accountId: user.accountId,
      masterDevice: id,
      noOfClones: noOfClones,
    }
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/devices/clone`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate(-1)
    } catch (error) {
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      //   setLoading(false)
    }
  }

  return (
    <div className="d-flex flex-column-reverse flex-md-row justify-content-between col-12 gap-3 gap-md-0">
      <div className="">
        <button
          type="button"
          className="btn btn-danger me-2"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <Form
        onSubmit={cloneDevice}
        className="d-flex col justify-content-md-end"
      >
        <div className="d-flex justify-content-md-end">
          <div className="d-flex align-items-center me-2">Create</div>
          <div className="col-3 col-md-3 me-2 ">
            <Form.Control
              type="number"
              required
              placeholder="eg: 5"
              onChange={(e) => setNoOfClones(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center me-2">clones</div>
        </div>

        {noOfClones !== "" && (
          <div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        )}
      </Form>
    </div>
  )
}
