import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { locale } from "../../../../Config/Constants"
import { Form } from "react-bootstrap"

export default function DataSetList({ instances }) {
  const navigate = useNavigate()

  const [showRemoved, setShowRemoved] = useState(false)

  console.log("instances", instances)

  return (
    <>
      <div className="d-flex align-items-center">
        <p style={{ fontSize: 26 }} className="mt-2 me-4">
          DATA SET INSTANCES
        </p>
        <Form className="d-flex">
          <Form.Label>Removed</Form.Label>
          <Form.Check
            className="ms-2"
            type="checkbox"
            id="isRemovedCheckbox"
            checked={showRemoved}
            onChange={(e) => {
              setShowRemoved(e.target.checked)
            }}
          />
        </Form>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless">
          <thead
            style={{ borderBottom: "1px solid var(--secondary)" }}
            className="bold"
          >
            <tr>
              <td>NAME</td>
              <td>CATEGORY</td>
              <td>CREATED</td>
              {showRemoved && (
                <>
                  <td>REMOVED</td>
                  <td>REMOVAL METHOD</td>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {!showRemoved && instances && instances.length > 0
              ? instances.map((instance) => {
                  if (!instance.dateRemoved) {
                    return (
                      <tr key={instance.id}>
                        <td
                          className="hoverMe text-decoration-underline"
                          onClick={() =>
                            navigate(
                              `/data_sets/view_data_set/${instance.dataSet.id}`
                            )
                          }
                        >
                          {instance.dataSet.clientName}
                        </td>
                        <td>{instance.dataSet.dataCategory}</td>
                        <td>
                          {instance.dateCreated
                            ? new Date(instance.dateCreated).toLocaleDateString(
                                locale
                              )
                            : ""}
                        </td>
                      </tr>
                    )
                  }
                })
              : !showRemoved && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Instances Found...
                    </td>
                  </tr>
                )}

            {showRemoved && instances && instances.length > 0
              ? instances.map((instance) => {
                  if (instance.dateRemoved) {
                    return (
                      <tr key={instance.id}>
                        <td
                          className="hoverMe text-decoration-underline"
                          onClick={() =>
                            navigate(
                              `/data_sets/view_data_set/${instance.dataSet.id}`
                            )
                          }
                        >
                          {instance.dataSet.clientName}
                        </td>
                        <td>{instance.dataSet.dataCategory}</td>
                        <td>
                          {instance.dateCreated
                            ? new Date(instance.dateCreated).toLocaleDateString(
                                locale
                              )
                            : ""}
                        </td>
                        <td>
                          {instance.dateRemoved
                            ? new Date(instance.dateRemoved).toLocaleDateString(
                                locale
                              )
                            : "In Use"}
                        </td>
                        <td>
                          {instance.removalMethod === "SecureErasure"
                            ? "Secure Erasure"
                            : instance.removalMethod}
                        </td>
                      </tr>
                    )
                  }
                })
              : showRemoved && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Instances Found...
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </>
  )
}
