import { Carousel } from "react-bootstrap"
import CarouselCard from "../../../Landing/Components/CarouselCard"
import CarouselHeader from "../../../Landing/Components/CarouselHeader"

import "./WelcomeCarousel.css"
import WelcomeCarouselHeader from "./WelcomeCarouselHeader"

export default function WelcomeCarousel() {
  return (
    <Carousel interval={null} className="welcomeCarousel">
      <Carousel.Item>
        <CarouselCard classes={"bg-white welcomeCarouselItem"}>
          <WelcomeCarouselHeader text={"First off. Welcome!"} />

          <p className="text-center">
            The goal of this tutorial is to give you a quick to do list to get
            you started with cyber essentials. This setup only has to be done
            once and will allow us to ensure your information is up to date and
            that your network is secure.
            <br />
            <br />
            If you are ever stuck or need the information provided here again
            you can simply click the help button at the top of the screen.
          </p>
        </CarouselCard>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard classes={"bg-white welcomeCarouselItem"}>
          <WelcomeCarouselHeader text={"Set up your Devices."} />
          <p className="text-center">
            The first thing we recommend doing is getting your devices setup.
            Here's what to do.
            <br />
            <br />
            <ul className="text-start">
              <li>Download the device scanning software.</li>
              <li>Install the software on all devices.</li>
              <li>Chose 1 main device and set that up to scan the network.</li>
              <li>
                Finally head to the devices section and manually add any devices
                that are missing.
              </li>
            </ul>
          </p>
        </CarouselCard>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard classes={"bg-white welcomeCarouselItem"}>
          <WelcomeCarouselHeader text={"Add some Employees."} />
          <p className="text-center">
            Next head to the employees section and add in details for all your
            employees.
            <br />
            <br />
            You will notice there is an option to add a training date. If your
            employees have not recieved any cyber training yet we recommend
            doing this as soon as possible. Head to our help section for our
            recommend training course.
            <br />
            <br />
            Top tip: Having your staff trained in basic cyber security will
            reduce your risk of attack by 70%!
          </p>
        </CarouselCard>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard classes={"bg-white welcomeCarouselItem"}>
          <WelcomeCarouselHeader text={"Add your Cloud Services"} />
          <p className="text-center">
            To best protect you we need to know what cloud services are being
            used and who has access to them.
            <br />
            <br />
            Simply head to the cloud services section and add your cloud
            services. When creating a new cloud service, if every employee has
            access simply click the add all employees button and we will do the
            rest.
            <br />
            <br />
            Top tip: If its easier you can add all employees to a service and
            simply delete the ones who dont use it.
          </p>
        </CarouselCard>
      </Carousel.Item>
      <Carousel.Item>
        <CarouselCard classes={"bg-white welcomeCarouselItem"}>
          <WelcomeCarouselHeader text={"Manage Your Data Sets"} />
          <p className="text-center">
            Managing data is extremly important. Data breaches not only lose
            customer trust but come with heavy penalties.
            <br />
            <br />
            To monitor data entrusted to you head to the data sets section.
            <br />
            Next add any data that you manage, give it a name, categorization
            and add any devices that is present on.
            <br />
            <br />
            This not only allows you to view who has accessed any data but gives
            you a complete history of ho this data was disposed of.
          </p>
        </CarouselCard>
      </Carousel.Item>
    </Carousel>
  )
}
