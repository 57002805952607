import axios from "axios"
import React from "react"
import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import EmployeeTable from "./Components/EmployeeTable"
import SubmitButton from "../../Components/SubmitButton"
import { toast } from "react-toastify"

export default function CreateEditTrainingCourse() {
  const { user } = useAuthContext()
  const [trainingCourse, setTrainingCourse] = useState({
    accountId: user.accountId,
    title: "",
    description: "",
    location: "",
    employees: [],
  })
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const getTrainingCourse = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/trainingcourses/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setTrainingCourse({ ...res.data })
      } catch (error) {
        console.log("error", error)
      }
    }
    if (params.id !== "0") {
      getTrainingCourse()
    }
    setLoading(false)
  }, [params.id])

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/employees/listbyaccount/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        console.log("res.data", res.data)
        setEmployees(res.data.employees)
      } catch (error) {}
    }

    getEmployees()

    setLoading(false)
  }, [user])

  const setField = (field, value) => {
    setTrainingCourse((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      if (params.id !== "0") {
        const postData = {
          accountId: user.accountId,
          title: trainingCourse.title,
          description: trainingCourse.description,
          location: trainingCourse.location,
          employees: trainingCourse.employees.map((e) => {
            if (isNaN(e.id)) {
              e.id = null
            }
            return e
          }),
        }
        console.log("postData", postData)
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/trainingcourses/${trainingCourse.id}`,
            postData,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Training course updated.")
          navigate("/training_courses")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      } else {
        try {
          await axios.post(
            `https://${process.env.REACT_APP_IP}/api/trainingcourses/`,
            trainingCourse,
            {
              headers: { Authorization: `bearer ${user.token}` },
            }
          )
          toast.success("Training course created.")
          navigate("/training_courses")
        } catch (err) {
          console.log(err)
          toast.error("Oops something went wrong. Please try again.")
        }
      }
    } catch (error) {
    } finally {
      setSubmitting(false)
    }
  }

  const addAllEmployees = (e) => {
    e.preventDefault()
    if (employees && employees.length > 0) {
      var addEmployees = []

      employees.map((employee) => {
        const randomId = (Math.random() + 1).toString(36).substring(7)
        addEmployees = [
          ...addEmployees,
          { id: randomId, employeeId: employee.id.toString(), date: null },
        ]
      })

      setField("employees", [...addEmployees])
    }
  }

  console.log("employees", employees)
  console.log("trainingCourse", trainingCourse)
  if (!loading) {
    return (
      <div className="p-4">
        {params.id === "0" ? (
          <h2 className="mb-5">Create Training Course</h2>
        ) : (
          <h2 className="mb-5">Edit Training Course</h2>
        )}
        <Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-2">Title</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={trainingCourse.title ?? ""}
                required
                placeholder="Title"
                onChange={(e) => setField("title", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-2">
            <Form.Label className="col col-md-2">Location</Form.Label>
            <div className="col-md-4">
              <Form.Control
                value={trainingCourse.location ?? ""}
                required
                placeholder="Location"
                onChange={(e) => setField("location", e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex flex-column flex-md-row mb-4">
            <Form.Label className="col col-md-2">Description</Form.Label>
            <div className="col col-md-10">
              <Form.Control
                required
                as="textarea"
                rows={5}
                value={trainingCourse.description ?? ""}
                placeholder="Description"
                onChange={(e) => setField("description", e.target.value)}
              />
            </div>
          </Form.Group>
          <div className="d-flex align-items-center">
            <p className="d-flex mt-3" style={{ fontSize: 26 }}>
              EMPLOYEES
            </p>
            <button
              className="btn btn-primary ms-5"
              onClick={(e) => {
                addAllEmployees(e)
              }}
            >
              Add All Employees
            </button>
          </div>
          <Form.Group className="d-flex flex-column flex-md-row">
            <EmployeeTable
              setField={setField}
              employees={trainingCourse.employees}
              selectEmployees={employees}
            />
          </Form.Group>

          <div>
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <SubmitButton submitting={submitting} />
          </div>
        </Form>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
