import React from "react"
import { useAuthContext } from "../../Hooks/useAuthContext"

import { Nav, Navbar } from "react-bootstrap"
import { NavLink } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faComputer,
  faUsers,
  faCloud,
  faUserTie,
  faTriangleExclamation,
  faDatabase,
  faRightFromBracket,
  faChalkboardTeacher,
  faHome,
} from "@fortawesome/free-solid-svg-icons"
import "./SideNav.css"
import { useNavigate } from "react-router-dom"

export default function SideNav() {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()

  return (
    <Navbar
      className="sideNav d-flex justify-content-center align-items-baseline"
      style={{ height: "100%" }}
    >
      {user && (
        <Nav
          style={{ height: "100%" }}
          className="d-flex flex-column col-12 mt-4"
        >
          <div>
            <NavLink className="d-flex nav-link" to="/">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faHome} />
              </div>
              Home
            </NavLink>
            <NavLink className="d-flex nav-link" to="/devices">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faComputer} />
              </div>
              Devices
            </NavLink>
            <NavLink className="d-flex nav-link" to="/employees">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              Employees
            </NavLink>
            <NavLink className="d-flex nav-link" to="/data_sets">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faDatabase} />
              </div>
              Data Sets
            </NavLink>
            <NavLink className="d-flex nav-link" to="/training_courses">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faChalkboardTeacher} />
              </div>
              Training Courses
            </NavLink>
            <NavLink className="d-flex nav-link" to="/cloud_services">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faCloud} />
              </div>
              Cloud Services
            </NavLink>
            <NavLink className="d-flex nav-link" to="/incident_reports">
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </div>
              Incidents
            </NavLink>
            {user.isAdmin && (
              <NavLink className="d-flex nav-link" to="/account">
                <div className="col-1" />
                <div className="d-flex justify-content-center align-items-center col-3">
                  <FontAwesomeIcon icon={faUserTie} />
                </div>
                Admin
              </NavLink>
            )}
          </div>
          <div style={{ marginTop: "auto" }} className="d-flex mb-5">
            <Nav.Link
              className="d-flex nav-link col-12"
              onClick={() => {
                navigate("../")
                dispatch({ type: "LOGOUT" })
              }}
            >
              <div className="col-1" />
              <div className="d-flex justify-content-center align-items-center col-3">
                <FontAwesomeIcon icon={faRightFromBracket} />
              </div>
              Log Out
            </Nav.Link>
          </div>
        </Nav>
      )}
    </Navbar>
  )
}
