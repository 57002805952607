import axios from "axios"
//React
import { useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
//Styles
import "./App.css"
//Gloabal auth via user
import { useAuthContext } from "./Hooks/useAuthContext"
import useLocalStorage from "use-local-storage"
//Components
import LandingPage from "./Pages/Landing/LandingPage"
import TopNav from "./Components/Navigation/TopNav"
import ManageUser from "./Pages/ManageUser"
import Devices from "./Pages/Devices/Devices"
import Employees from "./Pages/Employees/Employees"
import DataSets from "./Pages/DataSets/DataSets"
import ManageAccount from "./Pages/Account/ManageAccount"
import Home from "./Pages/Home/Home"
import { useState } from "react"
import CloudServices from "./Pages/CloudServices.js/CloudServices"
import IncidentReports from "./Pages/IncidentReports/IncidentReports"
import SideNav from "./Components/Navigation/SideNav"
import SideContainer from "./Components/Containers/SideContainer"
import MainContainer from "./Components/Containers/MainContainer"
import AccountCompliance from "./Pages/Account/AccountCompliance"
import TrainingCourses from "./Pages/TrainingCourses/TrainingCourses"
import AccountSummary from "./Pages/Account/AccountSummary"

function App() {
  const { dispatch, user } = useAuthContext()
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkToken = async (existing) => {
      axios
        .get(`https://${process.env.REACT_APP_IP}/api/users/checktoken`, {
          headers: { Authorization: `bearer ${existing.token}` },
        })
        .then((res) => {
          dispatch({ type: "WELCOMEBACK", payload: res.data })
        })
        .catch((err) => {
          dispatch({ type: "LOGOUT" })
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const existingUser = JSON.parse(localStorage.getItem("user"))

    if (existingUser) {
      checkToken(existingUser)
      // dispatch({ type: "WELCOMEBACK", payload: user })
    } else {
      setLoading(false)
    }
  }, [dispatch])

  if (!loading) {
    return (
      <BrowserRouter>
        <div className="app" data-theme={theme}>
          {!user && (
            <Routes>
              <Route
                path="/company-compliance/:accountGUID"
                element={<AccountCompliance />}
              />
              <Route path="/*" element={<LandingPage />} />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          )}
          {user && (
            <>
              <TopNav setTheme={setTheme} theme={theme} />
              <SideContainer style={{ width: "15%", height: "100%" }}>
                <SideNav />
              </SideContainer>
              <MainContainer>
                <Routes>
                  {user && (
                    <>
                      <Route path="/" element={<Home theme={theme} />} />
                      <Route path="/devices/*" element={<Devices />} />
                      <Route path="/data_sets/*" element={<DataSets />} />
                      <Route path="/employees/*" element={<Employees />} />
                      <Route
                        path="/training_courses/*"
                        element={<TrainingCourses />}
                      />
                      <Route
                        path="/cloud_services/*"
                        element={<CloudServices />}
                      />
                      <Route
                        path="/incident_reports/*"
                        element={<IncidentReports />}
                      />
                      <Route
                        path="/company-compliance/:accountGUID"
                        element={<AccountCompliance />}
                      />
                      <Route path="/user" element={<ManageUser />} />
                      <Route path="*" element={<Navigate to={"/"} />} />
                    </>
                  )}
                  {user && user.isAdmin && (
                    <>
                      <Route path="/account" element={<ManageAccount />} />
                      <Route
                        path="/company-summary/:accountGUID"
                        element={<AccountSummary />}
                      />
                      <Route path="*" element={<Navigate to={"/"} />} />
                    </>
                  )}
                </Routes>
              </MainContainer>
            </>
          )}
        </div>
      </BrowserRouter>
    )
  } else {
    return (
      <div className="app" data-theme={theme}>
        <h1>Loading...</h1>
      </div>
    )
  }
}

export default App
