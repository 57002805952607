import axios from "axios"
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../../../Hooks/useAuthContext"

import CloudServiceRow from "./Components/View/CloudServiceRow"
import MainHeading from "../../../../Components/Headings/MainHeading"
import { locale } from "../../../../Config/Constants"
import { toast } from "react-toastify"

export default function ViewCloudService() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [cloudService, setCloudService] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getCloudService = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/cloudservices/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setCloudService(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getCloudService()
  }, [params.id])

  const handleDelete = async (serviceId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/cloudservices/${serviceId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Cloud Service deleted.")
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    }
  }

  console.log(cloudService)

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex flex-column col-12">
          <div className="d-flex mb-5">
            <h1>{cloudService.name}</h1>
            <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate(`../cloud_service/${cloudService.id}`)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(cloudService.id)}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="mb-5">
            <CloudServiceRow
              label={"Type"}
              content={cloudService.type ?? "Not Found"}
            />
            <CloudServiceRow
              label={"MFA Enabled"}
              content={cloudService.mfaEnabled ? "True" : "False"}
            />
          </div>

          <div>
            <p style={{ fontSize: 26 }}>ACCESS RECORD</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless ">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                    <td>START DATE</td>
                    <td>END DATE</td>
                  </tr>
                </thead>
                <tbody>
                  {cloudService.employeesWithAccess.map(
                    (employeeWithAccess) => (
                      <tr key={employeeWithAccess.id} className="tableRow">
                        <td
                          className="hoverMe text-decoration-underline"
                          onClick={() =>
                            navigate(
                              `/employees/view_employee/${employeeWithAccess.employee.id}`
                            )
                          }
                        >
                          {employeeWithAccess.employee.name}
                        </td>
                        <td>
                          {new Date(
                            employeeWithAccess.dateStart
                          ).toLocaleDateString(locale)}
                        </td>
                        <td>
                          {employeeWithAccess.dateEnd
                            ? new Date(
                                employeeWithAccess.dateEnd
                              ).toLocaleDateString(locale)
                            : "In Use"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" mt-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
