import axios from "axios"
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EmployeeRow from "./Components/EmployeeRow"
import { useAuthContext } from "../../Hooks/useAuthContext"
import MainHeading from "../../Components/Headings/MainHeading"
import { locale } from "../../Config/Constants"
import { toast } from "react-toastify"

export default function ViewEmployee() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [employee, setEmployee] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/employees/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setEmployee(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getEmployee()
  }, [params.id])

  const handleDelete = async (employeeId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/employees/${employeeId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Employee deleted.")
    } catch (error) {
      console.log(error)
      toast.error("Oops something went wrong. Please try again.")
    }
  }

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex flex-column col-12">
          <div className="d-flex mb-5">
            <h1>{employee.name}</h1>
            <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate(`../employee/${employee.id}`)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(employee.id)}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="d-flex flex-column mb-5">
            <EmployeeRow label={"Email"} content={employee.email} />
            <EmployeeRow
              label={"Phone"}
              content={employee.telephone ? employee.telephone : ""}
            />
            <EmployeeRow
              label={"Address"}
              content={employee.address ? employee.address : ""}
            />
            <EmployeeRow
              label={"Postcode"}
              content={employee.postCode ? employee.postCode : ""}
            />
            <EmployeeRow
              label={"Wors From Home?"}
              content={employee.worksFromHome ? "Yes" : "No"}
            />
          </div>

          <div>
            <p style={{ fontSize: 26 }}>USERNAMES</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                  </tr>
                </thead>
                <tbody>
                  {employee.usernames && employee.usernames.length > 0 ? (
                    employee.usernames.map((entry) => (
                      <tr key={entry.id}>
                        <td>{entry.username}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Usernames Found...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-5">
            <p style={{ fontSize: 26 }}>CLOUD SERVICE RECORD</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                    <td>START DATE</td>
                    <td>END DATE</td>
                  </tr>
                </thead>
                <tbody>
                  {employee.cloudServices &&
                  employee.cloudServices.length > 0 ? (
                    employee.cloudServices.map((entry) => (
                      <tr key={entry.id}>
                        <td
                          className="hoverMe text-decoration-underline"
                          onClick={() =>
                            navigate(
                              `/cloud_services/view_cloud_service/${entry.cloudServiceId}`
                            )
                          }
                        >
                          {entry.name}
                        </td>
                        <td>
                          {new Date(entry.dateStart).toLocaleDateString(locale)}
                        </td>
                        <td>
                          {entry.dateRemoved
                            ? new Date(entry.dateRemoved).toLocaleDateString(
                                locale
                              )
                            : "In Use"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Cloud Services Found...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-5">
            <p style={{ fontSize: 26 }}>TRAINING COURSE RECORD</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                    <td>DATE TRAINED</td>
                  </tr>
                </thead>
                <tbody>
                  {employee.trainingCourses &&
                  employee.trainingCourses.length > 0 ? (
                    employee.trainingCourses.map((entry) => (
                      <tr key={entry.id}>
                        <td
                          className="hoverMe text-decoration-underline"
                          onClick={() =>
                            navigate(
                              `/training_courses/view_training_course/${entry.trainingCourseId}`
                            )
                          }
                        >
                          {entry.trainingCourseTitle}
                        </td>
                        <td>
                          {new Date(entry.date).toLocaleDateString(locale)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Traing Courses Found...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
