import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"
import TableItem from "./TableItem"

export default function HDDTable({ hdds, setField, deletedHDDS }) {
  const handleDelete = (id) => {
    const filteredList = hdds.filter((item) => item.id !== id)

    if (!isNaN(id)) {
      setField("deletedHDDS", [...deletedHDDS, id])
    }

    setField("hdds", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newHDD = {
      id: randomId,
    }

    setField("hdds", [...hdds, newHDD])
  }

  const handleEdit = (id, field, value) => {
    const existing = hdds.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...hdds, newEdit]
    } else {
      let updatedList = hdds.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("hdds", updatedList)
    }
  }

  return (
    <div className="d-flex flex-column">
      <table className="table table-borderless" style={{ minWidth: "800px" }}>
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>TYPE</td>
            <td>SERIAL</td>
            <td>CAPACITY (GB)</td>
            <td>STATUS</td>
            <td>DISPOSAL DATE</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {hdds.map((item) => (
            <tr key={item.id}>
              <td>
                <Form.Select
                  defaultValue={item.type ?? ""}
                  onChange={(e) => handleEdit(item.id, "type", e.target.value)}
                >
                  <option hidden value="">
                    Select Type
                  </option>
                  <option value="Unknown">Unknown</option>
                  <option value="HDD">HDD</option>
                  <option value="M2">M2</option>
                  <option value="SSD">SSD</option>
                  <option value="Tape">Tape</option>
                </Form.Select>
              </td>
              <td>
                <TableItem
                  item={item}
                  field="size"
                  handleEdit={handleEdit}
                  type={"number"}
                />
              </td>
              <td>
                <TableItem item={item} field="serial" handleEdit={handleEdit} />
              </td>
              <td>
                <Form.Select
                  required
                  className="form-control"
                  defaultValue={item.status ?? ""}
                  onChange={(e) =>
                    handleEdit(item.id, "status", e.target.value)
                  }
                >
                  <option hidden value="">
                    Select Status
                  </option>
                  <option value="Installed">Installed</option>
                  <option value="Removed">Removed</option>
                  <option value="Destroyed">Destroyed</option>
                  <option value="Erased">Erased</option>
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  defaultValue={
                    item.dateOfDisposal ? item.dateOfDisposal.split("T")[0] : ""
                  }
                  type={`date`}
                  onChange={(e) =>
                    handleEdit(item.id, "dateOfDisposal", e.target.value)
                  }
                />
              </td>
              <td className="d-flex align-content-center justify-content-center ">
                <div
                  className="btn btn-danger"
                  onClick={() => handleDelete(item.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-end" style={{ minWidth: "800px" }}>
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
