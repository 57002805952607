import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact1() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact1 text-white">
            The estimated global annual cost of cybercrime currently stands at{" "}
            <span className="fact1Span">$6 trillion</span>, and it is projected
            to rise significantly to approximately{" "}
            <span className="fact1Span">$10.5 trillion</span> by the year 2025.
          </h2>
          <p className="fact-reference text-white"> - purplesec.us</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          Using a tool like cyber essentials can save you and your business a
          lot of money. Even if there is only one threat prevented each year.
        </h3>
      </div>
    </div>
  )
}
