import axios from "axios"
//React
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export default function AccountSummary() {
  const { accountGUID } = useParams()

  const [account, setAccount] = useState()
  const [allDevices, setAllDevices] = useState()
  const [devices, setDevices] = useState()
  const [thinClients, setThinClients] = useState()
  const [servers, setServers] = useState()
  const [mobileDevices, setMobileDevices] = useState()
  //   const [networks, setNetworks] = useState()
  const [employees, setEmployees] = useState()
  const [networkEquipment, setNetworkEquipment] = useState()
  const [CloudServices, setCloudServices] = useState()

  const [loading, setLoading] = useState(true)

  console.log("cloud services", CloudServices)

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/accounts/guid/${accountGUID}`
        )
        setAccount(res.data)
        setAllDevices(res.data.devices)
        setEmployees(res.data.employees)
        setCloudServices(res.data.cloudServices)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    getAccountDetails()
  }, [accountGUID])

  useEffect(() => {
    if (allDevices) {
      try {
        setLoading(true)
        setDevices(
          allDevices.filter((x) => x.type === "Desktop" || x.type === "Laptop")
        )
        setThinClients(allDevices.filter((x) => x.type === "Thin Client"))
        setServers(allDevices.filter((x) => x.type === "Server"))
        setMobileDevices(
          allDevices.filter((x) => x.type === "Mobile" || x.type === "Tablet")
        )
        setNetworkEquipment(
          allDevices.filter((x) => x.type === "Router" || x.type === "NAS")
        )
      } catch (err) {
        console.log("err", err)
      } finally {
        setLoading(false)
      }
    }
  }, [allDevices])

  return (
    <div className="p-4">
      <div className="mb-5 mt-3">
        <h2>Account Summary</h2>
      </div>
      <div className="d-flex ms-5">
        <div className="d-flex flex-column col bold">
          PC Devices:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {devices && devices.filter((x) => x.type === "Desktop").length}{" "}
              Desktops
            </div>
            {devices &&
              devices.filter((x) => x.type === "Desktop").length > 0 && (
                <>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Desktop" &&
                          x.operatingSystem.toLowerCase().includes("windows")
                      ).length}{" "}
                    Windows
                  </div>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Desktop" &&
                          x.operatingSystem.toLowerCase().includes("mac")
                      ).length}{" "}
                    Apple
                  </div>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Desktop" &&
                          x.operatingSystem.toLowerCase().includes("linux")
                      ).length}{" "}
                    Linux
                  </div>
                  <div className="ms-5 mb-3">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Desktop" &&
                          !x.operatingSystem
                            .toLowerCase()
                            .includes("windows") &&
                          !x.operatingSystem.toLowerCase().includes("mac") &&
                          !x.operatingSystem.toLowerCase().includes("linux")
                      ).length}{" "}
                    Other
                  </div>
                </>
              )}

            <div className="ms-4 mb-2">
              {devices && devices.filter((x) => x.type === "Laptop").length}{" "}
              Laptops
            </div>
            {devices &&
              devices.filter((x) => x.type === "Laptop").length > 0 && (
                <>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Laptop" &&
                          x.operatingSystem.toLowerCase().includes("windows")
                      ).length}{" "}
                    Windows
                  </div>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Laptop" &&
                          x.operatingSystem.toLowerCase().includes("mac")
                      ).length}{" "}
                    Apple
                  </div>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Laptop" &&
                          x.operatingSystem.toLowerCase().includes("linux")
                      ).length}{" "}
                    Linux
                  </div>
                  <div className="ms-5">
                    {devices &&
                      devices.filter(
                        (x) =>
                          x.type === "Laptop" &&
                          !x.operatingSystem
                            .toLowerCase()
                            .includes("windows") &&
                          !x.operatingSystem.toLowerCase().includes("mac") &&
                          !x.operatingSystem.toLowerCase().includes("linux")
                      ).length}{" "}
                    Other
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="d-flex flex-column col bold">
          Thin Clients:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4">
              {thinClients && thinClients.length} Thin Clients
            </div>
          </div>
        </div>
        <div className="d-flex flex-column col bold">
          Servers:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {servers && servers.filter((x) => !x.virtualServer).length}{" "}
              Servers
            </div>
            <div className="ms-4">
              {servers && servers.filter((x) => x.virtualServer).length} Virtual
              Servers
            </div>
          </div>
        </div>
        <div className="d-flex flex-column col bold">
          Mobile Devices:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {mobileDevices &&
                mobileDevices.filter((x) => x.type === "Mobile").length}{" "}
              Mobiles
            </div>
            {mobileDevices &&
              mobileDevices.filter((x) => x.type === "Mobile").length > 0 && (
                <>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Mobile" &&
                          x.operatingSystem.toLowerCase().includes("windows")
                      ).length}{" "}
                    Windows
                  </div>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Mobile" &&
                          x.operatingSystem.toLowerCase().includes("ios")
                      ).length}{" "}
                    Apple
                  </div>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Mobile" &&
                          x.operatingSystem.toLowerCase().includes("android")
                      ).length}{" "}
                    Android
                  </div>
                  <div className="ms-5 mb-3">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Mobile" &&
                          !x.operatingSystem
                            .toLowerCase()
                            .includes("windows") &&
                          !x.operatingSystem.toLowerCase().includes("ios") &&
                          !x.operatingSystem.toLowerCase().includes("android")
                      ).length}{" "}
                    Other
                  </div>
                </>
              )}

            <div className="ms-4 mb-2">
              {mobileDevices &&
                mobileDevices.filter((x) => x.type === "Tablet").length}{" "}
              Tablets
            </div>
            {mobileDevices &&
              mobileDevices.filter((x) => x.type === "Tablet").length > 0 && (
                <>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Tablet" &&
                          x.operatingSystem.toLowerCase().includes("windows")
                      ).length}{" "}
                    Windows
                  </div>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Tablet" &&
                          x.operatingSystem.toLowerCase().includes("ios")
                      ).length}{" "}
                    Apple
                  </div>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Tablet" &&
                          x.operatingSystem.toLowerCase().includes("android")
                      ).length}{" "}
                    Android
                  </div>
                  <div className="ms-5">
                    {mobileDevices &&
                      mobileDevices.filter(
                        (x) =>
                          x.type === "Tablet" &&
                          !x.operatingSystem
                            .toLowerCase()
                            .includes("windows") &&
                          !x.operatingSystem.toLowerCase().includes("ios") &&
                          !x.operatingSystem.toLowerCase().includes("android")
                      ).length}{" "}
                    Other
                  </div>
                </>
              )}
          </div>
        </div>
        {/* <div>Networks:</div> */}
        <div className="d-flex flex-column col bold">
          Network Equipment:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {networkEquipment &&
                networkEquipment.filter((x) => x.type === "Router").length}{" "}
              Routers
            </div>
            <div className="ms-4">
              {networkEquipment &&
                networkEquipment.filter((x) => x.type === "NAS").length}{" "}
              NASs
            </div>
          </div>
        </div>
        <div className="d-flex flex-column col bold">
          Employees:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {employees && employees.length} Employees
            </div>
            <div className="ms-4">
              {employees && employees.filter((x) => x.worksFromHome).length}{" "}
              Work From Home
            </div>
          </div>
        </div>
        <div className="d-flex flex-column col bold">
          Cloud Services:
          <div className="d-flex flex-column mt-3">
            <div className="ms-4 mb-2">
              {CloudServices && CloudServices.length} Cloud Services
            </div>
            <div className="ms-5">
              {CloudServices &&
                CloudServices.filter((x) => x.type === "SAAS").length}{" "}
              SAAS
            </div>
            <div className="ms-5">
              {CloudServices &&
                CloudServices.filter((x) => x.type === "PAAS").length}{" "}
              PAAS
            </div>
            <div className="ms-5">
              {CloudServices &&
                CloudServices.filter((x) => x.type === "IAAS").length}{" "}
              IAAS
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
