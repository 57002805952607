import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLaptop,
  faComputer,
  faServer,
  faPrint,
  faNetworkWired,
  faMobileScreenButton,
  faTabletScreenButton,
  faQuestion,
  faDesktop,
  faHardDrive,
} from "@fortawesome/free-solid-svg-icons"

export default function DeviceViewIcon({ device }) {
  const [deviceType, setDeviceType] = useState()
  const [deviceOS, setDeviceOS] = useState()
  const [auditStatus, setAuditStatus] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getIconDetails = (type, operatingSystem, isMonitored, status) => {
      try {
        if (type === "Desktop") {
          setDeviceType("Desktop")
        } else if (type === "Laptop") {
          setDeviceType("Laptop")
        }
        // else if (type === "Mobile") {
        //   setDeviceType("Mobile")
        // } else if (type === "Printer") {
        //   setDeviceType("Printer")
        // } else if (type === "Router") {
        //   setDeviceType("Router")
        // } else if (type === "Server") {
        //   setDeviceType("Server")
        // } else if (type === "Tablet") {
        //   setDeviceType("Tablet")
        // } else if (type === "NAS") {
        //   setDeviceType("NAS")
        // } else if (type === "Thin Client") {
        //   setDeviceType("Thin Client")
        // }
        else {
          setDeviceType("Other")
        }

        if (operatingSystem.toLowerCase().includes("windows")) {
          setDeviceOS("Windows")
        } else if (operatingSystem.toLowerCase().includes("mac")) {
          setDeviceOS("Apple")
        } else if (operatingSystem.toLowerCase().includes("linux")) {
          setDeviceOS("Linux")
        } else {
          setDeviceOS("Other")
        }

        if (isMonitored) {
          if (status) {
            setAuditStatus("green")
          } else {
            setAuditStatus("red")
          }
        } else {
          setAuditStatus("gray")
        }
      } catch (err) {
        console.log("err", err)
      } finally {
        setLoading(false)
      }
    }

    if (device.type) {
      getIconDetails(
        device.type,
        device.operatingSystem,
        device.isMonitored,
        device.status
      )
    }
  }, [device])

  return (
    <>
      {!loading && (
        <div className="">
          {deviceType ? (
            deviceType === "Other" ? (
              <div className="ms-5 mt-5">
                <FontAwesomeIcon
                  icon={faQuestion}
                  size="10x"
                  className="ms-4"
                />
              </div>
            ) : (
              <img
                height={350}
                width="auto"
                src={require(`../../../../Components/Imgs/${deviceType}.png`)}
              />
            )
          ) : (
            <div />
          )}
          {deviceOS ? (
            deviceOS === "Other" ? (
              <>
                {deviceType !== "Other" && (
                  <FontAwesomeIcon
                    icon={faQuestion}
                    size="1x"
                    style={
                      deviceType === "Desktop"
                        ? { marginBottom: 5, marginLeft: -65 }
                        : deviceType === "Laptop"
                        ? { marginBottom: 10, marginLeft: -48 }
                        : {}
                    }
                  />
                )}
              </>
            ) : (
              <img
                height={150}
                width="auto"
                src={require(`../../../../Components/Imgs/${deviceOS}.png`)}
                style={
                  deviceType === "Desktop"
                    ? { marginTop: -80, marginLeft: -385 }
                    : deviceType === "Laptop"
                    ? { marginTop: -120, marginLeft: -290 }
                    : {}
                }
              />
            )
          ) : (
            <div />
          )}
          {auditStatus && (
            <div
              className="bringFront"
              style={
                deviceType === "Desktop"
                  ? {
                      backgroundColor: auditStatus,
                      height: 60,
                      width: 60,
                      borderRadius: 60,
                      marginTop: -75,
                      marginLeft: -20,
                    }
                  : deviceType === "Laptop"
                  ? {
                      backgroundColor: auditStatus,
                      height: 60,
                      width: 60,
                      borderRadius: 60,
                      marginTop: -150,
                      marginLeft: 10,
                    }
                  : {}
              }
            />
          )}
        </div>
      )}
    </>
  )
}
