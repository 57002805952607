//Axios
import axios from "axios"

//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Button from "react-bootstrap/Button"

//React
import { useEffect, useState } from "react"

//Router
import { useNavigate } from "react-router-dom"
//Helper
import { validateForm } from "../../Helpers/FormValidation"
import { toast } from "react-toastify"
import { useAuthContext } from "../../Hooks/useAuthContext"
import SubmitButton from "../SubmitButton"

export default function EditUser({ userDetails, setUserDetails }) {
  const { user, dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const updateUser = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    const data = {
      name: userDetails.name,
      address: userDetails.address,
      email: userDetails.email,
    }
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/users/${user.id}`,
        data,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      console.log(res)
      if (res.data.email !== user.email) {
        toast.success(
          "Email has been changed. Please login again. Logging you out now."
        )
        dispatch({ type: "LOGOUT", payload: user })
        navigate("../")
      } else {
        toast.success("User updated.")

        const updatedUser = {
          ...user,
          name: res.data.name,
        }

        localStorage.setItem("user", JSON.stringify(updatedUser))
        // window.location.reload(false)
      }
    } catch (err) {
      console.log(err.response)
      if (err.response.data === "User Exists") {
        setFormErrors((prev) => {
          return { ...prev, email: "Email already exists" }
        })
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      console.log(userDetails)
      const newErrors = validateForm(userDetails)

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [userDetails])

  console.log(formErrors, "Errors")

  const setField = (field, value) => {
    setUserDetails({
      ...userDetails,
      [field]: value,
    })
  }

  return (
    <Form onSubmit={updateUser}>
      <Form.Group controlId="validationCustom01">
        <FloatingLabel label="Name" className="mb-3">
          <Form.Control
            defaultValue={userDetails.name ?? ""}
            required
            type="text"
            placeholder="Enter Name"
            isInvalid={formErrors.name}
            isValid={formErrors.name === ""}
            onChange={(e) => setField("name", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.name}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group>
        <FloatingLabel label="Email address" className="mb-3">
          <Form.Control
            defaultValue={userDetails.email ?? ""}
            required
            type="email"
            placeholder="name@example.com"
            isInvalid={formErrors.email}
            isValid={formErrors.email === ""}
            onChange={(e) => setField("email", e.target.value)}
          />
          <Form.Control.Feedback className="text-danger" type="invalid">
            {formErrors.email}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="d-flex justify-content-end align-items-center mt-3">
        <SubmitButton submitting={submitting} />
      </Form.Group>
      <span style={{ fontSize: "10px" }}>
        * Changing the email address will log you out.
      </span>
    </Form>
  )
}
