import axios from "axios"
import React, { useEffect, useState } from "react"
import DeviceListIcon from "../Components/DeviceListIcon"
import Spinner from "../../../Components/Loading/Spinner"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

export default function DeviceTable({
  deviceList,
  setDeviceList,
  pagination,
  setPagination,
  setTotalCount,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const checkDate = new Date()
  checkDate.setDate(checkDate.getDate() - 3)

  const getDevices = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/devices/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&office=${pagination.office}&room=${pagination.room}&status=${pagination.status}&pageNumber=${pagination.pageNumber}&sort=${pagination.sort}&search=${pagination.search}&type=${pagination.type}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setDeviceList(res.data.devices)
      setTotalCount(res.data.count)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDevices()
  }, [user, pagination])

  return (
    <table className="table table-borderless">
      <thead
        className="bold"
        style={{ borderBottom: "1px solid var(--secondary)" }}
      >
        <tr>
          <th
            className="col-1 hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort: pagination.sort === "type-a" ? "type-d" : "type-a",
                }
              })
            }
          >
            TYPE
            {pagination.sort === "type-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "type-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th
            className="hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort: pagination.sort === "name-a" ? "name-d" : "name-a",
                }
              })
            }
          >
            DEVICE
            {pagination.sort === "name-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "name-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th className="">SERIAL</th>
          <th
            className="hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort:
                    pagination.sort === "date-audited-a"
                      ? "date-audited-d"
                      : "date-audited-a",
                }
              })
            }
          >
            LAST CHECKED
            {pagination.sort === "date-audited-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "date-audited-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th
            className="hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort:
                    pagination.sort === "date-detected-a"
                      ? "date-detected-d"
                      : "date-detected-a",
                }
              })
            }
          >
            LAST ONLINE
            {pagination.sort === "date-detected-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "date-detected-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th
            className="hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort:
                    pagination.sort === "office-a" ? "office-d" : "office-a",
                }
              })
            }
          >
            OFFICE
            {pagination.sort === "office-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "office-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th
            className="hoverMe"
            onClick={() =>
              setPagination((prev) => {
                return {
                  ...prev,
                  sort: pagination.sort === "room-a" ? "room-d" : "room-a",
                }
              })
            }
          >
            ROOM
            {pagination.sort === "room-a" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            )}
            {pagination.sort === "room-d" && (
              <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
            )}
          </th>
          <th>STATUS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={6} className="text-center py-3">
              <Spinner />
            </td>
          </tr>
        ) : deviceList.length > 0 ? (
          deviceList.map((device) => {
            return (
              <tr key={device.id}>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  <DeviceListIcon device={device} />
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  <div className="d-flex flex-column">
                    <div>{device.name}</div>
                    <div style={{ fontSize: 12 }}>{device.ipAddress}</div>
                  </div>
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {device.serial}
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {device.isMonitored
                    ? new Date(device.dateLastAudited) > new Date(checkDate)
                      ? "Monitored"
                      : device.dateLastAudited
                      ? new Date(device.dateLastAudited).toLocaleDateString()
                      : "Un-Audited"
                    : device.dateLastAudited
                    ? new Date(device.dateLastAudited).toLocaleDateString()
                    : "Un-Audited"}
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {new Date(device.dateLastDetected).toDateString() ===
                  new Date().toDateString()
                    ? "Today"
                    : device.dateLastDetected
                    ? new Date(device.dateLastDetected).toLocaleDateString()
                    : "Not Detected"}
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {device.office}
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {device.room}
                </td>
                <td
                  className="hoverMe"
                  onClick={() => navigate(`./view_device/${device.id}`)}
                >
                  {device.status}
                </td>
                <td className="d-flex justify-content-center">
                  <button
                    style={{ fontSize: 14, width: 100, borderRadius: 6 }}
                    className="btn btn-primary"
                    onClick={() => navigate(`./device/${device.id}`)}
                  >
                    EDIT
                  </button>
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={6} className="text-center py-3">
              No results...
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
