import axios from "axios"
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"

import TrainingCourseRow from "./Components/TrainingCourseRow"
import { locale } from "../../Config/Constants"
import { toast } from "react-toastify"

export default function ViewTrainingCourse() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [trainingCourse, setTrainingCourse] = useState({})
  const [loading, setLoading] = useState(true)

  console.log("trainingCourse", trainingCourse)

  useEffect(() => {
    const getTrainingCourse = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/trainingcourses/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setTrainingCourse(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getTrainingCourse()
  }, [params.id])

  const handleDelete = async (courseId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/trainingcourses/${courseId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Training courses deleted.")
    } catch (error) {
      console.log(error)
      if (error.response.data === "Employees exist on this course.") {
        toast.error("Employees have taken this course. Cannot delete")
      } else {
        toast.error("Oops something went wrong. Please try again.")
      }
    }
  }

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex flex-column col-12">
          <div className="d-flex mb-5">
            <h1>{trainingCourse.title}</h1>
            <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
              <button
                className="btn btn-primary"
                onClick={() =>
                  navigate(`../training_course/${trainingCourse.id}`)
                }
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(trainingCourse.id)}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="mb-5">
            <TrainingCourseRow
              label={"Location"}
              content={trainingCourse.location}
            />
            <TrainingCourseRow
              label={"Description"}
              content={trainingCourse.description}
            />
          </div>

          <div>
            <p style={{ fontSize: 26 }}>EMPLOYEE RECORD</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <table className="table table-borderless ">
                <thead
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                  className="bold"
                >
                  <tr>
                    <td>NAME</td>
                    <td>DATE TRAINED</td>
                  </tr>
                </thead>
                <tbody>
                  {trainingCourse.employees.map((employee) => (
                    <tr key={employee.id} className="tableRow">
                      <td
                        className="hoverMe text-decoration-underline"
                        onClick={() =>
                          navigate(
                            `/employees/view_employee/${employee.employee.id}`
                          )
                        }
                      >
                        {employee.employee.name}
                      </td>
                      <td>
                        {new Date(employee.date).toLocaleDateString(locale)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" mt-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
