import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditDevice from "../Device/CreateEditDevice"
import ViewDevice from "../Device/ViewDevice"
import DevicesHome from "./Pages/DevicesHome"

export default function Devices() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<DevicesHome />} />
        <Route path="/device/:id" element={<CreateEditDevice />} />
        <Route path="/view_device/:id" element={<ViewDevice />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}
