import React from "react";
import "./SideContainer.css";

export default function SideContainer({ children, classes, style }) {
  return (
    <div style={{ ...style }} className={`sideCon ${classes}`}>
      {children}
    </div>
  );
}
