import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"

export default function UserTable({
  setField,
  employeesWithAccess,
  selectUsers,
  deletedEmployeeAccess,
}) {
  const handleDelete = (id) => {
    const filteredList = employeesWithAccess.filter((item) => item.id !== id)

    if (!isNaN(id)) {
      setField("deletedEmployeeAccess", [...deletedEmployeeAccess, id])
    }

    setField("employeesWithAccess", filteredList)
  }

  const handleAdd = () => {
    const randomId = (Math.random() + 1).toString(36).substring(7)

    const newLog = {
      id: randomId,
      isAdmin: false,
    }

    setField("employeesWithAccess", [...employeesWithAccess, newLog])
  }

  const handleEdit = (id, field, value) => {
    const existing = employeesWithAccess.find((i) => i.id === id)

    if (!existing) {
      const newEdit = {
        id: id,
        [field]: value,
      }
      return [...employeesWithAccess, newEdit]
    } else {
      let updatedList = employeesWithAccess.map((i) => {
        if (i.id === id) {
          i[field] = value
          return i
        }
        return i
      })

      setField("employeesWithAccess", updatedList)
    }
  }

  console.log(employeesWithAccess)
  return (
    <div className="d-flex flex-column align-items-center justify-content-center col">
      <table className="table table-borderless ">
        <thead
          style={{ borderBottom: "1px solid var(--secondary)" }}
          className="bold"
        >
          <tr>
            <td>NAME</td>
            <td>IS ADMIN</td>
            <td>START DATE</td>
            <td>END DATE</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {employeesWithAccess.map((employee) => (
            <tr key={employee.id}>
              <td>
                <Form.Select
                  value={employee.employeeId ?? "None"}
                  onChange={(e) =>
                    handleEdit(employee.id, "employeeId", e.target.value)
                  }
                >
                  <option hidden value="">
                    Select Employee
                  </option>
                  {selectUsers && selectUsers.length > 0 ? (
                    selectUsers.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Employees Found</option>
                  )}
                </Form.Select>
              </td>
              <td className="d-flex ">
                <Form.Check
                  className="ms-3"
                  onChange={(e) =>
                    handleEdit(employee.id, "isAdmin", e.target.checked)
                  }
                  defaultChecked={employee.isAdmin}
                />
              </td>
              <td>
                <Form.Control
                  defaultValue={
                    employee.dateStart ? employee.dateStart.split("T")[0] : ""
                  }
                  type={`date`}
                  onChange={(e) =>
                    handleEdit(employee.id, "dateStart", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  defaultValue={
                    employee.dateEnd ? employee.dateEnd.split("T")[0] : ""
                  }
                  type={`date`}
                  onChange={(e) =>
                    handleEdit(employee.id, "dateEnd", e.target.value)
                  }
                />
              </td>
              <td className="d-flex justify-content-center ">
                <div
                  className="btn btn-danger"
                  onClick={() => handleDelete(employee.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} size={"1x"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex col-12 justify-content-end">
        <div className="btn btn-primary" onClick={handleAdd}>
          <FontAwesomeIcon size={"1x"} icon={faAdd} />
        </div>
      </div>
    </div>
  )
}
