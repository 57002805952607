import axios from "axios"
import React, { useEffect, useState } from "react"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import TablePageSelect from "../../../Components/Table/TablePageSelect"
import { Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import DeviceTable from "../Components/DeviceTable"
import useLocalStorage from "use-local-storage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import exampleFile from "../Components/example.csv"

export default function DevicesHome() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [deviceList, setDeviceList] = useState([])
  const [types, setTypes] = useState([])
  const [offices, setOffices] = useState([])
  const [devicesFile, setDevicesFile] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [mapCSV, setMapCSV] = useState(false)
  const [mappedHeaders, setMappedHeaders] = useState()
  const [csvHeaders, setCSVheaders] = useState()
  const mapHeaders = [
    "Name",
    "Type",
    "IpAddress",
    "Manufacturer",
    "Model",
    "Serial",
    "CPUManufacturer",
    "CPUModel",
    "CPUSpeed",
    "RAMSize",
    "RAMType",
    "GraphicsCard",
    "MacAddress",
    "HasInternetAccess",
    "BackupsEnabled",
    "DefaultPasswordReset",
    "PCAutoPlayDisabled",
    "AutomaticUpdatesEnabled",
    "AutomaticUpdatesLastChecked",
    "DateLastAudited",
    "OperatingSystem",
    "OperatingSystemVersion",
    "Antivirus",
    "AntivirusVersion",
    "PurchaseDate",
    "PurchasePrice",
    "Location",
  ]
  const [csvData, setCSVData] = useState()
  const [submitting, setSubmitting] = useState(false)

  const fileReader = new FileReader()

  //Paginations
  const [pagination, setPagination] = useLocalStorage("devicesSearch", {
    status: "all",
    pageNumber: 1,
    sort: "name-d",
    search: "",
    type: "",
    pageSize: 10,
    office: "",
    room: "",
  })

  console.log("pagination", pagination)

  useEffect(() => {
    if (location.state) {
      setPagination((prev) => {
        return { ...prev, status: location.state }
      })
    }
  }, [location.state])

  useEffect(() => {
    const getTypes = async () => {
      try {
        const res = await axios.get(
          `https://${
            process.env.REACT_APP_IP
          }/api/selectListOptions/${"Device Type"}/listoptions`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setTypes(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    const getOffices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/offices/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setOffices(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    getTypes()
    getOffices()
  }, [user])

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",")
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n")

    csvHeader[csvHeader.length - 1] =
      csvHeader[csvHeader.length - 1].split("\r")[0]

    csvRows.map((row, i) => {
      csvRows[i] = row.split("\r")[0]
    })

    const array = csvRows.map((i) => {
      const values = i.split(",")
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })

    var autoAssignedHeaders = {}

    csvHeader.map((header) => {
      mapHeaders.map((mapHeader) => {
        if (mapHeader === header) {
          autoAssignedHeaders[mapHeader] = header
        }
      })
    })

    setMappedHeaders({
      ...mappedHeaders,
      ...autoAssignedHeaders,
    })

    setCSVheaders(csvHeader)
    setCSVData(array)
  }

  useEffect(() => {
    if (devicesFile) {
      setMapCSV(!mapCSV)

      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }

      fileReader.readAsText(devicesFile)
    }
  }, [devicesFile])

  const setField = (field, value) => {
    setMappedHeaders({
      ...mappedHeaders,
      [field]: value,
    })
  }

  const handleCancel = async (e) => {
    setDevicesFile()
    setMapCSV(false)
    setMappedHeaders()
    setCSVheaders()
    setCSVData()
    setSubmitting(false)
  }

  console.log("csvData", csvData)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)

    var csvDevices = []

    csvData.map((csvRow) => {
      var name =
        csvRow[mappedHeaders["Name"]] !== ""
          ? csvRow[mappedHeaders["Name"]]
          : undefined
      var type =
        csvRow[mappedHeaders["Type"]] !== ""
          ? csvRow[mappedHeaders["Type"]]
          : undefined
      var ipAddress =
        csvRow[mappedHeaders["IpAddress"]] !== ""
          ? csvRow[mappedHeaders["IpAddress"]]
          : undefined
      var manufacturer =
        csvRow[mappedHeaders["Manufacturer"]] !== ""
          ? csvRow[mappedHeaders["Manufacturer"]]
          : undefined
      var model =
        csvRow[mappedHeaders["Model"]] !== ""
          ? csvRow[mappedHeaders["Model"]]
          : undefined
      var serial =
        csvRow[mappedHeaders["Serial"]] !== ""
          ? csvRow[mappedHeaders["Serial"]]
          : undefined
      var cpuManufacturer =
        csvRow[mappedHeaders["CPUManufacturer"]] !== ""
          ? csvRow[mappedHeaders["CPUManufacturer"]]
          : undefined
      var cpuModel =
        csvRow[mappedHeaders["CPUModel"]] !== ""
          ? csvRow[mappedHeaders["CPUModel"]]
          : undefined
      var cpuSpeed =
        csvRow[mappedHeaders["CPUSpeed"]] !== ""
          ? csvRow[mappedHeaders["CPUSpeed"]]
          : undefined
      var ramSize =
        csvRow[mappedHeaders["RAMSize"]] !== ""
          ? csvRow[mappedHeaders["RAMSize"]]
          : undefined
      var ramType =
        csvRow[mappedHeaders["RAMType"]] !== ""
          ? csvRow[mappedHeaders["RAMType"]]
          : undefined
      var graphicsCard =
        csvRow[mappedHeaders["GraphicsCard"]] !== ""
          ? csvRow[mappedHeaders["GraphicsCard"]]
          : undefined
      var macAddress =
        csvRow[mappedHeaders["MacAddress"]] !== ""
          ? csvRow[mappedHeaders["MacAddress"]]
          : undefined
      var hasInternetAccess =
        csvRow[mappedHeaders["HasInternetAccess"]] !== ""
          ? csvRow[mappedHeaders["HasInternetAccess"]]
          : undefined
      var backupsEnabled =
        csvRow[mappedHeaders["BackupsEnabled"]] !== ""
          ? csvRow[mappedHeaders["BackupsEnabled"]]
          : undefined
      var defaultPasswordReset =
        csvRow[mappedHeaders["DefaultPasswordReset"]] !== ""
          ? csvRow[mappedHeaders["DefaultPasswordReset"]]
          : undefined
      var pcAutoPlayDisabled =
        csvRow[mappedHeaders["PCAutoPlayDisabled"]] !== ""
          ? csvRow[mappedHeaders["PCAutoPlayDisabled"]]
          : undefined
      var automaticUpdatesEnabled =
        csvRow[mappedHeaders["AutomaticUpdatesEnabled"]] !== ""
          ? csvRow[mappedHeaders["AutomaticUpdatesEnabled"]]
          : undefined
      var automaticUpdatesLastChecked =
        csvRow[mappedHeaders["AutomaticUpdatesLastChecked"]] !== ""
          ? csvRow[mappedHeaders["AutomaticUpdatesLastChecked"]]
          : undefined
      var dateLastAudited =
        csvRow[mappedHeaders["DateLastAudited"]] !== ""
          ? csvRow[mappedHeaders["DateLastAudited"]]
          : undefined
      var operatingSystem =
        csvRow[mappedHeaders["OperatingSystem"]] !== ""
          ? csvRow[mappedHeaders["OperatingSystem"]]
          : undefined
      var operatingSystemVersion =
        csvRow[mappedHeaders["OperatingSystemVersion"]] !== ""
          ? csvRow[mappedHeaders["OperatingSystemVersion"]]
          : undefined
      var antivirus =
        csvRow[mappedHeaders["Antivirus"]] !== ""
          ? csvRow[mappedHeaders["Antivirus"]]
          : undefined
      var antivirusVersion =
        csvRow[mappedHeaders["AntivirusVersion"]] !== ""
          ? csvRow[mappedHeaders["AntivirusVersion"]]
          : undefined
      var purchaseDate =
        csvRow[mappedHeaders["PurchaseDate"]] !== ""
          ? csvRow[mappedHeaders["PurchaseDate"]]
          : undefined
      var purchasePrice =
        csvRow[mappedHeaders["PurchasePrice"]] !== ""
          ? csvRow[mappedHeaders["PurchasePrice"]]
          : undefined
      var location =
        csvRow[mappedHeaders["Location"]] !== ""
          ? csvRow[mappedHeaders["Location"]]
          : undefined

      if (hasInternetAccess && hasInternetAccess.toLowerCase() === "true") {
        hasInternetAccess = true
      }
      if (hasInternetAccess && hasInternetAccess.toLowerCase() === "false") {
        hasInternetAccess = false
      }

      if (backupsEnabled && backupsEnabled.toLowerCase() === "true") {
        backupsEnabled = true
      }
      if (backupsEnabled && backupsEnabled.toLowerCase() === "false") {
        backupsEnabled = false
      }

      if (
        defaultPasswordReset &&
        defaultPasswordReset.toLowerCase() === "true"
      ) {
        defaultPasswordReset = true
      }
      if (
        defaultPasswordReset &&
        defaultPasswordReset.toLowerCase() === "false"
      ) {
        defaultPasswordReset = false
      }

      if (pcAutoPlayDisabled && pcAutoPlayDisabled.toLowerCase() === "true") {
        pcAutoPlayDisabled = true
      }
      if (pcAutoPlayDisabled && pcAutoPlayDisabled.toLowerCase() === "false") {
        pcAutoPlayDisabled = false
      }

      if (
        automaticUpdatesEnabled &&
        automaticUpdatesEnabled.toLowerCase() === "true"
      ) {
        automaticUpdatesEnabled = true
      }
      if (
        automaticUpdatesEnabled &&
        automaticUpdatesEnabled.toLowerCase() === "false"
      ) {
        automaticUpdatesEnabled = false
      }

      if (
        !name &&
        !type &&
        !ipAddress &&
        !manufacturer &&
        !model &&
        !serial &&
        !cpuManufacturer &&
        !cpuModel &&
        !cpuSpeed &&
        !ramSize &&
        !ramType &&
        !graphicsCard &&
        !macAddress &&
        !hasInternetAccess &&
        !backupsEnabled &&
        !defaultPasswordReset &&
        !pcAutoPlayDisabled &&
        !automaticUpdatesEnabled &&
        !automaticUpdatesLastChecked &&
        !dateLastAudited &&
        !operatingSystem &&
        !operatingSystemVersion &&
        !antivirus &&
        !antivirusVersion &&
        !purchaseDate &&
        !purchasePrice &&
        !location
      ) {
      } else {
        var data = {
          accountId: user.accountId,
          name: name,
          type: type,
          ipAddress: ipAddress,
          manufacturer: manufacturer,
          model: model,
          serial: serial,
          cpuManufacturer: cpuManufacturer,
          cpuModel: cpuModel,
          cpuSpeed: cpuSpeed,
          ramSize: ramSize,
          ramType: ramType,
          graphicsCard: graphicsCard,
          macAddress: macAddress,
          hasInternetAccess: hasInternetAccess ?? null,
          backupsEnabled: backupsEnabled ?? null,
          defaultPasswordReset: defaultPasswordReset ?? null,
          pcAutoPlayDisabled: pcAutoPlayDisabled ?? null,
          automaticUpdatesEnabled: automaticUpdatesEnabled ?? null,
          automaticUpdatesLastChecked: automaticUpdatesLastChecked,
          dateLastAudited: dateLastAudited,
          operatingSystem: operatingSystem,
          operatingSystemVersion: operatingSystemVersion,
          antivirus: antivirus,
          antivirusVersion: antivirusVersion,
          purchaseDate: purchaseDate,
          purchasePrice: purchasePrice,
          location: location,
        }

        csvDevices = [...csvDevices, data]
      }
    })

    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/devices/csv`,
        { accountId: user.accountId, devices: csvDevices },
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setDeviceList(res.data)
      toast.success("Devices Added.")
      navigate("/devices")
    } catch (err) {
      console.log(err)
      toast.error("Oops something went wrong. Please try again.")
    } finally {
      setDevicesFile()
      setMapCSV(false)
      setMappedHeaders()
      setCSVheaders()
      setCSVData()
      setSubmitting(false)
    }
  }

  return (
    <div className=" p-4 ">
      {mapCSV ? (
        <div className="d-flex flex-column col-8">
          <h1 className="pb-4">Map CSV Headers</h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {mapHeaders.map((header) => {
              return (
                <div key={header} className="col-12">
                  <div className="d-flex justify-content-between col-12 mb-3">
                    <div className="d-flex align-items-center bold">
                      {header}
                    </div>
                    <div>
                      <Form.Select
                        value={
                          mappedHeaders
                            ? mappedHeaders[header]
                              ? mappedHeaders[header]
                              : "Not Mapped"
                            : "Not Mapped"
                        }
                        onChange={(e) => {
                          setField(header, e.target.value)
                        }}
                        required
                      >
                        <option value="Not Mapped">Not Mapped</option>
                        {csvHeaders &&
                          csvHeaders.map((csvHeader) => {
                            return (
                              <option key={csvHeader} value={csvHeader}>
                                {csvHeader}
                              </option>
                            )
                          })}
                      </Form.Select>
                    </div>
                  </div>
                  <hr />
                </div>
              )
            })}
          </div>
          <div className="d-flex align-self-end col-4">
            <button
              disabled={submitting}
              onClick={(e) => handleCancel(e)}
              className="btn btn-danger col-6 me-3"
            >
              Cancel
            </button>

            <button
              disabled={submitting}
              onClick={(e) => handleSubmit(e)}
              className="btn btn-primary col-6"
            >
              {!submitting ? (
                "Submit"
              ) : (
                <div>
                  Please wait{" "}
                  <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
                </div>
              )}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="pb-4">Devices</h1>
            </div>
            <div>
              <button
                className="btn btn-primary mb-3"
                onClick={() => navigate("./device/0")}
              >
                Add Device
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex col-10">
              <div className="col-3 me-2">
                <Form.Control
                  placeholder="Search devices..."
                  value={pagination.search}
                  onChange={(e) =>
                    setPagination((prev) => {
                      return { ...prev, search: e.target.value }
                    })
                  }
                />
              </div>
              <div className="col-2 me-2">
                <Form.Select
                  value={pagination.type}
                  onChange={(e) => {
                    setPagination((prev) => {
                      return { ...prev, type: e.target.value }
                    })
                    setPagination((prev) => {
                      return { ...prev, pageNumber: 1 }
                    })
                  }}
                  required
                >
                  <option value="">All Types</option>
                  {types &&
                    types.map((type) => {
                      return (
                        <option key={type.id} value={type.value}>
                          {type.text}
                        </option>
                      )
                    })}
                </Form.Select>
              </div>
              <div className="col-2 me-2">
                <Form.Select
                  value={pagination.status}
                  onChange={(e) => {
                    setPagination((prev) => {
                      return { ...prev, status: e.target.value }
                    })
                    setPagination((prev) => {
                      return { ...prev, pageNumber: 1 }
                    })
                  }}
                  required
                >
                  <option value="all">All Devices</option>
                  <option value="audited">Audited</option>
                  <option value="un-audited">Un-Audited</option>
                  <option value="monitored">Monitored</option>
                  <option value="compliant">Compliant</option>
                  <option value="non-compliant">Non-Compliant</option>
                </Form.Select>
              </div>
              <div className="col-2 me-2">
                <Form.Select
                  value={pagination.office}
                  onChange={(e) => {
                    setPagination((prev) => {
                      return { ...prev, office: e.target.value }
                    })
                    setPagination((prev) => {
                      return { ...prev, pageNumber: 1 }
                    })
                  }}
                  required
                >
                  <option value="">Any Office</option>
                  {offices &&
                    offices.map((office) => {
                      return (
                        <option key={office} value={office}>
                          {office}
                        </option>
                      )
                    })}
                </Form.Select>
              </div>
              <div className="col-1 me-2">
                <Form.Control
                  placeholder="Room..."
                  value={pagination.room}
                  onChange={(e) =>
                    setPagination((prev) => {
                      return { ...prev, room: e.target.value }
                    })
                  }
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Form.Group className="">
                <div>
                  <label
                    className="btn btn-primary hoverMe me-5"
                    htmlFor="input-file"
                    style={{ fontSize: 12 }}
                  >
                    Import Devices With CSV
                  </label>
                </div>
                <Form.Control
                  id="input-file"
                  type="file"
                  accept=".csv"
                  placeholder="name@example.com"
                  onChange={(e) => {
                    setDevicesFile(e.target.files[0])
                  }}
                  style={{ display: "none" }}
                />
              </Form.Group>
              <a
                href={require("../Components/example.csv")}
                download={exampleFile}
              >
                <label className="hoverMe me-5" style={{ fontSize: 12 }}>
                  example.csv
                </label>
              </a>
            </div>
          </div>

          <div
            style={{ marginTop: -18 }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <DeviceTable
              deviceList={deviceList}
              setDeviceList={setDeviceList}
              pagination={pagination}
              setPagination={setPagination}
              setTotalCount={setTotalCount}
            />
          </div>

          <TablePageSelect
            classes="mt-3"
            pageNumber={pagination.pageNumber}
            setPagination={setPagination}
            totalCount={totalCount}
            pagination={pagination}
          />
        </>
      )}
    </div>
  )
}
