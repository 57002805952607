import CloseButton from "./CloseButton"
import WelcomeCarousel from "./WelcomeCarousel"

export default function ModalContent() {
  return (
    <div >
      <div className="d-flex justify-content-end">
        <CloseButton />
      </div>

      <WelcomeCarousel />
    </div>
  )
}
