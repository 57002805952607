import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircle,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import useLocalStorage from "use-local-storage"
import TablePageSelect from "../../../Components/Table/TablePageSelect"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { locale } from "../../../Config/Constants"
import Spinner from "../../../Components/Loading/Spinner"

export default function EmployeesHome() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [employeeList, setEmployeeList] = useState([])
  const [loading, setLoading] = useState(true)

  //Paginations
  const [pagination, setPagination] = useLocalStorage("employeesSearch", {
    status: "all",
    pageNumber: 1,
    sort: "name-d",
    search: "",
    type: "",
    pageSize: 10,
  })

  useEffect(() => {
    if (location.state) {
      setPagination((prev) => {
        return { ...prev, status: location.state }
      })
    }
  }, [location.state])

  const [totalCount, setTotalCount] = useState(0)

  const listEmployees = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/employees/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&status=${pagination.status}&pageNumber=${pagination.pageNumber}&sort=${pagination.sort}&search=${pagination.search}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setEmployeeList(res.data.employees)
      setTotalCount(res.data.count)
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listEmployees()
  }, [user, pagination])

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="pb-4">Employees</h1>
        </div>
        <div>
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate("./employee/0")}
          >
            Add Employee
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex col-10">
          <div className="col-4 me-2">
            <Form.Control
              placeholder="Search employees..."
              value={pagination.search}
              onChange={(e) =>
                setPagination((prev) => {
                  return { ...prev, search: e.target.value }
                })
              }
            />
          </div>
          <div className="col-3 me-2">
            <Form.Select
              value={pagination.status}
              onChange={(e) => {
                setPagination((prev) => {
                  return { ...prev, status: e.target.value }
                })
                setPagination((prev) => {
                  return { ...prev, pageNumber: 1 }
                })
              }}
              required
            >
              <option value="all">All Employees</option>
              <option value="trained">Trained</option>
              <option value="un-trained">Un-Trained</option>
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless ">
          <thead
            className="bold"
            style={{ borderBottom: "1px solid var(--secondary)" }}
          >
            <tr>
              <td
                className="hoverMe"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort: pagination.sort === "name-a" ? "name-d" : "name-a",
                    }
                  })
                }
              >
                NAME
                {pagination.sort === "name-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "name-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td>EMAIL</td>
              {/* <td>STATUS</td> */}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center py-3">
                  <Spinner />
                </td>
              </tr>
            ) : employeeList.length > 0 ? (
              employeeList.map((employee) => {
                return (
                  <tr key={employee.id}>
                    <td
                      className="hoverMe"
                      onClick={() => navigate(`./view_employee/${employee.id}`)}
                    >
                      {employee.name}
                    </td>
                    <td
                      className="hoverMe"
                      onClick={() => navigate(`./view_employee/${employee.id}`)}
                    >
                      {employee.email}
                    </td>
                    {/* <td
                      className="hoverMe"
                      onClick={() => navigate(`./view_employee/${employee.id}`)}
                    >
                      {employee.status ? (
                        <FontAwesomeIcon
                          style={{
                            height: "15px",
                            width: "15px",
                          }}
                          icon={faCircle}
                          className="text-danger ms-4"
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            height: "15px",
                            width: "15px",
                          }}
                          icon={faCircle}
                          className="text-success ms-4"
                        />
                      )}
                    </td> */}
                    <td className="d-flex justify-content-center">
                      <button
                        style={{ fontSize: 14, width: 100, borderRadius: 6 }}
                        className="btn btn-primary"
                        onClick={() => navigate(`./employee/${employee.id}`)}
                      >
                        EDIT
                      </button>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center py-3">
                  No results...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <TablePageSelect
        pageNumber={pagination.pageNumber}
        setPagination={setPagination}
        totalCount={totalCount}
        pagination={pagination}
      />
    </div>
  )
}
