import axios from "axios"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../Hooks/useAuthContext"
import "./ViewDevice.css"
import ViewDeviceRow from "./Components/View/ViewDeviceRow"
import HddList from "./Components/View/HddList"
import SoftwareList from "./Components/View/SoftwareList"
import CloneDevice from "./Components/View/CloneDevice"
import DataSetList from "./Components/View/DataSetList"
import { locale } from "../../Config/Constants"
import DeviceViewIcon from "./Components/View/DeviceViewIcon"
import { toast } from "react-toastify"

export default function ViewDevice() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const params = useParams()
  const [device, setDevice] = useState({})
  const [loading, setLoading] = useState(true)

  const checkDate = new Date()
  checkDate.setDate(checkDate.getDate() - 3)

  useEffect(() => {
    const getDevices = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/devices/${params.id}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setDevice(res.data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    getDevices()
  }, [params.id])

  const handleDelete = async (deviceId) => {
    try {
      const res = await axios.delete(
        `https://${process.env.REACT_APP_IP}/api/devices/${deviceId}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      navigate("../")
      toast.success("Device deleted.")
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data)
      } else {
        toast.error("Oops something went wrong. Please try again.")
      }
      console.log(error)
    }
  }

  if (!loading) {
    return (
      <div className="p-4">
        <div className="d-flex col-12">
          <div className="d-flex flex-column col-7">
            <div className="d-flex justify-content-between align-content-center mb-3">
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <h1>{device.name}</h1>
                  <p>{device.ipAddress}</p>
                </div>
                <div className="d-flex align-items-baseline ms-5 mt-1 gap-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate(`../device/${device.id}`)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(device.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>

            {device.type === "Server" && (
              <div className="mb-4">
                <ViewDeviceRow
                  label={"Virtual Server?"}
                  content={device.virtualServer ? "Yes" : "No"}
                />
              </div>
            )}

            <div className="mb-4">
              <ViewDeviceRow
                label={"Manufacturer"}
                content={
                  device.manufacturer ? device.manufacturer : "Not Found"
                }
              />
              <ViewDeviceRow
                label={"Model"}
                content={device.model ? device.model : "Not Found"}
              />
              <ViewDeviceRow
                label={"Serial"}
                content={device.serial ? device.serial : "Not Found"}
              />
            </div>

            <div className="mb-4">
              {(device.type === "Laptop" ||
                device.type === "Desktop" ||
                device.type === "Server") && (
                <>
                  <ViewDeviceRow
                    label={"Processor"}
                    content={
                      device.cpuModel && device.cpuModel.includes("GHz")
                        ? device.cpuModel
                        : device.cpuManufacturer
                        ? device.cpuModel
                          ? device.cpuSpeed
                            ? device.cpuManufacturer +
                              " - " +
                              device.cpuModel +
                              " @ " +
                              device.cpuSpeed +
                              "GHz"
                            : ""
                          : ""
                        : "Not Found"
                    }
                  />
                  <ViewDeviceRow
                    label={"Memory"}
                    content={
                      device.ramSize
                        ? device.ramType
                          ? device.ramSize + "Gb " + device.ramType
                          : device.ramSize + "unkown type"
                        : "Not Found"
                    }
                  />
                  <ViewDeviceRow
                    label={"Graphics Card"}
                    content={
                      device.graphicsCard ? device.graphicsCard : "Not Found"
                    }
                  />
                </>
              )}
              <ViewDeviceRow
                label={"MAC Address"}
                content={device.macAddress ? device.macAddress : "Not Found"}
              />
            </div>

            <div className="mb-4">
              <ViewDeviceRow
                label={"Has Internet Access ?"}
                content={device.hasInternetAccess ? "Yes" : "No"}
              />
              <ViewDeviceRow
                label={"Backups Enabled ?"}
                content={device.backupsEnabled ? "Yes" : "No"}
                style={
                  !device.backupsEnabled
                    ? {
                        backgroundColor: "darkOrange",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
              <ViewDeviceRow
                label={"Default Password Reset ?"}
                content={device.defaultPasswordReset ? "Yes" : "No"}
                style={
                  !device.defaultPasswordReset
                    ? {
                        backgroundColor: "red",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
              {device.type !== "Server" && (
                <ViewDeviceRow
                  label={"Auto Play Disabled ?"}
                  content={device.pcAutoPlayDisabled ? "Yes" : "No"}
                  style={
                    !device.pcAutoPlayDisabled
                      ? {
                          backgroundColor: "darkOrange",
                          color: "white",
                          border: "1px solid var(--secondary)",
                        }
                      : {}
                  }
                />
              )}
              <ViewDeviceRow
                label={"Automatic Updates Enabled ?"}
                content={device.automaticUpdatesEnabled ? "Yes" : "No"}
                style={
                  !device.automaticUpdatesEnabled
                    ? {
                        backgroundColor: "red",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
              <ViewDeviceRow
                label={"Automatic Updates Last Checked"}
                content={
                  device.automaticUpdatesLastChecked
                    ? new Date(
                        device.automaticUpdatesLastChecked
                      ).toLocaleDateString(locale)
                    : "Not Found"
                }
                style={
                  !device.automaticUpdatesLastChecked ||
                  device.automaticUpdatesLastCheckedOutOfDate
                    ? {
                        backgroundColor: "red",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
            </div>

            <div className="mb-4">
              <ViewDeviceRow
                label={"Date Last Audited"}
                content={
                  device.isMonitored
                    ? new Date(device.dateLastAudited) > new Date(checkDate)
                      ? "Monitored"
                      : device.dateLastAudited
                      ? new Date(device.dateLastAudited).toLocaleDateString()
                      : "Un-Audited"
                    : device.dateLastAudited
                    ? new Date(device.dateLastAudited).toLocaleDateString()
                    : "Un-Audited"
                }
                style={
                  device.status === "Attention Required"
                    ? {
                        backgroundColor: "red",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
              <ViewDeviceRow
                label={"Date Last Detected"}
                content={
                  new Date(device.dateLastDetected).toDateString() ===
                  new Date().toDateString()
                    ? "Today"
                    : device.dateLastAudited
                    ? new Date(device.dateLastDetected).toLocaleDateString()
                    : "Not Detected"
                }
              />
            </div>

            <div className="mb-5">
              <ViewDeviceRow
                label={"Operating System"}
                content={
                  device.operatingSystem
                    ? device.operatingSystemVersion
                      ? device.operatingSystem +
                        " - " +
                        device.operatingSystemVersion
                      : device.operatingSystem
                    : "Not Found"
                }
                style={
                  !device.operatingSystem
                    ? {
                        backgroundColor: "darkOrange",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
              <ViewDeviceRow
                label={"Anti-Virus"}
                content={
                  device.antivirus
                    ? device.antivirusVersion
                      ? device.antivirus + " - " + device.antivirusVersion
                      : device.antivirus
                    : "Not Found"
                }
                style={
                  !device.antivirus
                    ? {
                        backgroundColor: "red",
                        color: "white",
                        border: "1px solid var(--secondary)",
                      }
                    : {}
                }
              />
            </div>
          </div>
          <div className="col-1" />
          <div className="d-flex flex-column col-4">
            <div className="mb-5">
              <DeviceViewIcon device={device} />
            </div>
            <div className="ms-3">
              <div className="mb-3">
                <div className="d-flex flex-column flex-md-row">
                  <div className="col col-md-6 ms-2">Date Purchased</div>
                  <div className="col col-md-6 ms-2">
                    {device.purchaseDate
                      ? new Date(device.purchaseDate).toLocaleDateString(locale)
                      : "Not Found"}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex flex-column flex-md-row">
                  <div className="col col-md-6 ms-2">Purchase Price (£)</div>
                  <div className="col col-md-6 ms-2">
                    {device.purchasePrice
                      ? "£ " + device.purchasePrice
                      : "Not Found"}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex flex-column flex-md-row">
                  <div className="col col-md-6 ms-2">
                    Location (Office - Room)
                  </div>
                  <div className="col col-md-6 ms-2">
                    {device.office
                      ? device.room
                        ? device.office + " - " + device.room
                        : device.office + " - Not Found"
                      : device.room
                      ? "Not Found - " + device.room
                      : "Not Found - Not Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(device.type === "Laptop" ||
          device.type === "Desktop" ||
          device.type === "Server") && (
          <>
            <div className="mb-4">
              <HddList hdds={device.hdds} />
            </div>
            <div className="mb-4">
              <SoftwareList softwareList={device.software} />
            </div>
            <DataSetList instances={device.instances} />
          </>
        )}
        <CloneDevice id={params.id} />
      </div>
    )
  } else {
    return <h1>Loading...</h1>
  }
}
