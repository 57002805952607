import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function fact6() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact6 text-white">
            Reports suggest there are <span className="fact6Span">2200 </span>
            cyber attacks per day, with an attack happening every 39 seconds on
            average
          </h2>
          <p className="fact-reference text-white"> - getastra.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          You should always install the latest security updates for your
          devices. Keep on top of this by turning on automatic updates where
          possible and by using cyber essentials to inform you when a piece of
          software is out of date.
        </h3>
      </div>
    </div>
  )
}
