import axios from "axios"
import React, { useState } from "react"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import "./Activate.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function Activate() {
  const params = useParams()
  const navigate = useNavigate()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const activateUser = async () => {
      if (params.id !== null && params.code !== null) {
        try {
          const res = await axios.post(
            `https://${process.env.REACT_APP_IP}/api/users/activate/${params.code}`
          )
          if (res.status === 200) {
            setSuccess(true)
            setTimeout(navigate, 6000, "../login")
          }
        } catch (err) {
          setError(true)
        }
      }
    }

    activateUser()
  }, [params])

  return (
    <div className=" activateMainCon d-flex flex-column justify-content-center  align-items-center">
      <div className="activateInnerCon primaryShadow p-5 d-flex flex-column justify-content-center  align-items-center">
        <div className="p-5 mb-5">
          <h1>LOGO</h1>
        </div>
        {!success && !error && (
          <div className="p-3  activateMessageCon ">
            <h1 className="mb-2">Confirming your email address.</h1>
            <hr />
            <div className=" py-3 d-flex flex-column align-items-center">
              <h4 className="text-center ">Please wait.</h4>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="2x"
                className="mt-2"
              />
            </div>
          </div>
        )}
        {success && (
          <div className="p-3 activateMessageCon ">
            <h1 className="mb-2">Success! Your Account is now active</h1>
            <hr />
            <div className=" py-3 d-flex flex-column align-items-center">
              <h4 className="text-center">
                Redirecting you to{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/login")}
                >
                  login.
                </a>
              </h4>
            </div>
          </div>
        )}
        {error && (
          <div className="p-3 activateMessageCon ">
            <h1 className="mb-2">Ooops something went wrong.</h1>
            <hr />
            <div className=" py-3 d-flex flex-column align-items-center">
              <h4 className="text-center">
                Please try again or{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/resend_confirmation")}
                >
                  request a new confirmation email.
                </a>
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
