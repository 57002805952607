import axios from "axios"
//Bootstrap
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
//React
import { useState, useEffect } from "react"
//Custom Styles
import "./LoginForm.css"
import SubmitButton from "../../../Components/SubmitButton"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function LoginForm({ setUser }) {
  const navigate = useNavigate()
  const [form, setForm] = useState({ rememberMe: false })
  const [formErrors, setFormErrors] = useState("")
  const [loginErr, setLoginErr] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const login = async (data) => {
    setSubmitting(true)

    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/users/login/`,
        data
      )
      setUser(res.data)
    } catch (err) {
      console.log(err)
      if (err.response.status === 400) {
        setLoginErr(err.response.data[0])
      }
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const findFormErrors = () => {
      const { email, password } = form

      const newErrors = {}

      // email errors
      if (email !== undefined) {
        if (email === "") {
          newErrors.email = "Email is required"
        } else {
          newErrors.email = ""
        }
      }

      if (password !== undefined) {
        if (password === "") {
          newErrors.password = "Password is required"
        } else {
          newErrors.password = ""
        }
      }

      setFormErrors(newErrors)
    }
    findFormErrors()
  }, [form])

  const setField = (field, value) => {
    setLoginErr("")
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!form.email) {
      setFormErrors((prev) => {
        return { ...prev, email: "Email is required" }
      })
    }
    if (!form.password) {
      console.log(true)
      setFormErrors((prev) => {
        return { ...prev, password: "Password is required" }
      })
    }
    if (formErrors.email === "" && formErrors.password === "") {
      login(form)
    }
  }

  return (
    <div>
      <Form onSubmit={(e) => handleSubmit(e)} className="mb-3">
        <Form.Group className="d-flex flex-column col-12">
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              isInvalid={formErrors.email}
              onChange={(e) => setField("email", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type="password"
              placeholder="Password"
              isInvalid={formErrors.password}
              onChange={(e) => setField("password", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.password}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <div className="loginErr mt-3 mb-1 text-center">
          {loginErr && (
            <span className="loginErr mb-3 text-center text-dark">
              {loginErr}
            </span>
          )}
        </div>
        <Form.Group className="d-flex align-items-center col-12 mt-3">
          {/* <Form.Check
            className="text-black"
            type="checkbox"
            label={`Stay logged in?`}
            onChange={(e) => setField("rememberMe", e.target.checked)}
          /> */}
          <div
            style={{ padding: 0 }}
            className=" d-flex justify-content-center col-12 text-center"
          >
            <button
              disabled={submitting}
              type="submit"
              className="btn btn-primary col-12 "
            >
              {!submitting ? (
                "Login"
              ) : (
                <div>
                  Please wait{" "}
                  <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
                </div>
              )}
            </button>
          </div>
        </Form.Group>
      </Form>

      <div className="d-flex flex-column align-items-center">
        <div className="d-flex mb-3">
          Forgot Password?
          <Link to={"../forgot_password"} className="ms-3">
            Click to reset
          </Link>
        </div>

        <Link to={"../resend_confirmation"} className="">
          Confirmation email not arrived?
        </Link>
      </div>
    </div>
  )
}
