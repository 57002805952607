import React from "react"
import RegisterForm from "../Components/RegisterForm"
import LeftCon from "../Components/LeftCon"
import RightCon from "../Components/RightCon"
import LandingHeading from "../Components/LandingHeading"

export default function Register() {
  return (
    <div className="min-vh-100 d-flex">
      <LeftCon>
        <LandingHeading text={"Create New Account"} />
        <hr />
        <RegisterForm />
      </LeftCon>

      <RightCon />
    </div>
  )
}
