import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircle,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import useLocalStorage from "use-local-storage"
import TablePageSelect from "../../../Components/Table/TablePageSelect"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { locale } from "../../../Config/Constants"
import Spinner from "../../../Components/Loading/Spinner"

export default function IncidentReportsHome() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [incidentReportList, setIncidentReportList] = useState([])
  const [types, setTypes] = useState([])
  const bundle = "Incident Report Type"
  const [loading, setLoading] = useState(true)

  //Paginations
  const [pagination, setPagination] = useLocalStorage("incidentReportSearch", {
    status: "all",
    pageNumber: 1,
    sort: "title-d",
    search: "",
    type: "",
    pageSize: 10,
  })

  useEffect(() => {
    if (location.state) {
      setPagination((prev) => {
        return { ...prev, status: location.state }
      })
    }
  }, [location.state])

  useEffect(() => {
    const getTypes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/selectListOptions/${bundle}/listoptions`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        if (res.status === 200) {
          setTypes(res.data)
        }
      } catch (err) {
        console.log(err.response)
      }
    }

    getTypes()
  }, [])

  const [totalCount, setTotalCount] = useState(0)

  const listIncidentReports = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_IP}/api/incidentreports/listbyaccount/${user.accountId}?pageSize=${pagination.pageSize}&status=${pagination.status}&pageNumber=${pagination.pageNumber}&sort=${pagination.sort}&search=${pagination.search}`,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      setIncidentReportList(res.data.reports)
      setTotalCount(res.data.count)
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listIncidentReports()
  }, [user, pagination])

  return (
    <div className="p-4">
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div>
          <h1 className="pb-4">Incident Reports</h1>
        </div>

        <div>
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate("./incident_report/0")}
          >
            Add Incident Report
          </button>
        </div>
      </div>

      <div className="d-flex mb-2">
        <div className="col-3 me-2">
          <Form.Control
            placeholder="Search incident reports..."
            value={pagination.search}
            onChange={(e) =>
              setPagination((prev) => {
                return { ...prev, search: e.target.value }
              })
            }
          />
        </div>
        <div className="col-2 me-2">
          <Form.Select
            value={pagination.status}
            onChange={(e) => {
              setPagination((prev) => {
                return { ...prev, status: e.target.value }
              })
              setPagination((prev) => {
                return { ...prev, pageNumber: 1 }
              })
            }}
            required
          >
            <option value="all">All Reports</option>
            {types &&
              types.map((type) => {
                return (
                  <option key={type.id} value={type.value}>
                    {type.text + " Reports"}
                  </option>
                )
              })}
          </Form.Select>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <table className="table table-borderless ">
          <thead
            className="bold"
            style={{ borderBottom: "1px solid var(--secondary)" }}
          >
            <tr>
              <td
                className="hoverMe col-2"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort:
                        pagination.sort === "title-a" ? "title-d" : "title-a",
                    }
                  })
                }
              >
                TITLE
                {pagination.sort === "title-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "title-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td className="col-2">TYPE</td>
              <td className="col-2">NO. OF DEVICES</td>
              <td className="col-2">TIME / COST TO REPAIR</td>
              <td
                className="hoverMe col-2"
                onClick={() =>
                  setPagination((prev) => {
                    return {
                      ...prev,
                      sort: pagination.sort === "date-a" ? "date-d" : "date-a",
                    }
                  })
                }
              >
                DATE OCCURRED
                {pagination.sort === "date-a" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                )}
                {pagination.sort === "date-d" && (
                  <FontAwesomeIcon className="ms-2" icon={faCaretUp} />
                )}
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-3">
                  <Spinner />
                </td>
              </tr>
            ) : incidentReportList.length > 0 ? (
              incidentReportList.map((incidentReport) => (
                <tr key={incidentReport.id} className="tableRow">
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_incident_Report/${incidentReport.id}`)
                    }
                  >
                    {incidentReport.incidentTitle}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_incident_Report/${incidentReport.id}`)
                    }
                  >
                    {incidentReport.incidentType}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_incident_Report/${incidentReport.id}`)
                    }
                  >
                    {incidentReport.totalDevicesInvolved}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_incident_Report/${incidentReport.id}`)
                    }
                  >
                    {incidentReport.repairTime +
                      "hrs / £" +
                      incidentReport.repairCost}
                  </td>
                  <td
                    className="hoverMe"
                    onClick={() =>
                      navigate(`./view_incident_Report/${incidentReport.id}`)
                    }
                  >
                    {incidentReport.dateOccurred
                      ? new Date(
                          incidentReport.dateOccurred
                        ).toLocaleDateString(locale)
                      : "N/A"}
                  </td>
                  <td className="d-flex justify-content-center">
                    <button
                      style={{ fontSize: 14, width: 100, borderRadius: 6 }}
                      className="btn btn-primary"
                      onClick={() =>
                        navigate(`./incident_report/${incidentReport.id}`)
                      }
                    >
                      EDIT
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-3">
                  No results...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TablePageSelect
        pageNumber={pagination.pageNumber}
        setPagination={setPagination}
        totalCount={totalCount}
        pagination={pagination}
      />
    </div>
  )
}
