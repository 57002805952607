import axios from "axios"
import React from "react"
import { useState, useEffect } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import { toast } from "react-toastify"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function EditAccount({
  account,
  setAccount,
  receivedAccount,
  setReceivedAccount,
  logo,
  setLogo,
}) {
  const { user } = useAuthContext()

  const [submitting, setSubmitting] = useState(false)
  const [imgByteArray, setImgByteArray] = useState()
  const [logoURL, setLogoURL] = useState()

  const [formErrors, setFormErrors] = useState({})

  const updateAccount = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      //Convert img byte array into base64 to be passed into the api
      const base64Data = window.btoa(
        new Uint8Array(imgByteArray).reduce(function (data, byte) {
          return data + String.fromCharCode(byte)
        }, "")
      )

      const accountInfo = {
        name: account.name,
        address: account.address,
        email: account.email,
        telephone: account.telephone,
        postCode: account.postCode,
        logoBase64: base64Data,
        turnoverQ1: account.turnoverQ1,
        turnoverQ2: account.turnoverQ2,
        turnoverQ3: account.turnoverQ3,
      }

      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/accounts/${user.accountId}`,
        accountInfo,
        {
          headers: { Authorization: `bearer ${user.token}` },
        }
      )
      if (res.status === 200) {
        toast.success("Account updated")
        setAccount(res.data)
        setReceivedAccount(res.data)
      }
    } catch (err) {
      console.log(err)
      if (err && err.response.data === "User Exists") {
        setFormErrors((prev) => {
          return { ...prev, email: "Email already exists" }
        })
      }
    } finally {
      setSubmitting(false)
    }
  }

  const setField = (field, value) => {
    setAccount({
      ...account,
      [field]: value,
    })
  }

  const generateImgURL = (byteArray) => {
    setImgByteArray(byteArray)
    //generate img blob using img byte array
    const blob = new Blob([byteArray], {
      type: "image/png",
    })
    //generate url to view the img
    setLogoURL(window.URL.createObjectURL(blob))
  }

  useEffect(() => {
    if (logo) {
      //get img blob for logo
      const imgBlob = new Blob([logo], { type: "image/png" })
      //setup file reader to retrieve byte array from img blob
      const fileReader = new FileReader()
      fileReader.onload = function () {
        const arrayBuffer = this.result
        const byteArray = new Uint8Array(arrayBuffer)
        //pass img byte array generated from blob to 'generateImgURL'
        generateImgURL(byteArray)
      }
      fileReader.readAsArrayBuffer(imgBlob)
    }
  }, [logo])

  return (
    <div className="p-4">
      <h2 className="mb-3">Edit Company</h2>
      <Form onSubmit={updateAccount}>
        <Form.Group controlId="validationCustom01">
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control
              defaultValue={account.name ?? ""}
              required
              type="text"
              placeholder="Enter Name"
              isInvalid={formErrors.name}
              isValid={formErrors.name === ""}
              onChange={(e) => setField("name", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.name}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Email" className="mb-3">
            <Form.Control
              defaultValue={account.email ?? ""}
              required
              type="email"
              placeholder="name@example.com"
              isInvalid={formErrors.email}
              isValid={formErrors.email === ""}
              onChange={(e) => setField("email", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Telephone" className="mb-3">
            <Form.Control
              defaultValue={account.telephone ?? ""}
              type="tel"
              placeholder="name@example.com"
              isInvalid={formErrors.telephone}
              isValid={formErrors.telephone === ""}
              onChange={(e) => setField("telephone", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.telephone}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Address" className="mb-3">
            <Form.Control
              defaultValue={account.address ?? ""}
              required
              type="text  "
              placeholder="name@example.com"
              isInvalid={formErrors.address}
              isValid={formErrors.address === ""}
              onChange={(e) => setField("address", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.address}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <FloatingLabel label="Postcode" className="mb-3">
            <Form.Control
              defaultValue={account.postCode ?? ""}
              type="text  "
              placeholder="name@example.com"
              isInvalid={formErrors.postCode}
              isValid={formErrors.postCode === ""}
              onChange={(e) => setField("postCode", e.target.value)}
            />
            <Form.Control.Feedback className="text-danger" type="invalid">
              {formErrors.postCode}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="d-flex mt-4 mb-2">
          <Form.Label className="col">
            Is your head office domiciled in the UK or Crown Dependencies and is
            your gross annual turnover less than £20m?
          </Form.Label>
          <div className="">
            <Form.Check
              onChange={(e) => setField("turnoverQ1", e.target.checked)}
              checked={account.turnoverQ1 ?? false}
            />
          </div>
        </Form.Group>

        {account.turnoverQ1 && (
          <div className="d-flex justify-content-center bold mt-3 mb-3">
            Did you know you can apply for free cyber security insurance?
          </div>
        )}

        {account.turnoverQ1 && (
          <>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col col-8">
                What is your total gross revenue? Please provide figure to the
                nearest £100K. You only need to answer this question if you are
                taking the insurance.
              </Form.Label>
              <div className="col">
                <Form.Control
                  defaultValue={account.turnoverQ2 ?? ""}
                  type="number"
                  onChange={(e) => setField("turnoverQ2", e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col col-8">
                What is the organisation email contact for the insurance
                documents? You only need to answer this question if you are
                taking the insurance.
              </Form.Label>
              <div className="col">
                <Form.Control
                  defaultValue={account.turnoverQ3 ?? ""}
                  type="email"
                  onChange={(e) => setField("turnoverQ3", e.target.value)}
                />
              </div>
            </Form.Group>
          </>
        )}

        <Form.Group>
          <div>
            <label
              className="btn btn-primary hoverMe me-5"
              htmlFor="input-file"
            >
              Select Logo
            </label>
            {logoURL && <img height={75} width="auto" src={logoURL} />}
          </div>
          <Form.Control
            defaultValue={account.logo ?? ""}
            id="input-file"
            type="file"
            placeholder="name@example.com"
            isInvalid={formErrors.logo}
            isValid={formErrors.logo === ""}
            onChange={(e) => {
              setLogo(e.target.files[0])
              setField("logoBase64", e.target.files[0])
            }}
            style={{ display: "none" }}
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-end align-items-center mt-3">
          <button
            disabled={submitting || receivedAccount === account}
            type="submit"
            className="btn btn-primary"
          >
            {!submitting ? (
              "Submit"
            ) : (
              <div>
                Please wait{" "}
                <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
              </div>
            )}
          </button>
        </Form.Group>
      </Form>
    </div>
  )
}
