import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact9() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact9 text-white">
            <span className="fact9Span">41%</span> of companies have experienced
            a ransomware attack in the past year
          </h2>
          <p className="fact-reference text-white"> - truefort.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          You should always have a disaster recovery plan in place and back up
          all data following a simple rule such as the 3-2-1 backup rule to
          ensure that operations can continue even in the event of a ransomware
          attack. Using the 3-2-1 rule, you will keep three copies of your data
          on two different types of media (local and external hard drive) and
          one copy in an off-site location (cloud storage).
        </h3>
      </div>
    </div>
  )
}
