import axios from "axios"
import React from "react"
import { useEffect, useState } from "react"
import { ToastContainer } from "react-toastify"
import { useAuthContext } from "../../Hooks/useAuthContext"
import EditAccount from "./Components/EditAccount"
import EditSelectListOptions from "./Components/EditSelectListOptions"
import EditUsers from "./Components/EditUsers"
import { Link } from "react-router-dom"

export default function ManageAccount() {
  const { user } = useAuthContext()
  const [account, setAccount] = useState({})
  const [receivedAccount, setReceivedAccount] = useState()
  const [loading, setLoading] = useState(true)
  const [logo, setLogo] = useState()

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/accounts/${user.accountId}`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        setAccount(res.data)
        setReceivedAccount(res.data)
        if (res.data.logoBase64) {
          var byteArray = base64ToArrayBuffer(res.data.logoBase64)
          setLogo(byteArray)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    getAccountDetails()
  }, [user])

  const base64ToArrayBuffer = (base64) => {
    //Convert received base64 string into a byte array
    const binaryString = window.atob(base64)
    const bytes = new Uint8Array(binaryString.length)
    return bytes.map((byte, i) => binaryString.charCodeAt(i))
  }

  return (
    <>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center mb-2">
            Company Compliance:
            <Link
              className="ms-3"
              to={`../company-compliance/${account.accessCode}`}
            >{`https://cybertrackit.co.uk/company-compliance/${account.accessCode}`}</Link>
          </div>
          <div className="d-flex justify-content-center mb-2">
            Company Summary:
            <Link
              className="ms-3"
              to={`../company-summary/${account.accessCode}`}
            >{`https://cybertrackit.co.uk/company-summary/${account.accessCode}`}</Link>
          </div>
          <div className="d-flex col-12 mx-5">
            <div className="col-5">
              <EditAccount
                account={account}
                setAccount={setAccount}
                receivedAccount={receivedAccount}
                setReceivedAccount={setReceivedAccount}
                logo={logo}
                setLogo={setLogo}
              />
            </div>
            <div className="col-1" />
            <div className="col-5">
              <EditUsers />
            </div>
            <ToastContainer />
          </div>
        </div>
      )}
    </>
  )
}
