import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "use-local-storage"
import Spinner from "../../Components/Loading/Spinner"
import { useAuthContext } from "../../Hooks/useAuthContext"
import DataSetPie from "./Components/Charts/DataSetPie"
import Modal from "../../Components/Modals/Modal"
import DeviceStatus from "./Components/DeviceStatus"
import EmployeeStatus from "./Components/EmployeeStatus"
import ModalContent from "./Components/WelcomeTutorial/ModalContent"

export default function Home({ theme }) {
  const { user } = useAuthContext()
  const [account, setAccount] = useState({})
  const [greet, setGreet] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getAccount = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/accounts/${user.accountId}/overview`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )

        setAccount(res.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    getAccount()
  }, [user])

  useEffect(() => {
    const hrs = new Date().getHours()
    if (hrs < 12) setGreet("Good Morning")
    else if (hrs >= 12 && hrs <= 17) setGreet("Good Afternoon")
    else if (hrs >= 17 && hrs <= 24) setGreet("Good Evening")
  }, [])

  console.log(account, "ACC")

  if (!loading) {
    return error ? (
      <h1>Ooops something went wrong. Please try again.</h1>
    ) : (
      <div>
        <div className="d-flex justify-content-between my-2">
          <h3 className="py-3">
            {greet} {user.name}
          </h3>
          <h3 className="py-3">{account.name}</h3>
        </div>
        <div className="d-flex justify-content-center flex-column flex-md-row gap-4">
          <div className="col-md-4 primaryShadow">
            {account.trainedEmployees > 0 || account.unTrainedEmployees > 0 ? (
              <EmployeeStatus
                account={account}
                theme={theme}
                navigate={navigate}
              />
            ) : (
              <div className="p-3 ">
                <h4 className="text-center mb-3">Employees </h4>
                <h5 className="py-5 text-center">
                  Add employees to see statistics.
                </h5>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/employees")}
                  >
                    Go to employees
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 primaryShadow">
            {account.auditedDevices > 0 || account.unAuditedDevices > 0 ? (
              <DeviceStatus
                theme={theme}
                navigate={navigate}
                account={account}
              />
            ) : (
              <div className="p-3 ">
                <h4 className="text-center mb-3">Devices </h4>
                <h5 className="py-5 text-center">
                  Add devices to see statistics
                </h5>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/devices")}
                  >
                    Go to devices
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-column   col col-md-3 gap-4 ">
            {account.datasets.length > 0 ? (
              <DataSetPie
                theme={theme}
                datasets={account.datasets}
                navigate={navigate}
              />
            ) : (
              <div className="p-3 primaryShadow">
                <h4 className="text-center mb-3">Data Sets </h4>
                <h5 className="py-5 text-center">
                  Add data sets to see statistics
                </h5>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/data_sets")}
                  >
                    Go to data sets
                  </button>
                </div>
              </div>
            )}

            <div className="primaryShadow p-3">
              <h1 className="text-center">{account.cloudServices.length}</h1>
              <h4 className="text-center my-3">
                Cloud
                {account.cloudServices.length !== 1 ? " Services" : " Service"}
              </h4>

              <div className="text-center mb-4">in use</div>
              <div style={{ overflow: "auto", maxHeight: "235px" }}></div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/cloud_services")}
                >
                  Go There
                </button>
              </div>
            </div>
          </div>
        </div>
        {!user.hasSeenTutorial && (
          <Modal>
            <ModalContent />
          </Modal>
        )}
      </div>
    )
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "70vh" }}
      >
        <Spinner size="3x">
          <h1>Loading</h1>
        </Spinner>
      </div>
    )
  }
}
