import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditDataSet from "../DataSet/CreateEditDataSet"
import ViewDataSet from "../DataSet/ViewDataSet"
import DataSetsHome from "./Pages/DataSetsHome"

export default function DataSets() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<DataSetsHome />} />
        <Route path="/data_set/:id" element={<CreateEditDataSet />} />
        <Route path="/view_data_set/:id" element={<ViewDataSet />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}
