import { faMobile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact3() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact3 text-white">
            <span className="fact3Span">97%</span> of companies have faced
            cyberattacks involving mobile threats
          </h2>
          <p className="fact-reference text-white"> - purplesec.us</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faMobile} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          Set up a private guest network for visitors and mobile devices to keep
          them off your network. Unmonitored devices should never be allowed on
          your network, as they can easily introduce viruses to the network
          which can then spread to all other devices within it.
        </h3>
      </div>
    </div>
  )
}
