import React from "react"
import { ToastContainer } from "react-toastify"

import { Route, Routes } from "react-router-dom"
import CreateEditTrainingCourse from "../TrainingCourse/CreateEditTrainingCourse"
import ViewTrainingCourse from "../TrainingCourse/ViewTrainingCourse"
import TrainingCoursesHome from "./Pages/TrainingCoursesHome"

export default function TrainingCourses() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<TrainingCoursesHome />} />
        <Route
          path="/training_course/:id"
          element={<CreateEditTrainingCourse />}
        />
        <Route
          path="/view_training_course/:id"
          element={<ViewTrainingCourse />}
        />
      </Routes>
      <ToastContainer />
    </div>
  )
}
