import axios from "axios"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { FloatingLabel, Form } from "react-bootstrap"
import SubmitButton from "../../../Components/SubmitButton"
import { useNavigate } from "react-router-dom"

export default function MfaForm({ emailAdr }) {
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()
  const [MFACodeErrors, setMFACodeErrors] = useState("")

  const [MFACode, setMFACode] = useState()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const findMFAErrors = () => {
      const newErrors = {}

      if (MFACode !== undefined) {
        if (MFACode === "") {
          newErrors.MFA = "Authentication code is required"
        } else {
          newErrors.MFA = ""
        }
      }

      setMFACodeErrors(newErrors)
    }
    findMFAErrors()
  }, [MFACode])

  const handle2FASubmit = (e) => {
    e.preventDefault()
    if (!MFACode) {
      setMFACodeErrors((prev) => {
        return { ...prev, MFA: "Authentication code is required" }
      })
    }
    if (MFACodeErrors.MFA === "") {
      submitTOTP(MFACode)
    }
  }

  const submitTOTP = async (totp) => {
    var data = {
      Email: emailAdr,
      AuthCode: totp,
    }
    try {
      const res = await axios.post(
        `https://${process.env.REACT_APP_IP}/api/users/verifytotp/`,
        data
      )
      if (res.status === 200) {
        //if totp is valid login user
        dispatch({ type: "LOGIN", payload: res.data })
      }
    } catch (err) {
      console.log(err)
      if (err.response.status === 400) {
        setMFACodeErrors((prev) => {
          return { ...prev, MFA: err.response.data[0] }
        })
        // setMFAErr(err.response.data[0])
      }
    }
  }
  return (
    <Form onSubmit={(e) => handle2FASubmit(e)}>
      <Form.Group>
        <FloatingLabel
          controlId="floatingInput"
          label="2FA Code"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="123456"
            isInvalid={MFACodeErrors.MFA}
            onChange={(e) => setMFACode(e.target.value)}
          />
          <Form.Control.Feedback className="text-white" type="invalid">
            {MFACodeErrors.MFA}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="d-flex justify-content-end align-items-center mt-3">
        <button
          className="btn btn-danger me-2"
          type="button"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <SubmitButton submitting={submitting} />
      </Form.Group>
    </Form>
  )
}
