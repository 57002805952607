import { faUserSecret } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import "./Fact.css"

export default function Fact5() {
  return (
    <div className="h-100 d-flex flex-column col">
      <h1 className="p-3 mt-3 text-white text-center text-decoration-underline">
        Did you know?
      </h1>

      <div className="d-flex justify-content-around p-3 my-5">
        <div className="col-4 my-5">
          <h2 className="fact5 text-white">
            <span className="fact5Span">81%</span> of hacking-related breaches
            are due to weak/stolen passwords
          </h2>
          <p className="fact-reference text-white"> - zippia.com</p>
        </div>

        <div className="text-white d-flex align-items-center">
          <FontAwesomeIcon icon={faUserSecret} size="10x" />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center align-items-center py-4">
        <h3 className="primaryShadow col-8 p-3 text-white">
          Enabling multi-factor authentication can be key when making accounts
          secure. Multi-factor authentication allows some leeway with
          weak/stolen passwords as hackers cannot access accounts with the
          weak/stolen password alone. This should provide enough time to be able
          to change any passwords and resucure any accounts which may have been
          endangered.
        </h3>
      </div>
    </div>
  )
}
